import { useCallback, useRef, useState } from "react";

interface FetcherType<T, R> {
    (request: T): Promise<R>;
}

interface BeforetransformRequest<T> {
    (request: T): T;
}
interface BeforetransformResponse<T> {
    (request: T): Promise<T>;
}

interface FetcherOptions<R> {
    defaultPageIndex?: number;
    defaultPageSize?: number;
    pageSizeKey?: string;
    transformResponse?: BeforetransformResponse<R>;
}

type PageConfig = { pageNum: number; size: number };
export const usePagination = <T, R>(
    fetcher: FetcherType<T, R>,
    options?: FetcherOptions<R>
) => {
    const [dataSource, setDataSource] = useState<R>();
    const [newPageSizeKey] = useState(options?.pageSizeKey ?? "pageSize");
    const [pageConfig] = useState({
        pageNum: options?.defaultPageIndex ?? 1,
        [newPageSizeKey]: options?.defaultPageSize ?? 20,
    });
    const requestRef = useRef<PageConfig | T>(pageConfig as PageConfig);
    const [isRequest, setIsRequest] = useState(false);
    const stateTransformResponse = useRef(options?.transformResponse);

    const fetchAction = useCallback(
        (
            request?: T,
            transformRequest?: BeforetransformRequest<T>,
            transformResponse?: BeforetransformResponse<R>
        ) => {
            setIsRequest(true);
            const newRequest = transformRequest
                ? transformRequest(request as T)
                : {
                      ...pageConfig,
                      ...requestRef.current,
                      ...request,
                  };
            requestRef.current = newRequest;
            return fetcher(newRequest as T)
                .then((res) => {
                    setIsRequest(false);
                    const transformResponseFactory =
                        transformResponse || stateTransformResponse.current;
                    return transformResponseFactory
                        ? transformResponseFactory(res)
                        : res;
                })
                .then((res) => {
                    setDataSource(res);
                    return res;
                })
                .catch((error) => {
                    setIsRequest(false);
                    setDataSource(undefined);
                    throw error;
                });
        },
        [fetcher, pageConfig]
    );

    const onPageIndexChange = useCallback(
        (page: number, size?: number) => {
            requestRef.current = {
                ...requestRef.current,
                pageNum: page,
                [newPageSizeKey]: size,
            };
            fetchAction();
        },
        [fetchAction, newPageSizeKey]
    );

    return {
        dataSource,
        isRequest,
        fetchAction,
        pageIndex: (requestRef.current as PageConfig).pageNum,
        pageSize: (requestRef.current as PageConfig)[
            newPageSizeKey as keyof PageConfig
        ],
        onPageIndexChange,
    };
};
