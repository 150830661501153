import { ReactElement, useCallback, useMemo } from "react";
import { Layout, Menu, Dropdown, Button, theme, Divider } from "antd";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { routeGroups, routes } from "../../../../service/route";
import styles from "./styles.module.less";
import { useAuthState } from "../../../../service/platform/useAuthState";
import { useUserInfo } from "../../../../service/platform/useUserInfo";
import { DownOutlined } from "@ant-design/icons";
import { useWeather } from "./useWeather";
import { useUserMenuPermissionByRule } from "../../../../service/route/useUserMenuPermissionByRule";
const { Header, Content, Sider } = Layout;

const HeaderDropdownMenu = () => {
    const auth = useAuthState();
    const userInfo = useUserInfo();

    const onHandleMenuClick = useCallback(
        (e) => {
            if (e.key) {
                auth.signout();
            }
        },
        [auth]
    );

    return (
        <Dropdown
            className={styles.dropdownItem}
            overlay={
                <Menu onClick={onHandleMenuClick}>
                    <Menu.Item key="logout">退出登录</Menu.Item>
                </Menu>
            }
        >
            <Button type="link" className={styles.dropdownButton}>
                欢迎你 {userInfo?.nickname || ""} <DownOutlined />
            </Button>
        </Dropdown>
    );
};

const WeatherElement = () => {
    const data = useWeather();

    if (!data) return null;
    return (
        <span className={styles.weather}>
            今天是 {data.date}&nbsp;&nbsp;{data.week}&nbsp;&nbsp;最高温度{" "}
            {data.tem1} 度，最低温度 {data.tem2} 度，{data.wea}
        </span>
    );
};

export const MainLayout = (): ReactElement => {
    const localtion = useLocation();
    const selectedKey = useMemo(
        () =>
            routes.find((route) => {
                if (route.path === "/") return false;
                const active = matchPath(
                    { path: route.path, end: false },
                    localtion.pathname
                );
                return active;
            })?.path || "/",
        [localtion]
    );

    const {
        token: { colorBgContainer, Menu: MenuTheme },
    } = theme.useToken();

    const menuPermissionByRule = useUserMenuPermissionByRule();

    return (
        <Layout key="main_layout" style={{ minHeight: '100vh' }}>
            <Sider
                trigger={null}
                collapsible
                className={styles.sider}
                style={{ backgroundColor: MenuTheme?.colorItemBg }}
            >
                <div className={styles.logo}>SIG&nbsp;&nbsp;STUDIO</div>
                <Menu
                    className={styles.menuBox}
                    theme="dark"
                    mode="inline"
                    selectedKeys={[selectedKey]}
                >
                    {routeGroups.map((rts, idx) => {
                        const childRoutes = rts.filter((route) => {
                            if (route.hideMenu) return false;
                            if (!menuPermissionByRule(route.rule)) return false;
                            return true;
                        });
                        
                        return (
                            <>
                                {idx !== 0 && !!childRoutes.length && (
                                    <Divider key={`divider_${idx}`} className={styles.divider} />
                                )}
                                {childRoutes.map((route) => {
                                    return (
                                        <Menu.Item
                                            style={{ marginBlock: 0 }}
                                            key={route.path}
                                        >
                                            <Link to={route.path}>
                                                {route.name}
                                            </Link>
                                        </Menu.Item>
                                    );
                                })}
                            </>
                        );
                    })}
                </Menu>
            </Sider>
            <Layout>
                <Header
                    className={styles.header}
                    style={{
                        backgroundColor: colorBgContainer,
                    }}
                >
                    <WeatherElement />
                    {/* <span
                        className={styles.collapsedButton}
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    >
                        {collapsed ? (
                            <MenuUnfoldOutlined />
                        ) : (
                            <MenuFoldOutlined />
                        )}
                    </span> */}
                    <HeaderDropdownMenu />
                </Header>
                <Content style={{ padding: 0 }}>
                    {/* <RouteBreadcrumb /> */}
                    <Layout>
                        <Content style={{ minHeight: "80vh" }}>
                            <Outlet />
                        </Content>
                    </Layout>
                </Content>
            </Layout>

            {/* <Footer style={{ textAlign: "center" }}>
                ©2022 Created by
            </Footer> */}
        </Layout>
    );
};
