import { post } from ".";
import {
    CommonStatusEnum,
    DeleteRequest,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";
import { IndustryItem } from "./industry";

export interface ClientItem {
    guid: string;
    /** 客户名称-中文 */
    titleCn?: string;
    /** 客户名称-英文 */
    titleEn?: string;
    /** 行业类型 */
    industryGuid?: string;
    /** 行业类型实体 */
    industryInfo?: IndustryItem;
    /** 图标 */
    icon?: string;
    /** 排序 */
    sort?: number;
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface ClientListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: ClientItem[];
}

export interface ClientDetailsReply {
    data: ClientItem;
}

/**
 * 获取客户列表
 */
export const fetchClientList = (request: PageConfig & Partial<ClientItem>) => {
    return post<PageConfig & Partial<ClientItem>, ClientListReply>(
        "admin.biz.client.info.list",
        request
    );
};

/**
 * 创建客户
 */
export const createClient = (request: ClientItem) => {
    return post<ClientItem>("admin.biz.client.info.create", request);
};

/**
 * 获取客户详情
 */
export const fetchClientDetails = (request: { guid: string }) => {
    return post<{ guid: string }, ClientItem>(
        "admin.biz.client.info.detail",
        request
    );
};

/**
 * 修改客户
 */
export const updateClient = (request: Partial<ClientItem>) => {
    return post<Partial<ClientItem>, ClientItem>(
        "admin.biz.client.info.update",
        request
    );
};

/**
 * 删除客户
 */
export const deleteClient = (request: DeleteRequest) => {
    return post<DeleteRequest>("admin.biz.client.info.delete", {
        isDelete: CommonStatusEnum.NO,
        ...request,
    });
};
