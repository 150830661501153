import { Button, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

export const useBlocking = (onSubmit: () => Promise<any>, saveTitle?: string) => {
    const [isBlocking, setIsBlocking] = useState(false);
    let blocker = useBlocker(isBlocking);
    const blockerState = blocker.state;

    const onProceed = useCallback(() => {
        setIsBlocking(false);
    }, []);
    console.log('blocker: ', blocker, isBlocking);
    
    useEffect(() => {
        if (blockerState === "blocked" && isBlocking) {
            const configmModal = Modal.confirm({
                title: "退出并放弃编辑？",
                footer: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px 0' }}>
                        <Button
                            onClick={() => {
                                blocker?.reset?.();
                                configmModal.destroy();
                            }}
                        >
                            继续编辑
                        </Button>
                        <Button
                            onClick={() => {
                                onSubmit().then(() => {
                                    configmModal.destroy();
                                });
                            }}
                        >
                            {saveTitle ? saveTitle : '保存并退出'}
                        </Button>
                        <Button
                            onClick={() => {
                                configmModal.destroy();
                                blocker?.proceed?.();
                            }}
                        >
                            直接退出
                        </Button>
                    </div>
                ),
            });
        }
    }, [blocker, blockerState, isBlocking, saveTitle, onSubmit]);

    return { setIsBlocking, blocker, proceed: onProceed };
};
