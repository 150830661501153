import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./styles.module.less";
import {
    Button,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Form,
    FormInstance,
    Input,
    Row,
    Select,
    Space,
    Spin,
    TimePicker,
    message,
} from "antd";
// import type { DragEndEvent } from "@dnd-kit/core";
// import { DndContext } from "@dnd-kit/core";
// import {
//     arrayMove,
//     SortableContext,
//     verticalListSortingStrategy,
// } from "@dnd-kit/sortable";
import { BraftEditorBase } from "../../../../components/Editor";
import { UploadComponent } from "../../../../components/Upload";
import { BlockModal } from "../../../../components/BlockModal";
import { BlockItem, useSortDetails } from "../../../../components/BlockItem";
import { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import {
    BlockGroupItem,
    WorkItem,
    createWork,
    fetchWorkDetails,
    updateWork,
} from "../../../../service/request/apis/work";
import { useNavigate, useParams } from "react-router-dom";
import { CheckboxSwitch } from "../../../../components/CheckboxSwitch";
import {
    DraftStatusEnum,
    RecycleStatusEnum,
    StatusEnum,
} from "../../../../service/request/interface";
import { fetchServiceList } from "../../../../service/request/apis/service";
import { fetchClientList } from "../../../../service/request/apis/client";
import { config } from "../../../../service/config";
import { fetchJobList } from "../../../../service/request/apis/job";
import { fetchTeamList } from "../../../../service/request/apis/team";
import { useBlocking } from "../../../../service/useBlocking";

const { Panel } = Collapse;
const { Item, List } = Form;

const useServiceOptions = () => {
    const [data, setData] = useState<{ label: string; value: string }[]>();
    useEffect(() => {
        fetchServiceList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setData(
                res.list.map((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
        });
    }, []);
    return data;
};

const useClientOptions = () => {
    const [data, setData] = useState<{ label: string; value: string }[]>();
    useEffect(() => {
        fetchClientList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setData(
                res.list.map((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
        });
    }, []);
    return data;
};

// 职位选项
const useJobOptions = () => {
    const [data, setData] = useState<{ label: string; value: string }[]>();
    useEffect(() => {
        fetchJobList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setData(
                res.list.map((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
        });
    }, []);
    return data;
};

// 人员选项
const useTeamOptions = () => {
    const [data, setData] = useState<{ label: string; value: string }[]>();
    useEffect(() => {
        fetchTeamList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setData(
                res.list.map((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
        });
    }, []);
    return data;
};

const ProjectDetails = ({
    form,
    setTypes,
    types,
}: {
    form: FormInstance;
    types: BlockGroupItem[];
    setTypes: Dispatch<SetStateAction<BlockGroupItem[]>>;
}) => {
    // const [showCollapse, setShowCollapse] = useState(true);
    // const onDragEnd = useCallback(
    //     ({ active, over }: DragEndEvent) => {
    //         setShowCollapse(true);
    //         if (active.id !== over?.id) {
    //             const previous: BlockGroupItem[] =
    //                 form.getFieldValue("details");
    //             if (!previous) return [];

    //             const activeIndex = previous.findIndex(
    //                 (i) => i.type === active.id
    //             );
    //             const overIndex = previous.findIndex(
    //                 (i) => i.type === over?.id
    //             );
    //             const nextTypes = arrayMove(previous, activeIndex, overIndex);
    //             setTimeout(() => {
    //                 form.setFieldValue("details", nextTypes);
    //             }, 10);
    //             setTypes(nextTypes);
    //         }
    //     },
    //     [form, setTypes]
    // );

    // const onDragStart = useCallback((e) => {
    //     setShowCollapse(false);
    // }, []);

    const onSortChange = useSortDetails(form);

    const [visibleBlockModal, setVisibleBlockModal] = useState(false);

    const onOpenBlock = useCallback(() => {
        setVisibleBlockModal(true);
    }, []);

    const onTypeChange = useCallback(
        (type: BlockGroupItem) => {
            const blocks = form.getFieldValue("details") || [];
            const nextTypes = [...blocks, type];
            form.setFieldValue("details", nextTypes);
            setTypes(nextTypes);
        },
        [form, setTypes]
    );

    // const items = useMemo(() => types.map((i) => i.type), [types]);

    return (
        <>
            <Collapse
                expandIconPosition="end"
                className={styles.collapseCustom}
            >
                <Panel
                    header={
                        <span className={styles.panelTitle}>项目详细介绍</span>
                    }
                    key="4"
                >
                    {!!types?.length && (
                        // <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                        //     <SortableContext
                        //         disabled={types.length <= 1}
                        //         items={items}
                        //         strategy={verticalListSortingStrategy}
                        //     >
                        <List name="details">
                            {(fields, { remove }) => {
                                return fields.map((field, idx) => {
                                    const t = form.getFieldValue([
                                        "details",
                                        field.name,
                                    ]);

                                    return (
                                        <BlockItem
                                            isFirstItem={idx === 0}
                                            isLastItem={
                                                fields.length - 1 === idx
                                            }
                                            onSortChange={onSortChange}
                                            remove={remove}
                                            block={t}
                                            field={field}
                                        />
                                    );
                                });
                            }}
                        </List>
                        //     </SortableContext>
                        // </DndContext>
                    )}
                    <Row gutter={24} justify="center">
                        <Col
                            span={24}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button onClick={onOpenBlock}>添加</Button>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <BlockModal
                visible={visibleBlockModal}
                onTypeChange={onTypeChange}
                onVisibleChange={(visible) => {
                    setVisibleBlockModal(visible);
                }}
            />
        </>
    );
};

const useFormatDataSource = (
    form: FormInstance,
    data?: WorkItem | null,
    setTypes?: Dispatch<SetStateAction<BlockGroupItem[]>>
) => {
    const [initValue, setInitValue] = useState(false);
    useEffect(() => {
        if (data) {
            const newData = JSON.parse(JSON.stringify(data));
            if (newData.publishTime) {
                const time = dayjs(Number(newData.publishTime));
                newData.publishDatePart = time;
                newData.publishTimePart = time;
                newData.isTimingPublish = true;
            }
            if (newData.finishTime) {
                newData.finishTime = dayjs(Number(newData.finishTime));
            }

            form.setFieldsValue(newData);
            setTypes?.(newData.details || []);
            setTimeout(() => {
                setInitValue(true);
            }, 200);
        } else if (data === null) {
            setTimeout(() => {
                setInitValue(true);
            }, 200);
        }
    }, [form, data, setTypes]);
    return initValue;
};

const useFinish = () => {
    const navigate = useNavigate();
    return useCallback(
        (values: WorkItem) => {
            const { finishTime, publishDatePart, publishTimePart } = values;
            const timePart = publishTimePart as unknown as Dayjs;
            let datePart = publishDatePart as unknown as Dayjs;
            if (timePart) {
                datePart = datePart
                    .clone()
                    .hour(timePart.hour())
                    .minute(timePart.minute())
                    .second(timePart.second());
            }
            const newValues: WorkItem = {
                status: StatusEnum.StatusOnline,
                draftStatus: DraftStatusEnum.DraftStatusNo,
                recycleStatus: RecycleStatusEnum.RecycleStatusNo,
                ...values,
                finishTime: finishTime?.valueOf() ?? 0,
                publishTime: datePart ? datePart.valueOf() : 0,
            };

            const isEdit = !!values.guid;

            const loading = message.loading("提交中...", 0);
            const result = isEdit
                ? updateWork(newValues)
                : createWork(newValues);
            return result
                .then((res) => {
                    loading();
                    message.success(`${isEdit ? "编辑" : "添加"}成功`, 1);
                    setTimeout(() => {
                        navigate("/work");
                    }, 1000);
                    return res;
                })
                .catch((error) => {
                    loading();
                    message.error(`操作失败: ${error?.msg || ""}`, 1);
                });
        },
        [navigate]
    );
};

const useDraft = (form: FormInstance) => {
    const fifish = useFinish();
    return useCallback(() => {
        return form.validateFields().then(() => {
            return fifish({
                ...form.getFieldsValue(),
                draftStatus: DraftStatusEnum.DraftStatusYes,
            });
        });
    }, [form, fifish]);
};

const usePreview = (form: FormInstance) => {
    const draft = useDraft(form);
    return useCallback(() => {
        draft().then((res) => {
            window.open(`${config.WEB_SITE}detail/${res.guid}?preview=1`);
        });
    }, [draft]);
};

const useFetchDetails = () => {
    const params = useParams();
    const [data, setData] = useState<WorkItem | null | undefined>();

    useEffect(() => {
        if (!params.workid) {
            setData(null);
            return;
        }
        fetchWorkDetails({ guid: params.workid })
            .then((res) => {
                setData(res);
            })
            .catch(() => {
                message.error("获取信息失败，请联系管理员", 1);
            });
    }, [params]);

    return data;
};

export const WorkFormEditPage = (): ReactElement => {
    const [form] = Form.useForm();
    const [types, setTypes] = useState<BlockGroupItem[]>([]);
    const params = useParams();

    const onValuesChange = useCallback(
        (e) => {
            if (form.isFieldTouched("details")) {
                setTypes(form.getFieldValue("details"));
            }
        },
        [form]
    );

    const onDraft = useDraft(form);
    const onPreview = usePreview(form);
    const data = useFetchDetails();
    const serviceOptions = useServiceOptions();
    const clientOptions = useClientOptions();
    const jobOptions = useJobOptions();
    const teamOptions = useTeamOptions();
    const onFinish = useFinish();
    const initValue = useFormatDataSource(form, data, setTypes);
    const blockerRef = useRef<any>();

    const onSubmit = useCallback(() => {
        return form
            .validateFields()
            .then((values) => {
                return onFinish(values);
            })
            .then(() => {
                blockerRef.current?.();
            })
            .catch((errorInfo) => {
                const msg =
                    errorInfo.errorFields?.[0]?.errors?.[0] ||
                    "请检查是否有遗漏字段或字段格式错误";
                if (msg) {
                    message.error(msg, 1.5);
                }
            });
    }, [onFinish, form]);

    const { setIsBlocking, proceed } = useBlocking(
        params?.workid ? onSubmit : onDraft,
        params?.workid ? "保存并退出" : "保存为草稿退出"
    );
    blockerRef.current = proceed;

    const onChangeValues = useCallback(() => {
        if (!initValue) return;

        setIsBlocking(true);
    }, [setIsBlocking, initValue]);

    return (
        <div className={styles.container}>
            <h3 className={styles.header}>
                <span>WORK {params.workid ? "编辑项目" : "新项目"}</span>
            </h3>
            <Spin spinning={!!params.workid && !data}>
                <div className={styles.content}>
                    <Form
                        requiredMark={false}
                        onFinish={onFinish}
                        form={form}
                        onFieldsChange={onValuesChange}
                        onValuesChange={onChangeValues}
                    >
                        <Item hidden name="guid">
                            <Input />
                        </Item>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Collapse
                                expandIconPosition="end"
                                defaultActiveKey={["base_info"]}
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            基础信息
                                        </span>
                                    }
                                    key="base_info"
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Item
                                                label="项目客户"
                                                name="clientGuid"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "项目客户不能为空",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={clientOptions}
                                                    placeholder="选择项目客户"
                                                />
                                            </Item>
                                        </Col>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "服务类型不能为空",
                                                    },
                                                ]}
                                                label="服务类型"
                                                name="serviceGuids"
                                            >
                                                <Select
                                                    mode="multiple"
                                                    options={serviceOptions}
                                                    placeholder="选择服务类型"
                                                />
                                            </Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Item
                                                label="地点中文"
                                                name="addressCn"
                                            >
                                                <Input placeholder="项目地点中文" />
                                            </Item>
                                        </Col>
                                        <Col span={6}>
                                            <Item label="项目面积" name="area">
                                                <Input
                                                    type="number"
                                                    placeholder="项目面积"
                                                    suffix="㎡"
                                                />
                                            </Item>
                                        </Col>
                                        <Col span={12}>
                                            <Item
                                                label="完工日期"
                                                name="finishTime"
                                            >
                                                <DatePicker
                                                    style={{ width: "100%" }}
                                                />
                                            </Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Item
                                                label="地点英文"
                                                name="addressEn"
                                            >
                                                <Input placeholder="项目地点英文" />
                                            </Item>
                                        </Col>
                                        <Col span={6}>
                                            <Item
                                                label={
                                                    <>
                                                        排
                                                        <span
                                                            style={{
                                                                visibility:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            排序
                                                        </span>
                                                        序
                                                    </>
                                                }
                                                name="sort"
                                            >
                                                <Input
                                                    type="number"
                                                    placeholder="排序"
                                                />
                                            </Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={3}>
                                            <Item
                                                label={
                                                    <>
                                                        <span
                                                            style={{
                                                                visibility:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            是否
                                                        </span>
                                                        置顶
                                                    </>
                                                }
                                                valuePropName="checked"
                                                name="isTop"
                                            >
                                                <CheckboxSwitch />
                                            </Item>
                                        </Col>
                                        <Col span={3}>
                                            <Item
                                                valuePropName="checked"
                                                label="隐藏"
                                                name="status"
                                            >
                                                <CheckboxSwitch />
                                            </Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Collapse
                                expandIconPosition="end"
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            项目简介
                                        </span>
                                    }
                                    key="work_info"
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "中文标题不能为空",
                                                    },
                                                ]}
                                                label="中文名称"
                                                name="titleCn"
                                            >
                                                <Input placeholder="中文名称" />
                                            </Item>
                                        </Col>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "英文标题不能为空",
                                                    },
                                                ]}
                                                label="英文名称"
                                                name="titleEn"
                                            >
                                                <Input placeholder="英文名称" />
                                            </Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "中文简介不能为空",
                                                    },
                                                ]}
                                                name="summaryCn"
                                                label="中文简介"
                                            >
                                                <BraftEditorBase />
                                            </Item>
                                        </Col>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "英文简介不能为空",
                                                    },
                                                ]}
                                                label="英文简介"
                                                name="summaryEn"
                                            >
                                                <BraftEditorBase />
                                            </Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Collapse
                                expandIconPosition="end"
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            标题大图与缩略图
                                        </span>
                                    }
                                    key="title_images"
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Collapse
                                                style={{ margin: 10 }}
                                                activeKey={["top"]}
                                            >
                                                <Panel
                                                    showArrow={false}
                                                    key="top"
                                                    header={
                                                        <span
                                                            className={
                                                                styles.itemPanelTitle
                                                            }
                                                        >
                                                            顶部头图
                                                        </span>
                                                    }
                                                >
                                                    <Item
                                                        name="banner"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "顶部头图不能为空",
                                                            },
                                                        ]}
                                                        className={
                                                            styles.itemPanelCon
                                                        }
                                                    >
                                                        <UploadComponent
                                                            folder="work"
                                                            direction="horizontal"
                                                        />
                                                    </Item>
                                                </Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={12}>
                                            <Collapse
                                                style={{ margin: 10 }}
                                                activeKey="thumbnail"
                                            >
                                                <Panel
                                                    showArrow={false}
                                                    key="thumbnail"
                                                    header={
                                                        <span
                                                            className={
                                                                styles.itemPanelTitle
                                                            }
                                                        >
                                                            缩略图
                                                        </span>
                                                    }
                                                >
                                                    <Item
                                                        name="thumbnail"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "缩略图不能为空",
                                                            },
                                                        ]}
                                                        className={
                                                            styles.itemPanelCon
                                                        }
                                                    >
                                                        <UploadComponent
                                                            folder="work"
                                                            direction="horizontal"
                                                        />
                                                    </Item>
                                                </Panel>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>

                            <ProjectDetails
                                setTypes={setTypes}
                                types={types}
                                form={form}
                            />

                            <Collapse
                                expandIconPosition="end"
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            参与人员
                                        </span>
                                    }
                                    key="partners"
                                >
                                    <List name="partners">
                                        {(fields, { add, remove }) => {
                                            return (
                                                <Space
                                                    direction="vertical"
                                                    style={{ width: "100%" }}
                                                >
                                                    {fields.map((field) => {
                                                        return (
                                                            <Row
                                                                gutter={24}
                                                                key={field.name}
                                                            >
                                                                <Col span={6}>
                                                                    <Item
                                                                        style={{
                                                                            marginBottom: 5,
                                                                        }}
                                                                        name={[
                                                                            field.name,
                                                                            "jobGuid",
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            options={
                                                                                jobOptions
                                                                            }
                                                                            placeholder="职位"
                                                                        />
                                                                    </Item>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Item
                                                                        style={{
                                                                            marginBottom: 5,
                                                                        }}
                                                                        name={[
                                                                            field.name,
                                                                            "teamGuid",
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            options={
                                                                                teamOptions
                                                                            }
                                                                            placeholder="人员"
                                                                        />
                                                                    </Item>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            remove(
                                                                                field.name
                                                                            );
                                                                        }}
                                                                        icon={
                                                                            <DeleteFilled />
                                                                        }
                                                                    ></Button>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                    <Row gutter={24}>
                                                        <Col span={24}>
                                                            <Button
                                                                onClick={() => {
                                                                    add();
                                                                }}
                                                                icon={
                                                                    <PlusCircleOutlined />
                                                                }
                                                            >
                                                                添加
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Space>
                                            );
                                        }}
                                    </List>
                                </Panel>
                            </Collapse>

                            <Collapse
                                expandIconPosition="end"
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            定时发布
                                        </span>
                                    }
                                    key="timing"
                                >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Item
                                                name="isTimingPublish"
                                                style={{ marginBottom: 5 }}
                                                label="开启定时"
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    disabled={!!data?.guid}
                                                />
                                            </Item>
                                        </Col>
                                    </Row>

                                    <Item
                                        shouldUpdate={(prev, curr) =>
                                            prev?.isTimingPublish !==
                                            curr?.isTimingPublish
                                        }
                                        noStyle
                                    >
                                        {({ getFieldValue }) => {
                                            const isTimingPublish =
                                                getFieldValue(
                                                    "isTimingPublish"
                                                );
                                            return isTimingPublish ? (
                                                <Row gutter={24}>
                                                    <Col span={6}>
                                                        <Item
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    message:
                                                                        "发布日期不能为空",
                                                                },
                                                            ]}
                                                            style={{
                                                                marginBottom: 10,
                                                            }}
                                                            label="发布日期"
                                                            name="publishDatePart"
                                                        >
                                                            <DatePicker
                                                                disabled={
                                                                    !!data?.guid
                                                                }
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        </Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Item
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    message:
                                                                        "发布时间不能为空",
                                                                },
                                                            ]}
                                                            style={{
                                                                marginBottom: 10,
                                                            }}
                                                            label="发布时间"
                                                            name="publishTimePart"
                                                        >
                                                            <TimePicker
                                                                disabled={
                                                                    !!data?.guid
                                                                }
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        </Item>
                                                    </Col>
                                                </Row>
                                            ) : null;
                                        }}
                                    </Item>
                                </Panel>
                            </Collapse>
                        </Space>
                    </Form>
                </div>
            </Spin>
            <div className={styles.footer}>
                <Button
                    onClick={() => {
                        form.resetFields();
                    }}
                >
                    重置
                </Button>
                {!params.workid && <Button onClick={onDraft}>存为草稿</Button>}
                <Button onClick={onPreview}>预览</Button>
                <Button onClick={onSubmit} style={{ marginLeft: "auto" }}>
                    {params.workid ? "更新" : "发布"}
                </Button>
            </div>
        </div>
    );
};
