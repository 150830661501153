import { Form, Input, message, Modal, Spin } from "antd";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { PermissionFormList } from "../../../../components/PermissionFormList";
import {
    createSystemRole,
    fetchSystemRoleDetails,
    RoleItem,
    updateSystemRole,
} from "../../../../service/request/apis/role";

export const RoleModal = ({
    data,
    onClose,
    onRefresh,
}: {
    data: RoleItem;
    onClose: () => void;
    onRefresh: () => void;
}): ReactElement => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            const hide = message.loading("正在提交...", 0);
            values.actionRules = values.actionRules.join("\n");

            (values.guid ? updateSystemRole(values) : createSystemRole(values))
                .then(() => {
                    hide();
                    message.success(`${values.guid ? "编辑" : "创建"}成功`, 1);
                    onClose();
                    onRefresh();
                })
                .catch((error) => {
                    hide();
                    message.error(
                        `${values.guid ? "编辑" : "创建"}失败: ${
                            error?.msg || ""
                        }`,
                        1
                    );
                });
        });
    }, [form, onClose, onRefresh]);

    useEffect(() => {
        if (!data) return;
        fetchSystemRoleDetails({
            guid: data.guid,
        }).then((res) => {
            setLoading(false);
            form.setFieldsValue(res);
        });
    }, [data, form]);

    return (
        <Modal
            onCancel={onClose}
            onOk={onSubmit}
            width="50%"
            className="modal-header ant-modal-custom"
            bodyStyle={{ minHeight: "50vh" }}
            title={data.guid ? "编辑角色" : "创建角色"}
            open={true}
        >
            <Spin spinning={loading}>
                <Form form={form} labelCol={{ span: 3 }}>
                    <Form.Item name="guid" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="名称"
                        name="roleName"
                        rules={[{ message: "请输入角色名称", required: true }]}
                    >
                        <Input placeholder="请输入角色名称" />
                    </Form.Item>
                    <Form.Item
                        label="权限"
                        name="actionRules"
                        rules={[{ message: "请选择权限", required: true }]}
                    >
                        <PermissionFormList />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};
