import { post } from ".";
import {
    CommonStatusEnum,
    ListRequest,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";

export interface IndustryItem {
    guid: string;
    /** 行业名称-中文 */
    titleCn?: string;
    /** 行业名称-英文 */
    titleEn?: string;
    /** 图标 */
    icon?: string;
    /** 排序 */
    sort?: number;
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface IndustryListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: IndustryItem[];
}

export interface IndustryDetailsReply {
    data: IndustryItem;
}

/**
 * 获取行业列表
 */
export const fetchIndustryList = (
    request: ListRequest & Partial<IndustryItem>
) => {
    return post<ListRequest & Partial<IndustryItem>, IndustryListReply>(
        "admin.biz.industry.info.list",
        request
    );
};

/**
 * 创建行业
 */
export const createIndustry = (request: IndustryItem) => {
    return post<IndustryItem>("admin.biz.industry.info.create", request);
};

/**
 * 获取行业详情
 */
export const fetchIndustryDetails = (request: { guid: string }) => {
    return post<{ guid: string }, IndustryItem>(
        "admin.biz.industry.info.detail",
        request
    );
};

/**
 * 修改行业
 */
export const updateIndustry = (request: Partial<IndustryItem>) => {
    return post<Partial<IndustryItem>, IndustryItem>(
        "admin.biz.industry.info.update",
        request
    );
};

/**
 * 删除行业
 */
export const deleteIndustry = (request: {
    guids: string[];
    isDelete?: CommonStatusEnum;
}) => {
    return post<{ guids: string[]; isDelete?: CommonStatusEnum }>(
        "admin.biz.industry.info.delete",
        {
            isDelete: CommonStatusEnum.NO,
            ...request,
        }
    );
};
