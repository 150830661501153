import React, {
    FunctionComponent,
    useState,
    useCallback,
    useMemo,
    useEffect,
    useRef,
    useImperativeHandle,
    ForwardRefRenderFunction,
    forwardRef,
} from "react";
import { Upload, Modal, message, Button } from "antd";
import cn from "classnames";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import {
    PlusOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    DeleteOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import { isArray, isString } from "lodash-es";
import styles from "./styles.module.less";
import { post } from "../../service/request/apis";
// import { config } from "../../service/config";

type UploadImageResult = {
    [index: string]: string | number | undefined;
    content_type: string;
    filename: string;
    name: string;
    size: number;
    url: string;
};
interface UploadImgProps {
    value?: string[] | string;
    onChange?(value: string[] | string): void;
    folder?: string;
    uploadRequestApi?: (props: UploadByFormDataRequest) => Promise<UploadImageResult>;
    direction?: DirectionType;
    disabled?: boolean;
    buttonText?: string;
    shape?: "default" | "circle";
    beforeUpload?: (file: File, fileList: File[]) => Promise<File> | boolean;
}

function getBase64(file: Blob | File): PromiseLike<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}

export function beforeUpload(file: Blob): boolean {
    const isJpgOrPng = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/bmp",
        "image/gif",
        "image/webp",
    ].includes(file.type);
    if (!isJpgOrPng) {
        message.error("图片格式不支持");
        return false;
    }
    const isOutOfBounds = file.size / 1024 / 1024 < 6;
    if (!isOutOfBounds) {
        message.error("图片不能超过6Mb!");
        return false;
    }
    return isJpgOrPng && isOutOfBounds;
}

export interface UploadByFormDataRequest {
    [index: string]: string | Blob | undefined;
    folder?: string;
    fileBase64: string;
    fileName?: string;
}

export const uploadByFormData = (
    params: UploadByFormDataRequest
): Promise<UploadImageResult> => {
    // const formData = new FormData();
    // Object.keys(params).forEach((key) => {
    //     const item = params[key];
    //     if (item) {
    //         formData.append(key, item);
    //     }
    // });

    return post("admin.system.admin.upload.file", params) as Promise<UploadImageResult>;
};

const uploadDefaultHandle = (props: UploadByFormDataRequest): Promise<UploadImageResult> =>
    uploadByFormData(props);

// csv、xlsx上传前拦截方法
export const officeBeforeUpload = (file: Blob): boolean => {
    const isSupport = [
        "application/vnd.ms-excel",
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ".csv",
        ".xlsx",
    ].includes(file.type);
    if (!isSupport) {
        message.error("文件格式不支持");
        return false;
    }
    return true;
};

const useCustomUpload = (
    uploadRequestApi: (props: UploadByFormDataRequest) => Promise<UploadImageResult>,
    folder = 'common'
) => {
    return useCallback(
        ({ file, onSuccess, onError }) => {
            const suffix = file.name.slice(file.name.lastIndexOf('.'));
            return getBase64(file).then((base64) => {
                const prefix = (Date.now()).toString(36);
                return uploadRequestApi({
                    folder,
                    fileName: `${prefix}_${Date.now()}${suffix}`,
                    fileBase64: base64.split(",")[1],
                })
                    .then((res) => {
                        onSuccess(Array.isArray(res) ? res : [res], file);
                    })
                    .catch((error) => {
                        onError(error);
                    });
            });
        },
        [uploadRequestApi, folder]
    );
};

export const useFileList = (
    onChange: ((value: string[] | string) => void) | undefined,
    value: string[] | string,
    namePrefix = "图片"
) => {
    const [fileList, setFileList] = useState<UploadFile[]>();
    const isFirstUpdate = useRef(true);

    const handleChange = useCallback(
        ({ fileList: list, file }) => {
            if (isArray(value)) {
                setFileList(list);
                if (["done", "removed"].includes(file.status)) {
                    onChange?.(
                        list.map((e: UploadFile) => {
                            return e.url || e.response[0].url;
                        })
                    );
                }
            }
            if (isString(value) && file.status === "done") {
                onChange?.(file.response[0].url);
            }
        },
        [onChange, value, setFileList]
    );

    useEffect(() => {
        if (
            isFirstUpdate.current &&
            isArray(value) &&
            (value?.length ?? 0) > 0
        ) {
            isFirstUpdate.current = false;
            setFileList(
                (value as string[]).map(
                    (e, i) =>
                        ({
                            url: e,
                            uid: `-${i + 1}`,
                            name: `${namePrefix}${i + 1}`,
                            status: "done",
                        } as UploadFile)
                )
            );
        } else if (isString(value) && value) {
            setFileList(
                [value].map(
                    (e, i) =>
                        ({
                            url: e,
                            uid: `-${i + 1}`,
                            name: `${namePrefix}${i + 1}`,
                            status: "done",
                        } as UploadFile)
                )
            );
        }
    }, [value, value?.length, namePrefix]);

    return useMemo(() => {
        return {
            fileList,
            handleChange,
            setFileList,
        };
    }, [fileList, handleChange, setFileList]);
};

const useHandleRemove = () => {
    return useCallback((file: UploadFile): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: "删除图片",
                content: (
                    <p>
                        <img
                            src={file.url || file.response[0].url}
                            width="300"
                            alt=""
                        />
                        <br />
                        <br />
                        确认删除此图片吗？
                    </p>
                ),
                icon: <ExclamationCircleOutlined />,
                okType: "danger",
                onOk: () => {
                    resolve(true);
                },
                onCancel: () => {
                    reject();
                },
            });
        });
    }, []);
};

type DirectionType = "horizontal" | "vertical" | "minHorizontal" | undefined;
const UploadButtonComponent = ({
    direction = "horizontal",
    buttonText = "上传图片",
}: {
    direction: DirectionType;
    buttonText?: string;
}) => {
    const isHorizontal = direction === "horizontal";
    return (
        <div
            style={{
                display: "flex",
                flexDirection: isHorizontal ? "column" : "row",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <PlusOutlined />
            <div style={{ marginTop: isHorizontal ? 8 : 0 }}>{buttonText}</div>
        </div>
    );
};

const SinglePreview = ({
    onChange,
    handlePreview,
    value,
    file,
    disabled,
    showRemove = true,
    showViewImage,
    direction,
}: {
    onChange?: (value: string[] | string) => void;
    handlePreview: (file: UploadFile) => void;
    value: string;
    file: UploadFile;
    disabled?: boolean;
    showRemove?: boolean;
    showViewImage?: boolean;
    direction?: DirectionType;
}) => {
    const handleRemove = useHandleRemove();
    return (
        <div
            className={cn(styles.previewImage, {
                [styles.previewImageMinHorizontal]: direction === 'minHorizontal',
            })}
            style={
                {
                    backgroundImage: `url(${value})`,
                } as React.CSSProperties
            }
        >
            <div className={styles.previewBox}>
                {showViewImage && (
                    <Button
                        onClick={(e) => {
                            handlePreview(file);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        icon={<EyeOutlined />}
                    />
                )}
                <Button icon={<UploadOutlined />} />
                {showRemove && (
                    <Button
                        disabled={disabled}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRemove(file).then(() => {
                                onChange?.("");
                            });
                        }}
                        icon={<DeleteOutlined />}
                    />
                )}
            </div>
        </div>
    );
};

interface PreviewModalMethod {
    preview: (file: UploadFile) => Promise<void>;
}

const PreviewModalComponent: ForwardRefRenderFunction<PreviewModalMethod> = (
    _,
    ref
) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const handleCancel = useCallback(() => setPreviewVisible(false), []);

    useImperativeHandle(
        ref,
        () => ({
            preview: async (file: UploadFile) => {
                if (!file.url && !file.preview && file.originFileObj) {
                    // eslint-disable-next-line no-param-reassign
                    file.preview = await getBase64(file.originFileObj);
                }
                setPreviewImage(file.url ?? file.preview ?? "");
                setPreviewVisible(true);
                setPreviewTitle(
                    file.name ??
                        file.url?.substring(file.url.lastIndexOf("/") + 1)
                );
            },
        }),
        []
    );

    return (
        <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
        >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
    );
};

const PreviewModal = forwardRef(PreviewModalComponent);

export const UploadComponent: FunctionComponent<UploadImgProps> = ({
    value = "",
    disabled,
    onChange,
    direction,
    buttonText,
    shape,
    uploadRequestApi = uploadDefaultHandle,
    folder,
    ...rest
}) => {
    const customUpload = useCustomUpload(uploadRequestApi, folder);
    const modalRef = useRef() as React.MutableRefObject<PreviewModalMethod>;
    const { fileList, handleChange } = useFileList(onChange, value);
    const handleRemove = useHandleRemove();

    const handlePreview = useCallback((file: UploadFile) => {
        modalRef.current.preview(file);
    }, []);

    const uploadProps = useMemo(() => {
        const resProps = {
            customRequest: customUpload,
            listType: "picture-card",
            className: styles.uploadListInline,
            beforeUpload: rest?.beforeUpload ?? beforeUpload,
            onPreview: handlePreview,
            onChange: handleChange,
            onRemove: handleRemove,
            disabled,
        };
        if (isArray(value)) {
            Object.assign(resProps, {
                showUploadList: true,
                fileList,
            });
        }
        if (isString(value)) {
            Object.assign(resProps, {
                showUploadList: false,
            });
        }
        return resProps;
    }, [
        customUpload,
        rest?.beforeUpload,
        fileList,
        disabled,
        handleChange,
        handlePreview,
        handleRemove,
        value,
    ]);

    return (
        <>
            <div
                className={cn(styles.uploadWrapper, {
                    [styles.uploadWrapperVertical]: direction === "vertical",
                    [styles.uploadWrapperHorizontal]:
                        direction === "horizontal",
                    [styles.uploadWrapperMinHorizontal]: direction === 'minHorizontal'
                })}
            >
                <Upload
                    {...(uploadProps as UploadProps)}
                    className={cn({
                        [styles.circle]: shape === "circle",
                    })}
                >
                    {isArray(value) && (fileList?.length ?? 0) < 8 ? (
                        <UploadButtonComponent
                            buttonText={buttonText}
                            direction={direction}
                        />
                    ) : (
                        <>
                            {!value && (
                                <PlusOutlined className={styles.plusOutIcon} />
                            )}
                        </>
                    )}
                    {isString(value) && value && (
                        <SinglePreview
                            direction={direction}
                            disabled={disabled}
                            file={fileList?.[0] as UploadFile}
                            onChange={onChange}
                            value={value as string}
                            handlePreview={handlePreview}
                        />
                    )}
                </Upload>
            </div>
            <PreviewModal ref={modalRef} />
        </>
    );
};
