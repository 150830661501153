import { ReactElement, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import styles from "./styles.module.less";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { RouteName } from "../../service/route/interface";
import { fetchWorkList } from "../../service/request/apis/work";
import {
    CommonStatusEnum,
    DraftStatusEnum,
    StatusEnum,
} from "../../service/request/interface";
import { fetchIdeasList } from "../../service/request/apis/ideas";
import { fetchRecruitmentList } from "../../service/request/apis/recruitment";
import {
    roleWithRouteName,
    useUserMenuPermissionByRule,
} from "../../service/route/useUserMenuPermissionByRule";
import { fetchTeamList } from "../../service/request/apis/team";
import { fetchJobList } from "../../service/request/apis/job";

interface DashboardSource {
    draftCount: number;
    waitPublishCount: number;
    workCount: number;
    ideasCount: number;
    recruitmentCount: number;
    teamCount: number;
    jobCount: number;
    workDraftCount: number;
    ideasDraftCount: number;
}

let cacheData: DashboardSource;
export const DashboardPage = (): ReactElement => {
    const [data, setData] = useState<DashboardSource>(cacheData);

    useEffect(() => {
        Promise.all([
            // 待发布
            fetchWorkList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
                isUnPublish: CommonStatusEnum.YES,
                draftStatus: DraftStatusEnum.DraftStatusNo,
            }).then((res) => res.total),
            // works
            fetchWorkList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
                isUnPublish: CommonStatusEnum.NO,
                draftStatus: DraftStatusEnum.DraftStatusNo,
            }).then((res) => res.total),
            // ideas
            fetchIdeasList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
                draftStatus: DraftStatusEnum.DraftStatusNo,
            }).then((res) => res.total),
            // work草稿
            fetchWorkList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
                draftStatus: DraftStatusEnum.DraftStatusYes,
            }).then((res) => res.total),
            // ideas草稿
            fetchIdeasList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
                draftStatus: DraftStatusEnum.DraftStatusYes,
            }).then((res) => res.total),
            // ideas草稿
            fetchRecruitmentList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
            }).then((res) => res.total),
            // 人员
            fetchTeamList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
            }).then((res) => res.total),
            // 职能
            fetchJobList({
                pageNum: 1,
                pageSize: 1,
                status: StatusEnum.StatusOnline,
            }).then((res) => res.total),
        ]).then((res) => {
            const [
                waitPublishCount,
                workCount,
                ideasCount,
                workDraftCount,
                ideasDraftCount,
                recruitmentCount,
                teamCount,
                jobCount,
            ] = res;
            cacheData = {
                workCount,
                ideasCount,
                waitPublishCount,
                workDraftCount,
                ideasDraftCount,
                draftCount: workDraftCount + ideasDraftCount,
                recruitmentCount,
                teamCount,
                jobCount,
            };
            setData(cacheData);
        });
    }, []);

    const serMenuPermissionByRule = useUserMenuPermissionByRule();
    const showItems = useMemo(() => {
        const showWorks = serMenuPermissionByRule(
            roleWithRouteName[RouteName.WORK]
        );
        const showIdeas = serMenuPermissionByRule(
            roleWithRouteName[RouteName.IDEAS]
        );
        const showRecruitment = serMenuPermissionByRule(
            roleWithRouteName[RouteName.RECRUITMENT]
        );
        const showTeam = serMenuPermissionByRule(
            roleWithRouteName[RouteName.USERS]
        );
        const showJob = serMenuPermissionByRule(
            roleWithRouteName[RouteName.FUNCTIONS]
        );
        return {
            showWorks,
            showIdeas,
            showDraft: showWorks || showIdeas,
            waitPublish: showWorks || showIdeas,
            showRecruitment,
            showTeam,
            showJob,
        };
    }, [serMenuPermissionByRule]);

    return (
        <div>
            <PageTitle
                showBorder={false}
                title="控制台"
                subtitle="欢迎使用网站管理系统，今天辛苦了，祝福您工作顺利。"
            />
            <div className="wrapper-content">
                <Row gutter={24}>
                    {showItems.showWorks && (
                        <Col span={12}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>添加新</h5>
                                <Link
                                    to={`/${RouteName.WORK_CREATE}`}
                                    className={styles.content}
                                >
                                    <PlusCircleOutlined
                                        style={{ marginRight: 10 }}
                                    />
                                    WORK项目
                                </Link>
                            </div>
                        </Col>
                    )}
                    {showItems.showIdeas && (
                        <Col span={12}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>添加新</h5>
                                <Link
                                    to={`/${RouteName.IDEAS}`}
                                    className={styles.content}
                                >
                                    <PlusCircleOutlined
                                        style={{ marginRight: 10 }}
                                    />
                                    IDEAS项目
                                </Link>
                            </div>
                        </Col>
                    )}
                </Row>

                {showItems.showDraft && (
                    <Row gutter={24} style={{ marginBottom: 100 }}>
                        <Col span={12}>
                            <div className={styles.card}>
                                <Link
                                    to={`/${RouteName.DRAFT}`}
                                    className={styles.content}
                                >
                                    <span>草稿箱</span>
                                    <span className={styles.extra}>
                                        {showItems?.showWorks &&
                                            !showItems.showIdeas && (
                                                <>{data?.workCount || 0}个</>
                                            )}
                                        {showItems?.showIdeas &&
                                            !showItems.showWorks && (
                                                <>{data?.ideasCount || 0}个</>
                                            )}
                                        {showItems?.showIdeas &&
                                            showItems.showWorks && (
                                                <>{data?.draftCount || 0}个</>
                                            )}
                                    </span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}

                <Row gutter={24}>
                    {showItems.waitPublish && (
                        <Col span={8}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>待发布</h5>
                                <div className={styles.content}>
                                    <span className={styles.value}>
                                        {data?.waitPublishCount || 0}
                                    </span>
                                    <span className={styles.unit}>个</span>
                                </div>
                            </div>
                        </Col>
                    )}
                    {showItems.showWorks && (
                        <Col span={8}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>WORK</h5>
                                <div className={styles.content}>
                                    <span className={styles.value}>
                                        {data?.workCount || 0}
                                    </span>
                                    <span className={styles.unit}>个</span>
                                </div>
                            </div>
                        </Col>
                    )}
                    {showItems.showIdeas && (
                        <Col span={8}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>IDEAS</h5>
                                <div className={styles.content}>
                                    <span className={styles.value}>
                                        {data?.ideasCount || 0}
                                    </span>
                                    <span className={styles.unit}>个</span>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>

                <Row gutter={24}>
                    {showItems.showRecruitment && (
                        <Col span={8}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>招聘发布量</h5>
                                <div className={styles.content}>
                                    <span className={styles.value}>
                                        {data?.recruitmentCount || 0}
                                    </span>
                                    <span className={styles.unit}>个</span>
                                </div>
                            </div>
                        </Col>
                    )}
                    {showItems.showTeam && (
                        <Col span={8}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>人员</h5>
                                <div className={styles.content}>
                                    <span className={styles.value}>
                                        {data?.teamCount || 0}
                                    </span>
                                    <span className={styles.unit}>位</span>
                                </div>
                            </div>
                        </Col>
                    )}
                    {showItems.showJob && (
                        <Col span={8}>
                            <div className={styles.card}>
                                <h5 className={styles.title}>职能</h5>
                                <div className={styles.content}>
                                    <span className={styles.value}>
                                        {data?.jobCount || 0}
                                    </span>
                                    <span className={styles.unit}>个</span>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};
