import { FunctionComponent, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIsLoggedIn } from "../../service/platform/useIsLoggedIn";

const RequireAuth: FunctionComponent = ({ children }) => {
    const isLoggedIn = useIsLoggedIn();
    const location = useLocation();
    if (!isLoggedIn) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <>{children}</>;
};

/**
 *
 * @param param0
 * @returns 需要验证的路由
 */
export const PrivateRoute: FunctionComponent = ({ children }): ReactElement => {
    return <RequireAuth>{children}</RequireAuth>;
};
