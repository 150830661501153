import {
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { PageTitle } from "../../components/PageTitle";
import { PageTable } from "../../components/PageTable";
import { Button, Modal, Space, message } from "antd";
import { useSortable } from "@dnd-kit/sortable";
import {
    DeleteOutlined,
    DragOutlined,
    EditOutlined,
    ScissorOutlined,
    UndoOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
    WorkItem,
    deleteWork,
    fetchWorkList,
    updateWork,
} from "../../service/request/apis/work";
import { ColumnProps } from "antd/es/table";
import {
    CommonStatusEnum,
    DraftStatusEnum,
    RecycleStatusEnum,
} from "../../service/request/interface";
import dayjs from "dayjs";
import { usePagination } from "../../service/pagination/usePagination";
import { useNavigate } from "react-router-dom";
import { CheckboxSwitch } from "../../components/CheckboxSwitch";

const ActionItem = ({
    data,
    onDeleteBatchClick,
    onRecycleClick,
    onRecoverBatchHandle,
}: {
    data: WorkItem;
    onDeleteBatchClick: (guids: string[], isDelete?: CommonStatusEnum) => void;
    onRecycleClick: (data: WorkItem) => void;
    onRecoverBatchHandle: (guid: string) => void;
}) => {
    const { listeners, setActivatorNodeRef } = useSortable({
        id: data.guid!,
    });

    const onDeleteHandle = useCallback(() => {
        Modal.confirm({
            title: "确定销毁该条记录吗?",
            onOk: () => {
                onDeleteBatchClick([data.guid], CommonStatusEnum.YES);
            },
        });
    }, [onDeleteBatchClick, data]);

    const onRecycleHandle = useCallback(() => {
        Modal.confirm({
            title: "确定删除该条记录吗?",
            onOk: () => {
                onRecycleClick(data);
            },
        });
    }, [onRecycleClick, data]);

    return (
        <Space size="small">
            <Button
                title="拖拽进行排序"
                ref={setActivatorNodeRef}
                {...listeners}
                size="small"
                disabled={
                    data.recycleStatus === RecycleStatusEnum.RecycleStatusYes
                }
                icon={
                    <DragOutlined
                        style={{ touchAction: "none", cursor: "move" }}
                    />
                }
            />
            {data.recycleStatus === RecycleStatusEnum.RecycleStatusYes && (
                <Button
                    onClick={() => {
                        onRecoverBatchHandle(data.guid);
                    }}
                    size="small"
                    title="恢复"
                >
                    <UndoOutlined />
                </Button>
            )}
            {data.guid &&
                data.recycleStatus !== RecycleStatusEnum.RecycleStatusYes && (
                    <Link to={`/work/${data.guid}`}>
                        <Button
                            icon={<EditOutlined />}
                            title="编辑"
                            size="small"
                        >
                            编辑
                        </Button>
                    </Link>
                )}
            {data.recycleStatus === RecycleStatusEnum.RecycleStatusYes ? (
                <Button
                    onClick={onDeleteHandle}
                    size="small"
                    title="销毁"
                    danger
                >
                    <ScissorOutlined />
                </Button>
            ) : (
                <Button
                    onClick={onRecycleHandle}
                    danger
                    icon={<DeleteOutlined />}
                    title="删除"
                    size="small"
                />
            )}
        </Space>
    );
};

/** 是否显示 */
const StatusItem = ({
    guid,
    status,
    keyName,
    recycleStatus,
    refresh,
}: {
    guid: string;
    status: CommonStatusEnum;
    recycleStatus?: RecycleStatusEnum;
    keyName: keyof WorkItem;
    refresh: () => void;
}) => {
    const [isSwitch, setIsSwitch] = useState<CommonStatusEnum | undefined>(
        status
    );

    const onChange = useCallback(
        (e) => {
            setIsSwitch(e);
            updateWork({
                guid,
                [keyName]: e,
            }).then(() => {
                refresh();
            });
        },
        [keyName, guid, refresh]
    );

    useEffect(() => {
        setIsSwitch(status);
    }, [status]);

    return (
        <CheckboxSwitch
            disabled={recycleStatus === RecycleStatusEnum.RecycleStatusYes}
            value={status}
            onChange={onChange}
            checked={!!isSwitch}
        />
    );
};

const useColumns = (
    onDeleteBatchClick: (guids: string[], isDelete?: CommonStatusEnum) => void,
    onRecycleClick: (data: WorkItem) => void,
    onRecoverBatchHandle: (guid: string) => void,
    refresh: () => void
) => {
    return useMemo<ColumnProps<WorkItem>[]>(() => {
        return [
            {
                title: "中文名称",
                dataIndex: "titleCn",
                width: '20%',
            },
            {
                title: "英文名称",
                dataIndex: "titleEn",
                width: '20%',
            },
            {
                title: "行业标签",
                render: (_: string, record) => {
                    return record.clientInfo?.industryInfo?.titleCn ?? '';
                },
            },
            {
                title: "排序",
                key: "sort",
                dataIndex: "sort",
            },
            {
                title: "隐藏",
                render: (_: string, record) => {
                    return (
                        <StatusItem
                            keyName="status"
                            status={
                                record.status as unknown as CommonStatusEnum
                            }
                            recycleStatus={record.recycleStatus}
                            refresh={refresh}
                            guid={record.guid}
                        />
                    );
                },
            },
            {
                title: "置顶",
                key: "isTop",
                render: (_: string, record) => {
                    return (
                        <StatusItem
                            keyName="isTop"
                            status={record.isTop!}
                            refresh={refresh}
                            recycleStatus={record.recycleStatus}
                            guid={record.guid}
                        />
                    );
                },
            },
            {
                title: "创建时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
                },
            },
            // {
            //     title: "修改时间",
            //     render: (_: string, record) => {
            //         return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
            //     },
            // },
            {
                title: "完工时间",
                render: (_: string, record) => {
                    return dayjs(record.finishTime ?? 0).format("YYYY-MM-DD");
                },
            },
            {
                title: "操作",
                key: "action",
                render: (_: string, record: any) => {
                    return (
                        <ActionItem
                            onDeleteBatchClick={onDeleteBatchClick}
                            onRecycleClick={onRecycleClick}
                            onRecoverBatchHandle={onRecoverBatchHandle}
                            data={record}
                        />
                    );
                },
            },
        ];
    }, [onDeleteBatchClick, refresh, onRecycleClick, onRecoverBatchHandle]);
};

// const useFormFields = () => {
//     return useMemo(() => {
//         return [
//             <Form.Item label="中文名称">
//                 <Input placeholder="中文名称" />
//             </Form.Item>,
//             <Form.Item label="英文名称">
//                 <Input placeholder="英文名称" />
//             </Form.Item>,
//             <Form.Item label="行业标签">
//                 <Input placeholder="行业标签" />
//             </Form.Item>,
//             <Form.Item label="创建时间">
//                 <Input placeholder="创建时间" />
//             </Form.Item>,
//             <Form.Item label="修改时间">
//                 <Input placeholder="修改时间" />
//             </Form.Item>,
//         ];
//     }, []);
// };

export const WorkPage = ({
    header,
    tableHeader,
    type,
}: {
    header?: ReactNode;
    tableHeader?: ReactNode;
    type?: "draft" | "waitPublish";
}): ReactElement => {
    const navigate = useNavigate();
    const onAddHandle = useCallback(() => {
        navigate("create");
    }, [navigate]);

    const { isRequest, dataSource, pageIndex, onPageIndexChange, fetchAction, pageSize } =
        usePagination(fetchWorkList);

    const onResetDataSource = useCallback(
        (request?: Partial<WorkItem>) => {
            fetchAction({ ...request });
        },
        [fetchAction]
    );

    // 批量回收和销毁
    const onDeleteBatchClick = useCallback(
        (guids: string[], isDelete?: CommonStatusEnum) => {
            const loading = message.loading(
                `${!!isDelete ? "销毁中..." : "删除中..."}`,
                1
            );
            deleteWork({
                guids,
                isDelete,
            })
                .then(() => {
                    loading();
                    message.success(
                        `${!!isDelete ? "销毁成功" : "删除成功"}`,
                        1
                    );
                    onResetDataSource();
                })
                .catch(() => {
                    loading();
                    message.success(
                        `${!!isDelete ? "销毁失败" : "删除失败"}`,
                        1
                    );
                });
        },
        [onResetDataSource]
    );

    const onRecycleHandle = useCallback(
        (item: WorkItem) => {
            deleteWork({
                guids: [item.guid],
            })
                .then(() => {
                    message.success("删除成功", 1);
                    onResetDataSource();
                })
                .catch(() => {
                    message.error("删除失败", 1);
                });
        },
        [onResetDataSource]
    );

    // 恢复
    const onRecoverBatchHandle = useCallback((guid: string) => {
        updateWork({
            guid,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        })
            .then(() => {
                onResetDataSource();
            });
    }, [onResetDataSource]);

    const onSortChange = useCallback(
        (active, over) => {
            if (!over) return;
            updateWork({
                guid: active.guid,
                sort: over.sort,
            })
                .then(() => {
                    onResetDataSource();
                });
        },
        [onResetDataSource]
    );

    const columns = useColumns(
        onDeleteBatchClick,
        onRecycleHandle,
        onRecoverBatchHandle,
        onResetDataSource
    );

    useEffect(() => {
        const draftStatus = type === 'draft' ? DraftStatusEnum.DraftStatusYes : DraftStatusEnum.DraftStatusNo;
        const isUnPublish = type === 'waitPublish' ? CommonStatusEnum.YES : CommonStatusEnum.NO;
        onResetDataSource({ recycleStatus: RecycleStatusEnum.RecycleStatusNo, draftStatus, isUnPublish });
    }, [onResetDataSource, type]);

    return (
        <div>
            {!!header ? (
                header
            ) : (
                <PageTitle
                    title="Work"
                    subtitle="Work内容展示"
                    onAddHandle={onAddHandle}
                />
            )}
            <PageTable
                hideStatusSelect={!!type}
                onDeleteAllClick={onDeleteBatchClick}
                tableHeader={tableHeader}
                onRequestChange={onResetDataSource}
                onSortChange={onSortChange}
                tableProps={{
                    scroll: { x: '1400px' },
                    loading: isRequest,
                    columns: columns as any,
                    dataSource: dataSource?.list,
                    pagination: {
                        current: pageIndex,
                        pageSize,
                        onChange: onPageIndexChange,
                        showQuickJumper: true,
                        total: dataSource?.total ?? 0,
                        showSizeChanger: true,
                    },
                }}
                // fields={fields}
            />
        </div>
    );
};
