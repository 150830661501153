import { ReactElement } from "react";
import styles from "./styles.module.less";
import { Button } from "antd";
import cn from "classnames";
import { PlusCircleOutlined } from "@ant-design/icons";

export const PageTitle = ({
    title,
    subtitle,
    onAddHandle,
    onRecycleHandle,
    showBorder = true,
}: {
    title: string;
    subtitle: string;
    onAddHandle?: () => void;
    onRecycleHandle?: () => void;
    showBorder?: boolean;
}): ReactElement => {
    return (
        <div
            className={cn(styles.container, {
                [styles.border]: showBorder,
            })}
        >
            <div className={styles.titleGroup}>
                <h3 className={styles.title}>{title}</h3>
                <h5 className={styles.subtitle}>{subtitle}</h5>
            </div>
            {(!!onAddHandle || !!onRecycleHandle) && (
                <div className={styles.buttons}>
                    <Button onClick={onAddHandle} icon={<PlusCircleOutlined />}>
                        新增
                    </Button>
                </div>
            )}
        </div>
    );
};
