import { Checkbox, Col, Row, SelectProps } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useRefWrapper } from "../../service/useRefWrapper";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const powerConfig = [
    { label: "WORK权限", value: "admin.biz.work.*" },
    { label: "IDEAS权限", value: "admin.biz.idea.*" },
    { label: "客户权限", value: "admin.biz.client.*" },
    { label: "行业类型权限", value: "admin.biz.industry.*" },
    { label: "服务类型权限", value: "admin.biz.service.*" },
    { label: "IDEAS类型权限", value: "admin.biz.category.*" },
    { label: "人员权限", value: "admin.biz.team.*" },
    { label: "职能权限", value: "admin.biz.job.*" },
    { label: "About权限", value: "admin.system.setting.*", hidden: true },
    { label: "招聘权限", value: "admin.biz.recruitment.*" },
    { label: "控制台", value: "dashboard" },
];

// admin.system.setting.*
const aboutRoles = [
    "admin.biz.recruitment.*",
    "admin.biz.job.*",
    "admin.biz.team.*",
];

export const PermissionFormList = ({
    value,
    onChange,
}: {
    value?: string[];
    onChange?: (value: CheckboxValueType[]) => void;
    selectProps?: SelectProps;
}): ReactElement => {
    const [options] =
        useState<{ label: string; value: string; hidden?: boolean }[]>(
            powerConfig
        );
    const [checkAll, setCheckAll] = useState(false);
    const onChangeRef = useRefWrapper(onChange);
    const [indeterminate, setIndeterminate] = useState(false);

    const onChangeHandle = useCallback(
        (values: CheckboxValueType[]) => {
            const newValues = [ ...values ];
            if (newValues.some((item) => aboutRoles.includes(item as string))) {
                // 如果选择了招聘、人员、和职能，则自动加上about权限
                if (!newValues.includes('admin.system.setting.*')) {
                    newValues.push("admin.system.setting.*");
                }
            } else {
                const index = newValues.indexOf('admin.system.setting.*');
                if (index !== -1) {
                    newValues.splice(index, 1);
                }
            }
            if (onChangeRef.current) {
                onChangeRef.current(newValues);
            }
            setIndeterminate(
                !!values.length && values.length < powerConfig.length
            );
            setCheckAll(values.length === powerConfig.length);
        },
        [onChangeRef]
    );

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        if (onChangeRef.current) {
            onChangeRef.current(
                e.target.checked ? powerConfig.map((v) => v.value) : []
            );
        }
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    useEffect(() => {
        if (!value) return;
        setIndeterminate(!!value.length && value.length < powerConfig.length);
    }, [value]);
    console.log("value: ", value);

    return (
        <>
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                style={{ marginTop: 5 }}
            >
                所有权限
            </Checkbox>
            <Checkbox.Group
                style={{ width: "100%" }}
                value={value}
                onChange={onChangeHandle}
            >
                <Row>
                    {options.map((o, idx) => {
                        return (
                            <Col
                                key={idx}
                                span={6}
                                style={
                                    o.hidden
                                        ? {
                                              padding: "0",
                                              visibility: "hidden",
                                              overflow: "hidden",
                                              width: 0,
                                              height: 0,
                                              flex: 0,
                                          }
                                        : { padding: "5px 0" }
                                }
                            >
                                <Checkbox value={o.value}>{o.label}</Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </Checkbox.Group>
        </>
    );
};
