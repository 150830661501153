import axios from "axios";
import { useEffect, useState } from "react";

interface WeatherDataSource {
    /** 时间 */
    date: string;
    /** 星期几 */
    week: string;
    /** 城市 */
    city: string;
    /** 城市英文 */
    cityEn: string;
    /** 国家 */
    country: string;
    /** 国家英文 */
    countryEn: string;
    /** 天气 */
    wea: string;
    /** 天气图标 */
    wea_img: string;
    /** 当前温度 */
    tem: string;
    /** 最高温度 */
    tem1: string;
    /** 最低温度 */
    tem2: string;
}

export const useWeather = () => {
    const [data, setData] = useState<WeatherDataSource>();
    useEffect(() => {
        axios
            .get<WeatherDataSource, WeatherDataSource[]>(
                "https://v0.yiketianqi.com/api?unescape=1&version=v91&appid=43656176&appsecret=I42og6Lm&ext=&cityid=&city="
            )
            .then((res) => {
                if (!res?.length) return;
                const [value] = res;
                setData(value);
            });
    }, []);

    return data;
};
