import { useAuthState } from "./useAuthState";

/**
 *
 * @returns 是否登录
 */
export const useIsLoggedIn = () => {
    const authState = useAuthState();
    return authState.isLoggedIn;
};
