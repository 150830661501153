import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.less";
import {
    Button,
    Col,
    Collapse,
    DatePicker,
    Form,
    FormInstance,
    FormListFieldData,
    Input,
    Modal,
    Row,
    Select,
    Space,
    message,
} from "antd";
import { UploadComponent } from "../../components/Upload";
import {
    DeleteFilled,
    EditOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import { PageTitle } from "../../components/PageTitle";
import {
    AboutItem,
    fetchAboutDetails,
    updateAbout,
} from "../../service/request/apis/about";
import { BraftEditorBase } from "../../components/Editor";
import { config } from "../../service/config";
import { RecycleStatusEnum, StatusEnum } from "../../service/request/interface";
import { JobItem, fetchJobList } from "../../service/request/apis/job";
import { WorkItem, fetchWorkList } from "../../service/request/apis/work";
import { IdeasItem, fetchIdeasList } from "../../service/request/apis/ideas";
import dayjs from "dayjs";
import { useBlocking } from "../../service/useBlocking";
import { CheckboxSwitch } from "../../components/CheckboxSwitch";

const { Panel } = Collapse;
const { Item, List } = Form;

// 职位选项
const useJobOptions = () => {
    const [jobOptions, setData] =
        useState<{ label: string; value: string }[]>();
    const [jobList, setJobList] = useState<JobItem[]>();
    useEffect(() => {
        fetchJobList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setData(
                res.list.map((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
            setJobList(res.list);
        });
    }, []);
    return { jobList, jobOptions };
};

// 新闻或出版物选项
const useIdeasList = () => {
    const [ideasList, setData] = useState<IdeasItem[]>();

    useEffect(() => {
        fetchIdeasList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setData(res.list);
        });
    }, []);

    return ideasList;
};

const useIdeasOptions = (ideas?: IdeasItem[], title?: string, guid?: string) => {
    return useMemo(() => {
        if (!ideas) return { options: [], list: [] };
        const list = ideas.filter(
            (item) => {
                if (guid) {
                    return guid === item.categoryGuid;
                }
                return item.categoryInfo.titleCn === title;
            }
        );
        const options = list.map((item) => ({
            label: item.titleCn!,
            value: item.guid!,
        }));
        return { options, list };
    }, [ideas, title, guid]);
}


// 项目选项
const useWorksOptions = () => {
    const [workOptions, setOptions] =
        useState<{ label: string; value: string }[]>();
    const [workList, setData] = useState<WorkItem[]>();

    useEffect(() => {
        fetchWorkList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setOptions(
                res.list.map((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
            setData(res.list);
        });
    }, []);
    return { workList, workOptions };
};

const useFormatDataSource = (form: FormInstance, data?: AboutItem) => {
    const [isInitialValues, setIsInitialValues] = useState(false);
    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
            setTimeout(() => {
                setIsInitialValues(true);
            }, 200);
        } else {
            setIsInitialValues(false);
        }
    }, [form, data]);
    return isInitialValues;
};

interface SubmitRequestTransformParams {
    jobList?: JobItem[];
    workList?: WorkItem[];
    newsList?: IdeasItem[];
    publicationList?: IdeasItem[];
}

const useFinish = (
    props: SubmitRequestTransformParams,
    fetchDetails: () => void,
    details?: AboutItem
) => {
    return useCallback(
        (values) => {
            const loading = message.loading("保存中...", 0);
            const { jobList, workList, newsList, publicationList } = props;

            const newValues: AboutItem = {
                ...values,
            };

            if (newValues.organization) {
                newValues.organization = newValues.organization.map((item) => {
                    const job = jobList?.find((j) => j.guid === item.job.guid);
                    if (!job) return item;
                    return {
                        ...item,
                        job,
                    };
                });
            }

            if (newValues.newsList) {
                newValues.newsList = newValues.newsList.map((item) => {
                    const news = newsList?.find(
                        (j) => j.guid === item.news?.guid
                    );
                    if (!news) return item;

                    return {
                        news: {
                            guid: news.guid,
                            titleCn: news.titleCn,
                            titleEn: news.titleEn,
                            banner: news.banner,
                            thumbnail: news.thumbnail,
                            projectTime: news.projectTime,
                            finishTime: news.finishTime,
                        },
                    };
                });
            }

            if (newValues.works) {
                newValues.works = newValues.works.map((item) => {
                    const work = workList?.find(
                        (j) => j.guid === item.work?.guid
                    );
                    if (!work) return item;
                    return {
                        ...item,
                        work: {
                            guid: work.guid,
                            clientInfo: {
                                titleCn: work.clientInfo.titleCn,
                                titleEn: work.clientInfo.titleEn,
                            },
                            titleCn: work.titleCn,
                            titleEn: work.titleEn,
                            finishTime: work.finishTime,
                        },
                    };
                });
            }

            if (newValues.publications) {
                newValues.publications = newValues.publications.map((item) => {
                    const publication = publicationList?.find(
                        (j) => j.guid === item.publication?.guid
                    )!;

                    return {
                        publication: {
                            guid: publication.guid,
                            titleCn: publication.titleCn,
                            titleEn: publication.titleEn,
                            banner: publication.banner,
                            thumbnail: publication.thumbnail,
                            projectTime: publication.projectTime,
                            finishTime: publication.finishTime,
                        },
                    };
                });
            }

            const requestValues = { ...details, ...newValues };
            return updateAbout(requestValues)
                .then(() => {
                    loading();
                    fetchDetails();
                    message.success("保存成功", 1);
                })
                .catch((error) => {
                    loading();
                    message.error(`保存失败: ${error?.msg || ""}`, 1);
                });
        },
        [props, fetchDetails, details]
    );
};

const usePreview = (
    form: FormInstance,
    props: SubmitRequestTransformParams,
    fetchDetails: () => void,
    details?: AboutItem
) => {
    const finish = useFinish(props, fetchDetails, details);
    return useCallback(() => {
        form.validateFields().then(() => {
            finish(form.getFieldsValue()).then((res) => {
                window.open(`${config.WEB_SITE}about`);
            });
        });
    }, [form, finish]);
};

const useFetchDetails = () => {
    const [details, setData] = useState<AboutItem>();
    const fetchDetails = useCallback(() => {
        fetchAboutDetails()
            .then((res) => {
                setData(res);
            })
            .catch(() => {
                message.error("获取信息失败，请联系管理员", 1);
            });
    }, []);
    useEffect(() => {
        fetchDetails();
    }, [fetchDetails]);

    return { details, fetchDetails };
};

const WorkProjectModal = ({
    data,
    onCancel,
    onSubmit,
    workOptions,
}: {
    workOptions: { label: string; value: string }[] | undefined;
    onCancel: () => void;
    data?: { data: Record<string, string>; field: FormListFieldData };
    onSubmit: (
        values: Record<string, string>,
        data?: { data: Record<string, string>; field: FormListFieldData }
    ) => void;
}) => {
    const [form] = Form.useForm();
    const onFinish = useCallback(
        (values) => {
            onCancel();
            onSubmit(values, data);
        },
        [onSubmit, onCancel, data]
    );

    const newData = useMemo(() => {
        return {
            ...data?.data,
            award_time: data?.data?.award_time
                ? dayjs(data.data.award_time)
                : undefined,
        };
    }, [data]);

    return (
        <Modal
            onCancel={onCancel}
            onOk={() => {
                form.validateFields().then(() => {
                    form.submit();
                });
            }}
            width="60%"
            style={{ maxWidth: 868 }}
            title={!!data?.data ? "编辑奖项" : "添加奖项"}
            open={true}
            className="modal-header ant-modal-custom"
        >
            <Form
                onFinish={onFinish}
                form={form}
                initialValues={newData}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 8 }}
            >
                <Item
                    rules={[{ message: "请选择对应项目", required: true }]}
                    name={["work", "guid"]}
                    label="对应项目"
                >
                    <Select options={workOptions} placeholder="选择对应项目" />
                </Item>
                <Item
                    name="awardUrl"
                    label="原始奖项"
                    rules={[{ required: true, message: "请输入原始奖项" }]}
                >
                    <Input placeholder="原始奖项" />
                </Item>
                <Item
                    name="award_cat"
                    label="奖项类别"
                    rules={[{ required: true, message: "请输入奖项类别" }]}
                >
                    <Input placeholder="奖项类别" />
                </Item>
                <Item
                    name="award_level"
                    label="获奖等级"
                    rules={[{ required: true, message: "请输入奖项等级" }]}
                >
                    <Input placeholder="获奖等级" />
                </Item>
                <Item
                    rules={[{ required: true, message: "请选择日期" }]}
                    name="award_time"
                    label="获奖日期"
                >
                    <DatePicker format="YYYY-MM" />
                </Item>
                <Item
                    name="icon"
                    label="获奖图标"
                    rules={[{ required: true, message: "请上传奖项图标" }]}
                >
                    <UploadComponent direction="minHorizontal" />
                </Item>
            </Form>
        </Modal>
    );
};

export const AboutPage = (): ReactElement => {
    const [form] = Form.useForm();

    const ideasList = useIdeasList();
    const { options: newsOptions, list: newsList } =
        useIdeasOptions(ideasList, "项目分析");
    const { options: publicationOptions, list: publicationList } =
        useIdeasOptions(ideasList, "", '954650665651556352');

    const { workOptions, workList } = useWorksOptions();
    const { jobOptions, jobList } = useJobOptions();
    const params = {
        jobList,
        workList,
        newsList,
        publicationList,
    };
    const { details, fetchDetails } = useFetchDetails();

    const [showProject, setShowProjectModal] = useState<{
        data: Record<string, string>;
        field: FormListFieldData;
    }>();
    const onFinish = useFinish(params, fetchDetails, details);
    const onPreview = usePreview(form, params, fetchDetails, details);
    const isInitDefaultValue = useFormatDataSource(form, details);
    const blockerRef = useRef<any>();

    const onSubmit = useCallback(() => {
        return form
            .validateFields()
            .then((values) => {
                return onFinish(values);
            })
            .then(() => {
                blockerRef.current?.();
            })
            .catch((errorInfo) => {
                console.log('errorInfo: ', errorInfo);
                
                const msg =
                    errorInfo.errorFields?.[0]?.errors?.[0] ||
                    "请检查是否有遗漏字段或字段格式错误";
                if (msg) {
                    message.error(msg, 1.5);
                }
            });
    }, [form, onFinish]);

    const { setIsBlocking, proceed } = useBlocking(onSubmit);
    blockerRef.current = proceed;
    
    const onValuesChange = useCallback(() => {
        if (!isInitDefaultValue) return;
        setIsBlocking(true);
    }, [isInitDefaultValue, setIsBlocking]);

    return (
        <div className={styles.container}>
            <PageTitle title="ABOUT" subtitle="公司介绍页面的信息编辑与管理" />
            <div className={styles.content}>
                <Form
                    labelCol={{ span: 3 }}
                    requiredMark={false}
                    onFinish={onFinish}
                    form={form}
                    onValuesChange={onValuesChange}
                >
                    <Item hidden name="contact">
                        <Input />
                    </Item>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Collapse
                            expandIconPosition="end"
                            defaultActiveKey={["company_info"]}
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        公司介绍
                                    </span>
                                }
                                key="company_info"
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "中文简介不能为空",
                                                },
                                            ]}
                                            label="中文简介"
                                            name="companyInfoCn"
                                        >
                                            <BraftEditorBase />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "英文简介不能为空",
                                                },
                                            ]}
                                            label="英文简介"
                                            name="companyInfoEn"
                                        >
                                            <BraftEditorBase />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        联系方式
                                    </span>
                                }
                                key="connect_info"
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item label="电话" name="connectPhone">
                                            <Input placeholder="电话" />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item
                                            label="电子邮件"
                                            name="connectEmail"
                                        >
                                            <Input placeholder="电子邮件" />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item
                                            label="中文地址"
                                            name="connectAddressCn"
                                        >
                                            <Input placeholder="中文地址" />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item
                                            label="英文地址"
                                            name="connectAddressEn"
                                        >
                                            <Input placeholder="英文地址" />
                                        </Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item
                                            label="隐藏"
                                            name="hideConnectInfo"
                                            valuePropName="checked"
                                        >
                                            <CheckboxSwitch />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        社交媒体链接
                                    </span>
                                }
                                key="media_info"
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item label="公众号" name="mp">
                                            <UploadComponent direction="minHorizontal" />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item label="微博" name="weibo">
                                            <Input placeholder="微博" />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item label="小红书" name="xiaohongshu">
                                            <Input placeholder="小红书" />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item label="抖音" name="douyin">
                                            <Input placeholder="抖音" />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item label="Be." name="be">
                                            <Input placeholder="Be." />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item label="Pin." name="pin">
                                            <Input placeholder="Pin." />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item
                                            label="隐藏"
                                            name="hideMediaInfo"
                                            valuePropName="checked"
                                        >
                                            <CheckboxSwitch />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        组织架构
                                    </span>
                                }
                                key="organization"
                            >
                                <List name="organization">
                                    {(fields, { add, remove }) => {
                                        return (
                                            <Space
                                                direction="vertical"
                                                style={{ width: "100%" }}
                                            >
                                                {fields.map((field) => {
                                                    return (
                                                        <Row
                                                            gutter={24}
                                                            key={field.name}
                                                        >
                                                            <Col span={6}>
                                                                <Item
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    name={[
                                                                        field.name,
                                                                        "job",
                                                                        "guid",
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        options={
                                                                            jobOptions
                                                                        }
                                                                        placeholder="选择职位"
                                                                    />
                                                                </Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Item
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    name={[
                                                                        field.name,
                                                                        "number",
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        type="number"
                                                                        placeholder="人员数量"
                                                                    />
                                                                </Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Button
                                                                    onClick={() => {
                                                                        remove(
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <DeleteFilled />
                                                                    }
                                                                ></Button>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Button
                                                            onClick={() => {
                                                                add();
                                                            }}
                                                            icon={
                                                                <PlusCircleOutlined />
                                                            }
                                                        >
                                                            添加
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        );
                                    }}
                                </List>

                                <Row gutter={24} style={{ paddingTop: 20 }}>
                                    <Col span={12}>
                                        <Item
                                            label="隐藏"
                                            name="hideOrganization"
                                            valuePropName="checked"
                                        >
                                            <CheckboxSwitch />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        新闻
                                    </span>
                                }
                                key="newsList"
                            >
                                <List name="newsList">
                                    {(fields, { add, remove }) => {
                                        return (
                                            <Space
                                                direction="vertical"
                                                style={{ width: "100%" }}
                                            >
                                                {fields.map((field) => {
                                                    return (
                                                        <Row
                                                            gutter={24}
                                                            key={field.name}
                                                        >
                                                            <Col span={12}>
                                                                <Item
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    name={[
                                                                        field.name,
                                                                        "news",
                                                                        "guid",
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        options={
                                                                            newsOptions
                                                                        }
                                                                        placeholder="选择文章"
                                                                    />
                                                                </Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Button
                                                                    onClick={() => {
                                                                        remove(
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <DeleteFilled />
                                                                    }
                                                                ></Button>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Button
                                                            onClick={() => {
                                                                add();
                                                            }}
                                                            icon={
                                                                <PlusCircleOutlined />
                                                            }
                                                        >
                                                            添加
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        );
                                    }}
                                </List>
                                <Row gutter={24} style={{ paddingTop: 20 }}>
                                    <Col span={12}>
                                        <Item
                                            label="隐藏"
                                            name="hideNews"
                                            valuePropName="checked"
                                        >
                                            <CheckboxSwitch />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        奖项
                                    </span>
                                }
                                key="works"
                            >
                                <List name="works">
                                    {(fields, { add, remove }) => {
                                        return (
                                            <Space
                                                direction="vertical"
                                                style={{ width: "100%" }}
                                            >
                                                {fields.map((field) => {
                                                    return (
                                                        <Row
                                                            gutter={24}
                                                            key={field.name}
                                                        >
                                                            <Col span={7}>
                                                                <Item
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    name={[
                                                                        field.name,
                                                                        "work",
                                                                        "guid",
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        disabled
                                                                        options={
                                                                            workOptions
                                                                        }
                                                                        placeholder="选择奖项"
                                                                    />
                                                                </Item>
                                                            </Col>
                                                            <Item
                                                                hidden
                                                                name={[
                                                                    field.name,
                                                                    "award_cat",
                                                                ]}
                                                            />
                                                            <Item
                                                                hidden
                                                                name={[
                                                                    field.name,
                                                                    "award_level",
                                                                ]}
                                                            />
                                                            <Item
                                                                hidden
                                                                name={[
                                                                    field.name,
                                                                    "award_time",
                                                                ]}
                                                            />
                                                            <Item
                                                                hidden
                                                                label="获奖网址"
                                                                name={[
                                                                    field.name,
                                                                    "awardUrl",
                                                                ]}
                                                            >
                                                                <Input placeholder="获奖网址" />
                                                            </Item>
                                                            <Col
                                                                span={10}
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <Item
                                                                    hidden
                                                                    label="项目图标"
                                                                    name={[
                                                                        field.name,
                                                                        "icon",
                                                                    ]}
                                                                >
                                                                    <UploadComponent direction="minHorizontal" />
                                                                </Item>
                                                                <Button
                                                                    style={{
                                                                        marginBottom: 5,
                                                                        marginLeft: 25,
                                                                    }}
                                                                    onClick={() => {
                                                                        setShowProjectModal(
                                                                            {
                                                                                field,
                                                                                data: form.getFieldValue(
                                                                                    [
                                                                                        "works",
                                                                                        field.name,
                                                                                    ]
                                                                                ),
                                                                            }
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <EditOutlined />
                                                                    }
                                                                />
                                                                <Button
                                                                    style={{
                                                                        marginBottom: 5,
                                                                        marginLeft: 25,
                                                                    }}
                                                                    onClick={() => {
                                                                        remove(
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <DeleteFilled />
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Button
                                                            onClick={() => {
                                                                setShowProjectModal(
                                                                    {} as {
                                                                        data: Record<
                                                                            string,
                                                                            string
                                                                        >;
                                                                        field: FormListFieldData;
                                                                    }
                                                                );
                                                            }}
                                                            icon={
                                                                <PlusCircleOutlined />
                                                            }
                                                        >
                                                            添加
                                                        </Button>
                                                        {showProject && (
                                                            <WorkProjectModal
                                                                data={
                                                                    showProject
                                                                }
                                                                onSubmit={(
                                                                    newValues,
                                                                    edit
                                                                ) => {
                                                                    if (
                                                                        edit?.field
                                                                    ) {
                                                                        const newWorks =
                                                                            form.getFieldValue(
                                                                                "works"
                                                                            ) as WorkItem[];
                                                                        if (
                                                                            newWorks
                                                                        ) {
                                                                            form.setFieldValue(
                                                                                "works",
                                                                                newWorks.map(
                                                                                    (
                                                                                        item,
                                                                                        idx
                                                                                    ) => {
                                                                                        if (
                                                                                            idx ===
                                                                                            edit
                                                                                                .field
                                                                                                .name
                                                                                        ) {
                                                                                            return newValues;
                                                                                        }
                                                                                        return item;
                                                                                    }
                                                                                )
                                                                            );
                                                                        }
                                                                    } else {
                                                                        add(
                                                                            newValues
                                                                        );
                                                                    }
                                                                }}
                                                                workOptions={
                                                                    workOptions
                                                                }
                                                                onCancel={() => {
                                                                    setShowProjectModal(
                                                                        undefined
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Space>
                                        );
                                    }}
                                </List>
                                <Row gutter={24} style={{ paddingTop: 20 }}>
                                    <Col span={12}>
                                        <Item
                                            label="隐藏"
                                            name="hideWorks"
                                            valuePropName="checked"
                                        >
                                            <CheckboxSwitch />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        出版物
                                    </span>
                                }
                                key="publications"
                            >
                                <List name="publications">
                                    {(fields, { add, remove }) => {
                                        return (
                                            <Space
                                                direction="vertical"
                                                style={{ width: "100%" }}
                                            >
                                                {fields.map((field) => {
                                                    return (
                                                        <Row
                                                            gutter={24}
                                                            key={field.name}
                                                        >
                                                            <Col span={12}>
                                                                <Item
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    name={[
                                                                        field.name,
                                                                        "publication",
                                                                        "guid",
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        options={
                                                                            publicationOptions
                                                                        }
                                                                        placeholder="选择出版物"
                                                                    />
                                                                </Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Button
                                                                    onClick={() => {
                                                                        remove(
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <DeleteFilled />
                                                                    }
                                                                ></Button>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Button
                                                            onClick={() => {
                                                                add();
                                                            }}
                                                            icon={
                                                                <PlusCircleOutlined />
                                                            }
                                                        >
                                                            添加
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        );
                                    }}
                                </List>
                                <Row gutter={24} style={{ paddingTop: 20 }}>
                                    <Col span={12}>
                                        <Item
                                            label="隐藏"
                                            name="hidePublication"
                                            valuePropName="checked"
                                        >
                                            <CheckboxSwitch />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        {/* <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        招聘方式与人力资源
                                    </span>
                                }
                                key="recruit_connect"
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item
                                            label="招聘邮箱"
                                            name="recruitEmail"
                                        >
                                            <Input placeholder="招聘邮箱" />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item
                                            label="联系电话"
                                            name="recruitPhone"
                                        >
                                            <Input placeholder="联系电话" />
                                        </Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Item
                                            label="人力资源"
                                            name="recruitTeamGuid"
                                        >
                                            <Select placeholder="选择人员" />
                                        </Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                        <Collapse
                            expandIconPosition="end"
                            className={styles.collapseCustom}
                        >
                            <Panel
                                header={
                                    <span className={styles.panelTitle}>
                                        招聘职位信息
                                    </span>
                                }
                                key="recruit"
                            >
                                <List name="recruit">
                                    {(fields, { add, remove }) => {
                                        return (
                                            <Space
                                                direction="vertical"
                                                style={{ width: "100%" }}
                                            >
                                                {fields.map((field) => {
                                                    return (
                                                        <Row
                                                            gutter={24}
                                                            key={field.name}
                                                        >
                                                            <Col span={12}>
                                                                <Item
                                                                    style={{
                                                                        marginBottom: 5,
                                                                    }}
                                                                    name={[
                                                                        field.name,
                                                                        "recruitGuid",
                                                                    ]}
                                                                >
                                                                    <Select placeholder="选择招聘内容" />
                                                                </Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Button
                                                                    onClick={() => {
                                                                        remove(
                                                                            field.name
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <DeleteFilled />
                                                                    }
                                                                ></Button>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Button
                                                            onClick={() => {
                                                                add();
                                                            }}
                                                            icon={
                                                                <PlusCircleOutlined />
                                                            }
                                                        >
                                                            添加
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        );
                                    }}
                                </List>
                            </Panel>
                        </Collapse> */}
                    </Space>
                </Form>
            </div>
            <div className={styles.footer}>
                <Button
                    onClick={() => {
                        form.resetFields();
                    }}
                >
                    重置
                </Button>
                <Button onClick={onPreview}>预览</Button>
                <Button onClick={onSubmit} style={{ marginLeft: "auto" }}>
                    发布
                </Button>
            </div>
        </div>
    );
};
