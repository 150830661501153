export const startServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("service-worker.js")
            .then((reg) => {
                console.log("Service Worker 开启成功");
            })
            .catch((e) => {
                console.error("Error during service worker registration:", e);
            });
    } else {
        console.log("不支持serviceWorker");
    }
};
