import { ReactElement, useMemo, useState } from "react";
import { WorkPage } from "../Work";
import { PageTitle } from "../../components/PageTitle";
import { Radio } from "antd";
import { IdeasPage } from "../Ideas";
import { RouteName } from "../../service/route/interface";
import {
    roleWithRouteName,
    useUserMenuPermissionByRule,
} from "../../service/route/useUserMenuPermissionByRule";

export const WaitPublishPage = (): ReactElement | null => {
    const [type, setType] = useState<"work" | "idea">("work");
    const menuPermissionByRule = useUserMenuPermissionByRule();

    const tabs = useMemo(() => {
        if (!menuPermissionByRule(roleWithRouteName[RouteName.WAIT_RELEASED])) {
            return null;
        }
        if (!menuPermissionByRule(roleWithRouteName[RouteName.IDEAS])) {
            return <Radio.Button value="work">WORK待发布</Radio.Button>;
        } else if (!menuPermissionByRule(roleWithRouteName[RouteName.WORK])) {
            return <Radio.Button value="idea">IDEAS待发布</Radio.Button>;
        }
        return (
            <>
                <Radio.Button value="work">WORK待发布</Radio.Button>
                <Radio.Button value="idea">IDEAS待发布</Radio.Button>
            </>
        );
    }, [menuPermissionByRule]);

    if (!tabs) return null;

    return type === "work" ? (
        <WorkPage
            type="waitPublish"
            header={
                <PageTitle
                    title="待发布"
                    subtitle="Work待发布及IDEAS待发布内容"
                />
            }
            tableHeader={
                <Radio.Group
                    style={{ marginBottom: 20 }}
                    value={type}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(e) => setType(e.target.value)}
                >
                    {tabs}
                </Radio.Group>
            }
        />
    ) : (
        <IdeasPage
            header={
                <PageTitle
                    title="待发布"
                    subtitle="Work待发布及IDEAS待发布内容"
                />
            }
            type="draft"
            tableHeader={
                <Radio.Group
                    style={{ marginBottom: 20 }}
                    value={type}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(e) => setType(e.target.value)}
                >
                    {tabs}
                </Radio.Group>
            }
        />
    );
};
