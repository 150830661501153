import { post } from ".";
import {
    CommonStatusEnum,
    DeleteRequest,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";

export interface CategoryItem {
    guid: string;
    /** idea类别名称-中文 */
    titleCn?: string;
    /** idea类别名称-英文 */
    titleEn?: string;
    titleLike?: string;
    /** 是否插入到导航栏 */
    isInsertMenu?: CommonStatusEnum;
    /** 排序 */
    sort?: number;
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface CategoryListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: CategoryItem[];
}

export interface CategoryDetailsReply {
    data: CategoryItem;
}

/**
 * 获取服务列表
 */
export const fetchCategoryList = (
    request: PageConfig & Partial<CategoryItem>
) => {
    return post<PageConfig & Partial<CategoryItem>, CategoryListReply>(
        "admin.biz.category.info.list",
        request
    );
};

/**
 * 创建服务
 */
export const createCategory = (request: CategoryItem) => {
    return post<CategoryItem>("admin.biz.category.info.create", request);
};

/**
 * 获取服务详情
 */
export const fetchCategoryDetails = (request: { guid: string }) => {
    return post<{ guid: string }, CategoryItem>(
        "admin.biz.category.info.detail",
        request
    );
};

/**
 * 修改ideacategory
 */
export const updateCategory = (request: Partial<CategoryItem>) => {
    return post<Partial<CategoryItem>, CategoryItem>(
        "admin.biz.category.info.update",
        request
    );
};

/**
 * 删除服务
 */
export const deleteCategory = (request: DeleteRequest) => {
    return post<DeleteRequest>("admin.biz.category.info.delete", {
        isDelete: CommonStatusEnum.NO,
        ...request,
    });
};
