import { ComponentType, createElement, FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { AuthStateProvider } from './AuthStateProvider';
import { UserInfoStateProvider } from './UserInfoStateProvider';

interface ComposeProvidersProps {
    providers: ComponentType[];
}

/**
 * Compose multiple context providers together:
 * <Provider1>
 *     <Provider2>            <ComposeProviders providers={[Provider1, Provider2]}>
 *         {children}   =>        {children}
 *     </Provider2>           </ComposeProviders>
 * </Provider1>
 */
function ComposeProviders({
    providers = [],
    children,
}: PropsWithChildren<ComposeProvidersProps>): ReactElement {
    return providers.reduceRight((composed, Provider) => {
        return createElement(Provider, null, composed);
    }, children) as ReactElement;
}

interface Props {
    /**
     * custom providers
     */
    providers?: ComponentType[];
}

export const Platform: FunctionComponent<Props> = ({ children, providers = [] }) => {
    return (
        <ComposeProviders
            providers={[AuthStateProvider, UserInfoStateProvider, ...providers]}
        >
            {children}
        </ComposeProviders>
    );
};
Platform.displayName = 'Platform';