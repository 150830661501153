import { Dayjs } from "dayjs";
import { post } from ".";
import {
    CommonStatusEnum,
    DraftStatusEnum,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";
import { ClientItem } from "./client";
import { BlockGroupItem } from "./work";

export interface IdeasItem {
    guid: string;
    /** 是否外链 */
    isExternalLink?: CommonStatusEnum;
    /** 外链 */
    externalLink?: string;
    /** ideas类型 */
    categoryGuid: string;
    /** 类型实体 */
    categoryInfo: ClientItem;
    /** 中文标题 */
    titleCn?: string;
    /** 英文标题 */
    titleEn?: string;
    /** 中文简介 */
    summaryCn?: string;
    /** 英文简介 */
    summaryEn?: string;
    /** 缩略图 */
    thumbnail?: string;
    /** 顶部头图 */
    banner?: string;
    /** 详细介绍 */
    details: BlockGroupItem[];
    /** 项目时间 */
    projectTime?: number | Dayjs;
    /** 完工时间 */
    finishTime?: number | Dayjs;
    /** 是否定时发布(前端) */
    isTimingPublish?: boolean;
    /** 发布时间 */
    publishTime?: number;
    /** 发布时间(前端) */
    publishDatePart?: number | Dayjs;
    /** 发布时间(前端) */
    publishTimePart?: number | Dayjs;
    /** 在线状态 */
    status?: StatusEnum;
    /** 草稿状态 */
    draftStatus?: DraftStatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 置顶 */
    isTop?: CommonStatusEnum;
    /** 排序 */
    sort?: number;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface IdeasListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: IdeasItem[];
}

export interface IdeasDetailsReply {
    data: IdeasItem;
}

/**
 * 获取ideas列表
 */
export const fetchIdeasList = (request: Partial<PageConfig & IdeasItem>) => {
    return post<Partial<PageConfig & IdeasItem>, IdeasListReply>(
        "admin.biz.idea.info.list",
        request
    );
};

/**
 * 创建ideas
 */
export const createIdeas = (request: IdeasItem) => {
    return post<IdeasItem>("admin.biz.idea.info.create", request);
};

/**
 * 获取ideas详情
 */
export const fetchIdeasDetails = (request: { guid: string }) => {
    return post<{ guid: string }, IdeasItem>(
        "admin.biz.idea.info.detail",
        request
    );
};

/**
 * 修改ideas
 */
export const updateIdeas = (request: Partial<IdeasItem>) => {
    return post<Partial<IdeasItem>, IdeasItem>(
        "admin.biz.idea.info.update",
        request
    );
};

/**
 * 删除ideas
 */
export const deleteIdeas = (request: {
    guids: string[];
    isDelete?: CommonStatusEnum;
}) => {
    return post<{ guids: string[]; isDelete?: CommonStatusEnum }>(
        "admin.biz.idea.info.delete",
        {
            isDelete: CommonStatusEnum.NO,
            ...request,
        }
    );
};
