import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { PageTable } from "../../components/PageTable";
import { Button, Modal, Radio, Space, message } from "antd";
import { useSortable } from "@dnd-kit/sortable";
import {
    DeleteOutlined,
    DragOutlined,
    EditOutlined,
    ScissorOutlined,
    UndoOutlined,
} from "@ant-design/icons";
import {
    RecruitmentItem,
    deleteRecruitment,
    fetchRecruitmentList,
    updateRecruitment,
} from "../../service/request/apis/recruitment";
import { ColumnProps } from "antd/es/table";
import {
    CommonStatusEnum,
    RecycleStatusEnum,
    StatusEnum,
} from "../../service/request/interface";
import dayjs from "dayjs";
import { usePagination } from "../../service/pagination/usePagination";
import { CheckboxSwitch } from "../../components/CheckboxSwitch";
import { Link, useNavigate } from "react-router-dom";
import { ContactModal } from "./components/ContactModal";

const ActionItem = ({
    data,
    onDeleteBatchClick,
    onRecoverBatchHandle,
}: {
    data: RecruitmentItem;
    onDeleteBatchClick: (guids: string[], isDelete?: CommonStatusEnum) => void;
    onRecoverBatchHandle: (guid: string) => void;
}) => {
    const { listeners, setActivatorNodeRef } = useSortable({
        id: data.guid!,
    });

    const onDeleteHandle = useCallback(() => {
        Modal.confirm({
            title: "确定销毁该条记录吗?",
            onOk: () => {
                onDeleteBatchClick([data.guid], CommonStatusEnum.YES);
            },
        });
    }, [onDeleteBatchClick, data]);

    const onRecycleHandle = useCallback(() => {
        Modal.confirm({
            title: "确定删除该条记录吗?",
            onOk: () => {
                onDeleteBatchClick([data.guid]);
            },
        });
    }, [onDeleteBatchClick, data]);

    return (
        <Space size="small">
            <Button
                title="拖拽进行排序"
                ref={setActivatorNodeRef}
                {...listeners}
                size="small"
                disabled={
                    data.recycleStatus === RecycleStatusEnum.RecycleStatusYes
                }
                icon={
                    <DragOutlined
                        style={{ touchAction: "none", cursor: "move" }}
                    />
                }
            />
            {data.recycleStatus === RecycleStatusEnum.RecycleStatusYes && (
                <Button
                    onClick={() => {
                        onRecoverBatchHandle(data.guid);
                    }}
                    size="small"
                    title="恢复"
                >
                    <UndoOutlined />
                </Button>
            )}
            {data.recycleStatus === RecycleStatusEnum.RecycleStatusNo && (
                <Link to={`/recruitment/${data.guid}`}>
                    <Button icon={<EditOutlined />} title="编辑" size="small">
                        编辑
                    </Button>
                </Link>
            )}
            {data.recycleStatus === RecycleStatusEnum.RecycleStatusYes ? (
                <Button
                    onClick={onDeleteHandle}
                    size="small"
                    title="销毁"
                    danger
                >
                    <ScissorOutlined />
                </Button>
            ) : (
                <Button
                    onClick={onRecycleHandle}
                    danger
                    icon={<DeleteOutlined />}
                    title="删除"
                    size="small"
                />
            )}
        </Space>
    );
};

/** 是否显示 */
const StatusItem = ({
    data,
    refresh,
}: {
    data: RecruitmentItem;
    refresh: () => void;
}) => {
    const [isSwitch, setIsSwitch] = useState<StatusEnum | undefined>(
        data.status
    );

    const onChange = useCallback(
        (e: StatusEnum) => {
            setIsSwitch(e);
            updateRecruitment({
                ...data,
                status: e,
            }).then(() => {
                refresh();
            });
        },
        [data, refresh]
    );

    useEffect(() => {
        setIsSwitch(data.status);
    }, [data.status]);

    return (
        <CheckboxSwitch
            onChange={(e) => {
                onChange(e);
            }}
            checked={!!isSwitch}
        />
    );
};

const useColumns = (
    onDeleteBatchClick: (guids: string[], isDelete?: CommonStatusEnum) => void,
    onRecoverBatchHandle: (guid: string) => void,
    refresh: () => void
) => {
    return useMemo<ColumnProps<RecruitmentItem>[]>(() => {
        return [
            {
                title: "中文名称",
                dataIndex: "titleCn",
            },
            {
                title: "英文名称",
                dataIndex: "titleEn",
            },
            {
                title: "未发布",
                render: (_: string, record) => {
                    return <StatusItem refresh={refresh} data={record} />;
                },
            },
            {
                title: "创建时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
                },
            },
            {
                title: "修改时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
                },
            },
            {
                title: "操作",
                key: "action",
                render: (_: string, record: any) => {
                    return (
                        <ActionItem
                            onDeleteBatchClick={onDeleteBatchClick}
                            onRecoverBatchHandle={onRecoverBatchHandle}
                            data={record}
                        />
                    );
                },
            },
        ];
    }, [onRecoverBatchHandle, onDeleteBatchClick, refresh]);
};

export const RecruitmentPage = (): ReactElement => {
    const {
        isRequest,
        dataSource,
        pageIndex,
        onPageIndexChange,
        fetchAction,
        pageSize,
    } = usePagination(fetchRecruitmentList);
    const [tabType, setTabType] = useState<StatusEnum>(StatusEnum.StatusOnline);

    const onResetDataSource = useCallback(
        (request?) => {
            fetchAction(request);
        },
        [fetchAction]
    );

    // 批量回收和销毁
    const onDeleteBatchClick = useCallback(
        (guids: string[], isDelete?: CommonStatusEnum) => {
            const loading = message.loading(
                `${!!isDelete ? "销毁中..." : "删除中..."}`,
                1
            );
            deleteRecruitment({
                guids,
                isDelete,
            })
                .then(() => {
                    loading();
                    message.success(
                        `${!!isDelete ? "销毁成功" : "删除成功"}`,
                        1
                    );
                    onResetDataSource();
                })
                .catch(() => {
                    loading();
                    message.success(
                        `${!!isDelete ? "销毁失败" : "删除失败"}`,
                        1
                    );
                });
        },
        [onResetDataSource]
    );

    // 恢复
    const onRecoverBatchHandle = useCallback(
        (guid: string) => {
            updateRecruitment({
                guid,
                recycleStatus: RecycleStatusEnum.RecycleStatusNo,
            }).then(() => {
                onResetDataSource();
            });
        },
        [onResetDataSource]
    );

    const navigate = useNavigate();
    const onAddHandle = useCallback(() => {
        navigate("create");
    }, [navigate]);

    const columns = useColumns(
        onDeleteBatchClick,
        onRecoverBatchHandle,
        onResetDataSource
    );

    useEffect(() => {
        onResetDataSource({ status: tabType, recycleStatus: RecycleStatusEnum.RecycleStatusNo });
    }, [onResetDataSource, tabType]);

    const tableHeader = (
        <Radio.Group
            style={{ marginBottom: 20 }}
            value={tabType}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => setTabType(e.target.value)}
        >
            <Radio.Button value={StatusEnum.StatusOnline}>已发布</Radio.Button>
            <Radio.Button value={StatusEnum.StatusOffline}>未发布</Radio.Button>
        </Radio.Group>
    );

    const [openContact, setOpenContact] = useState(false);
    const onCloseContact = useCallback(() => {
        setOpenContact(false);
    }, []);

    return (
        <div>
            <PageTitle
                title="招聘"
                subtitle="招聘内容展示"
                onAddHandle={onAddHandle}
            />
            <PageTable
                onRequestChange={onResetDataSource}
                hideStatusSelect
                tableHeader={tableHeader}
                onDeleteAllClick={onDeleteBatchClick}
                extraItems={<Button onClick={() => {
                    setOpenContact(true);
                }} icon={<EditOutlined />}>联系方式</Button>}
                tableProps={{
                    loading: isRequest,
                    columns: columns as any,
                    dataSource: dataSource?.list,
                    pagination: {
                        current: pageIndex,
                        pageSize,
                        onChange: onPageIndexChange,
                        showQuickJumper: true,
                        total: dataSource?.total ?? 0,
                        showSizeChanger: true,
                    },
                }}
            />
            {openContact && <ContactModal onClose={onCloseContact} />}
        </div>
    );
};
