import { post } from ".";

/** role start */
export interface SystemRoleListRequest {
    pageNum: number;
    pageSize: number;
}

export interface RoleItem {
    guid: string;
    roleName: string;
    actionRules: string[];
    optRemark: string;
    createTime: number;
    updateTime: number;
}

interface OrginRoleItem extends Omit<RoleItem, "actionRules"> {
    actionRules: string;
}

interface OrginSystemRoleListReply extends Omit<SystemRoleListReply, "list"> {
    list: OrginRoleItem[];
}

export interface SystemRoleListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: RoleItem[];
}

export interface SystemRoleCreateRequest {
    roleName: string;
    actionRules: string;
    optRemark: string;
}

export interface SystemRoleUpdateRequest {
    guid: string;
    roleName: string;
    actionRules: string;
    optRemark: string;
}

export interface SystemRoleActionRequest {
    guid: string;
}

const transformFormatRoleValue = (data: OrginRoleItem) => {
    return {
        ...data,
        actionRules: data?.actionRules?.split?.("\n"),
    };
};

const transformFormatRoleList = (data: OrginSystemRoleListReply) => {
    return {
        ...data,
        list: data.list.map((item) => {
            return transformFormatRoleValue(item);
        }),
    };
};

/**
 * 获取人员角色权限列表
 */
export const fetchSystemRoleList = (request: SystemRoleListRequest) => {
    return post<SystemRoleListRequest>("admin.system.role.list", request).then(
        transformFormatRoleList
    );
};

/**
 * 创建人员角色权限
 */
export const createSystemRole = (request: SystemRoleCreateRequest) => {
    return post<SystemRoleCreateRequest>(
        "admin.system.role.create",
        request
    ).then(transformFormatRoleValue);
};

/**
 * 人员角色权限详情
 */
export const fetchSystemRoleDetails = (request: SystemRoleActionRequest) => {
    return post<SystemRoleActionRequest>(
        "admin.system.role.detail",
        request
    ).then(transformFormatRoleValue);
};

/**
 * 修改人员角色权限信息
 */
export const updateSystemRole = (request: SystemRoleUpdateRequest) => {
    return post<SystemRoleUpdateRequest>(
        "admin.system.role.update",
        request
    ).then(transformFormatRoleValue);
};

/**
 * 删除人员角色权限信息
 */
export const deleteSystemRole = (request: SystemRoleActionRequest) => {
    return post<SystemRoleActionRequest, RoleItem>(
        "admin.system.role.delete",
        request
    );
};

/** ====================== role end ======================  */
