import { post } from ".";
import {
    CommonStatusEnum,
    DeleteRequest,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";

export interface JobItem {
    guid: string;
    /** job名称-中文 */
    titleCn?: string;
    /** jop名称-英文 */
    titleEn?: string;
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface JobListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: JobItem[];
}

export interface JobDetailsReply {
    data: JobItem;
}

/**
 * 获取服务列表
 */
export const fetchJobList = (request: PageConfig & Partial<JobItem>) => {
    return post<PageConfig & Partial<JobItem>, JobListReply>(
        "admin.biz.job.info.list",
        request
    );
};

/**
 * 创建服务
 */
export const createJob = (request: JobItem) => {
    return post<JobItem>("admin.biz.job.info.create", request);
};

/**
 * 获取服务详情
 */
export const fetchJobDetails = (request: { guid: string }) => {
    return post<{ guid: string }, JobItem>(
        "admin.biz.job.info.detail",
        request
    );
};

/**
 * 修改job
 */
export const updateJob = (request: Partial<JobItem>) => {
    return post<Partial<JobItem>, JobItem>(
        "admin.biz.job.info.update",
        request
    );
};

/**
 * 删除服务
 */
export const deleteJob = (request: DeleteRequest) => {
    return post<DeleteRequest>("admin.biz.job.info.delete", {
        isDelete: CommonStatusEnum.NO,
        ...request,
    });
};
