import { ReactElement, useCallback, useEffect, useState } from "react";
import styles from "./styles.module.less";
import {
    Button,
    Col,
    Collapse,
    Form,
    FormInstance,
    Input,
    Row,
    Space,
    Spin,
    message,
} from "antd";
import { BraftEditorBase } from "../../../../components/Editor";
import { useNavigate, useParams } from "react-router-dom";
import {
    RecycleStatusEnum,
    StatusEnum,
} from "../../../../service/request/interface";
import {
    createRecruitment,
    updateRecruitment,
    RecruitmentItem,
    fetchRecruitmentDetails,
} from "../../../../service/request/apis/recruitment";

const { Panel } = Collapse;
const { Item } = Form;

const useFormatDataSource = (form: FormInstance, data?: RecruitmentItem) => {
    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [form, data]);
};

const useFinish = () => {
    const navigate = useNavigate();
    return useCallback(
        (values: RecruitmentItem) => {
            const newValues: RecruitmentItem = {
                status: StatusEnum.StatusOnline,
                recycleStatus: RecycleStatusEnum.RecycleStatusNo,
                ...values,
            };

            const isEdit = !!values.guid;

            const loading = message.loading("提交中...", 0);
            const result = isEdit
                ? updateRecruitment(newValues)
                : createRecruitment(newValues);
            return result
                .then((res) => {
                    loading();
                    message.success(`${isEdit ? "编辑" : "添加"}成功`, 1);
                    setTimeout(() => {
                        navigate("/recruitment");
                    }, 1000);
                    return res;
                })
                .catch((error) => {
                    loading();
                    message.error(`操作失败: ${error?.msg || ""}`, 1);
                });
        },
        [navigate]
    );
};

const useFetchDetails = () => {
    const params = useParams();
    const [data, setData] = useState<RecruitmentItem>();

    useEffect(() => {
        if (!params.ideasid) return;
        fetchRecruitmentDetails({ guid: params.ideasid })
            .then((res) => {
                setData(res);
            })
            .catch(() => {
                message.error("获取信息失败，请联系管理员", 1);
            });
    }, [params]);

    return data;
};

export const RecruitmentFormEditPage = (): ReactElement => {
    const [form] = Form.useForm();
    const params = useParams();
    const data = useFetchDetails();
    const onFinish = useFinish();
    useFormatDataSource(form, data);

    return (
        <div className={styles.container}>
            <h3 className={styles.header}>
                <span>招聘 {data?.guid ? '编辑项目' : '新项目'}</span>
            </h3>
            <Spin spinning={!!params.ideasid && !data}>
                <div className={styles.content}>
                    <Form requiredMark={false} onFinish={onFinish} form={form}>
                        <Item hidden name="guid">
                            <Input />
                        </Item>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Collapse
                                expandIconPosition="end"
                                defaultActiveKey={["base_info"]}
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            职位名称
                                        </span>
                                    }
                                    key="base_info"
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "中文标题不能为空",
                                                    },
                                                ]}
                                                label="中文名称"
                                                name="titleCn"
                                            >
                                                <Input placeholder="中文名称" />
                                            </Item>
                                        </Col>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "英文标题不能为空",
                                                    },
                                                ]}
                                                label="英文名称"
                                                name="titleEn"
                                            >
                                                <Input placeholder="英文名称" />
                                            </Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>

                            <Collapse
                                expandIconPosition="end"
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            职位简介
                                        </span>
                                    }
                                    key="work_info"
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "中文简介不能为空",
                                                    },
                                                ]}
                                                name="summaryCn"
                                                label="中文简介"
                                            >
                                                <BraftEditorBase />
                                            </Item>
                                        </Col>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "英文简介不能为空",
                                                    },
                                                ]}
                                                label="英文简介"
                                                name="summaryEn"
                                            >
                                                <BraftEditorBase />
                                            </Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>

                            <Collapse
                                expandIconPosition="end"
                                className={styles.collapseCustom}
                            >
                                <Panel
                                    header={
                                        <span className={styles.panelTitle}>
                                            职位要求
                                        </span>
                                    }
                                    key="content_info"
                                >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "中文要求不能为空",
                                                    },
                                                ]}
                                                name="contentCn"
                                                label="中文要求"
                                            >
                                                <BraftEditorBase />
                                            </Item>
                                        </Col>
                                        <Col span={12}>
                                            <Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "英文要求不能为空",
                                                    },
                                                ]}
                                                label="英文要求"
                                                name="contentEn"
                                            >
                                                <BraftEditorBase />
                                            </Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Space>
                    </Form>
                </div>
            </Spin>
            <div className={styles.footer}>
                <Button
                    onClick={() => {
                        form.resetFields();
                    }}
                >
                    重置
                </Button>
                <Button
                    onClick={() => {
                        form.validateFields()
                            .then(() => {
                                form.submit();
                            })
                            .catch((errorInfo) => {
                                const msg =
                                    errorInfo.errorFields?.[0]?.errors?.[0] ||
                                    "请检查是否有遗漏字段或字段格式错误";
                                if (msg) {
                                    message.error(msg, 1.5);
                                }
                            });
                    }}
                    style={{ marginLeft: "auto" }}
                >
                    发布
                </Button>
            </div>
        </div>
    );
};
