import {
    FormListFieldData,
    Collapse,
    Form,
    Input,
    Button,
    Tooltip,
    FormInstance,
} from "antd";
import { ReactElement, useCallback, useMemo } from "react";
// import { CSS } from "@dnd-kit/utilities";
import cn from "classnames";
import styles from "./styles.module.less";
import { UploadComponent } from "../Upload";
import { useImageByType } from "../BlockModal";
import {
    CloseOutlined,
    VerticalAlignBottomOutlined,
    VerticalAlignTopOutlined,
} from "@ant-design/icons";
// import iconMove from "../../assets/work/icon_move.png";
// import { useSortable } from "@dnd-kit/sortable";
import { BraftEditorBase } from "../Editor";
import { BlockGroupItem } from "../../service/request/apis/work";
import { arrayMove } from "@dnd-kit/sortable";

const { Panel } = Collapse;
const { Item } = Form;

export const useSortDetails = (form: FormInstance) => {
    return useCallback(
        (up: boolean, field: FormListFieldData) => {
            const previous = form.getFieldValue("details") as BlockGroupItem[];
            let nextTypes = previous;
            if (up) {
                // 上移
                nextTypes = arrayMove(
                    nextTypes,
                    field.name,
                    Math.max(0, field.name - 1)
                );
            } else {
                // 下移
                nextTypes = arrayMove(
                    nextTypes,
                    field.name,
                    Math.min(nextTypes.length, field.name + 1)
                );
            }
            form.setFieldValue("details", nextTypes);
        },
        [form]
    );
}

const ImageItem = ({
    field,
    type,
}: {
    field: FormListFieldData;
    type: number;
}) => {
    const directions: { direction: "vertical" | "horizontal"; name: string }[] =
        useMemo(() => {
            switch (type) {
                case 1:
                    return [
                        { direction: "vertical", name: "imageVerticalMiddle" },
                    ];
                case 2:
                    return [
                        { direction: "vertical", name: "imageVerticalLeft" },
                        { direction: "vertical", name: "imageVerticalRight" },
                    ];
                case 3:
                    return [
                        { direction: "vertical", name: "imageVerticalLeft" },
                        { direction: "vertical", name: "imageVerticalMiddle" },
                        { direction: "vertical", name: "imageVerticalRight" },
                    ];
                case 4:
                    return [
                        {
                            direction: "horizontal",
                            name: "imageHorizontalMiddle",
                        },
                    ];
                case 5:
                    return [
                        {
                            direction: "horizontal",
                            name: "imageHorizontalLeft",
                        },
                        {
                            direction: "horizontal",
                            name: "imageHorizontalRight",
                        },
                    ];

                case 14:
                case 15:
                    return [
                        { direction: "vertical", name: "imageVerticalLeft" },
                    ];

                case 16:
                case 17:
                    return [
                        { direction: "vertical", name: "imageVerticalRight" },
                    ];

                case 18:
                case 19:
                    return [
                        {
                            direction: "horizontal",
                            name: "imageHorizontalLeft",
                        },
                    ];

                case 20:
                case 21:
                    return [
                        {
                            direction: "horizontal",
                            name: "imageHorizontalRight",
                        },
                    ];
                default:
                    return [];
            }
        }, [type]);
    return (
        <div className={cn(styles.imageWrapper, styles.imageWrapperMiddle)}>
            <Item name={[field.name, "type"]} hidden>
                <Input />
            </Item>
            {directions.map((direction) => {
                return (
                    <div className={styles.imageItem}>
                        <Item name={[field.name, direction.name]}>
                            <UploadComponent
                                folder="work"
                                direction={direction.direction}
                            />
                        </Item>
                    </div>
                );
            })}
        </div>
    );
};

const ImageTextItem = ({
    field,
    type,
}: {
    field: FormListFieldData;
    type: number;
}) => {
    const name = useMemo(() => {
        switch (type) {
            case 6:
                return "imageVerticalLeft";
            case 7:
                return "imageVerticalRight";
            case 8:
                return "imageHorizontalLeft";
            case 9:
                return "imageHorizontalRight";
            default:
                return "";
        }
    }, [type]);
    return (
        <div className={styles.imageWrapper}>
            <Item name={[field.name, "type"]} hidden>
                <Input />
            </Item>
            <div className={styles.imageItemText}>
                <Item name={[field.name, name]}>
                    <UploadComponent
                        folder="work"
                        direction={type < 8 ? "vertical" : "horizontal"}
                    />
                </Item>
            </div>
            <div className={styles.itemTextarea}>
                <Item
                    label="中文简介"
                    name={[field.name, "textCN"]}
                    style={{ marginBottom: 20 }}
                >
                    <BraftEditorBase height={200} />
                </Item>
                <Item
                    label="英文简介"
                    name={[field.name, "textEN"]}
                    style={{ marginBottom: 0 }}
                >
                    <BraftEditorBase height={200} />
                </Item>
            </div>
        </div>
    );
};

const TextItem = ({ field }: { field: FormListFieldData }) => {
    return (
        <div className={styles.imageWrapper}>
            <Item name={[field.name, "type"]} hidden>
                <Input />
            </Item>
            <div className={styles.itemTextarea}>
                <Item
                    label="中文简介"
                    name={[field.name, "textCN"]}
                    style={{ marginBottom: 20 }}
                >
                    <BraftEditorBase height={200} />
                </Item>
                <Item
                    label="英文简介"
                    name={[field.name, "textEN"]}
                    style={{ marginBottom: 0 }}
                >
                    <BraftEditorBase height={200} />
                </Item>
            </div>
        </div>
    );
};

export const BlockItem = ({
    block,
    field,
    remove,
    isFirstItem,
    isLastItem,
    onSortChange,
}: {
    block: BlockGroupItem;
    field: FormListFieldData;
    remove: (name: number) => void;
    isFirstItem?: boolean;
    isLastItem?: boolean;
    onSortChange?: (up: boolean, field: FormListFieldData) => void;
}): ReactElement => {
    const image = useImageByType(block.type);

    // const {
    //     attributes,
    //     setNodeRef,
    //     transform,
    //     transition,
    //     isDragging,
    //     listeners,
    //     setActivatorNodeRef,
    // } = useSortable({
    //     id: block.type,
    // });
    // const style: React.CSSProperties = {
    //     transform: CSS.Transform.toString(
    //         transform && { ...transform, scaleY: 1 }
    //     ),
    //     transition,
    //     ...(isDragging ? { position: "relative", zIndex: 1 } : {}),
    // };

    return (
        <Collapse
            expandIconPosition="end"
            // style={{ margin: 10, ...style, backgroundColor: "#F3F3F3" }}
            style={{ margin: 10, backgroundColor: "#F3F3F3" }}
            activeKey={[`block_item_${block.type}`]}
            // ref={setNodeRef}
            // {...attributes}
        >
            <Panel
                extra={
                    <>
                        <CloseOutlined
                            onClick={() => {
                                remove(field.name);
                            }}
                        />
                        {/* <CloseOutlined
                            onClick={() => {
                                remove(field.name);
                            }}
                        />
                        <span
                            ref={setActivatorNodeRef}
                            {...listeners}
                            className={styles.iconMove}
                            style={{ backgroundImage: `url(${iconMove})` }}
                        /> */}
                        <span className={styles.moveBox}>
                            {!isLastItem && (
                                <Tooltip title="下移" placement="top">
                                    <Button
                                        onClick={() => {
                                            onSortChange?.(false, field);
                                        }}
                                        icon={<VerticalAlignBottomOutlined />}
                                    />
                                </Tooltip>
                            )}
                            {!isFirstItem && (
                                <Tooltip title="上移" placement="top">
                                    <Button
                                        onClick={() => {
                                            onSortChange?.(true, field);
                                        }}
                                        icon={<VerticalAlignTopOutlined />}
                                    />
                                </Tooltip>
                            )}
                        </span>
                    </>
                }
                showArrow={false}
                key={`block_item_${block.type}`}
                header={
                    <span className={styles.itemPanelTitle}>
                        {image?.label}
                    </span>
                }
            >
                <div className={styles.itemPanelContent}>
                    <img src={image?.img} alt="" />
                    <div className={styles.itemContent}>
                        {block.type < 6 && (
                            <ImageItem type={block.type} field={field} />
                        )}
                        {block.type > 5 && block.type < 10 && (
                            <ImageTextItem type={block.type} field={field} />
                        )}
                        {block.type > 9 && block.type < 14 && (
                            <TextItem field={field} />
                        )}
                        {block.type > 13 && (
                            <ImageItem type={block.type} field={field} />
                        )}
                    </div>
                </div>
            </Panel>
        </Collapse>
    );
};
