import { post } from ".";

/** login start */
export interface LoginRequest {
    platform: "pc" | "mobile";
    userName: string;
    userPwd: string;
}

export interface LoginReply {
    token: string;
}

export const doLogin = (request: LoginRequest) => {
    return post<LoginRequest, LoginReply>("admin.system.auth.login", {
        ...request,
        platform: "pc",
    });
};

/** ====================== login end ======================  */

/** ====================== user manage ======================  */
export interface UserListRequest {
    pageNum: number;
    pageSize: number;
}

export interface UserUpdateRequest {
    guid: string;
    userNick?: string;
    roleGuid?: string;
    optStatus?: number;
}

export interface UseCreateRequest {
    userNick: string;
    userName: string;
    roleGuid: string;
    userPwd: string;
}

export interface UserItem {
    guid: string;
    actionRules: string[];
    roleGuid: string;
    username: string;
    nickname: string;
    optStatus: number;
    optSuper: number;
    createTime: number;
    updateTime: number;
}

export interface UserListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: UserItem[];
}

const transformFormatRoleValue = (data: any) => {
    return {
        ...data,
        actionRules: data?.roleInfo?.actionRules?.split?.("\n"),
    };
};

/**
 * 获取当前登录的用户信息
 * @returns {Promise<UserItem>}
 */
export const fetchUserProfile = () => {
    return post<undefined, UserItem>("admin.system.auth.profile").then(
        transformFormatRoleValue
    );
};

/**
 * 获取管理员用户列表
 * @param request UserListRequest
 * @returns {Promise<UserListReply>}
 */
export const fetchUserList = (request: UserListRequest) => {
    return post<UserListRequest, UserListReply>(
        "admin.system.admin.list",
        request
    );
};

/**
 * 修改用户信息
 * @param request UserUpdateRequest
 * @returns {Promise<UserItem>}
 */
export const updateUserInfo = (request: UserUpdateRequest) => {
    return post<UserUpdateRequest, UserItem>(
        "admin.system.admin.update",
        request
    );
};

/**
 * 创建用户信息
 * @param request UseCreateRequest
 * @returns {Promise<void>}
 */
export const createUser = (request: UseCreateRequest) => {
    return post<UseCreateRequest, void>("admin.system.admin.create", request);
};

/**
 * 删除用户信息
 * @param request { guid: string }
 * @returns {Promise<void>}
 */
export const deleteUser = (request: { guid: string }) => {
    return post<{ guid: string }, void>("admin.system.admin.delete", request);
};

/**
 * 获取指定用户信息
 * @param request { guid: string }
 * @returns {Promise<UserItem>}
 */
export const fetchUserDetails = (request: { guid: string }) => {
    return post<{ guid: string }, UserItem>(
        "admin.system.admin.detail",
        request
    );
};

/** ====================== user manage ====================== */
