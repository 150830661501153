import { Modal } from "antd";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import image01 from "../../assets/work/normal_vertical_imagex1@2x.png";
import image02 from "../../assets/work/normal_vertical_imagex2@2x.png";
import image03 from "../../assets/work/normal_vertical_imagex3@2x.png";
import image04 from "../../assets/work/normal_horizontal_imagex1@2x.png";
import image05 from "../../assets/work/normal_horizontal_imagex2@2x.png";

import image06 from "../../assets/work/normal_vertical_image_with_text_right@2x.png";
import image07 from "../../assets/work/normal_vertical_image_with_text_left@2x.png";
import image08 from "../../assets/work/normal_horizontal_image_with_text_right@2x.png";
import image09 from "../../assets/work/normal_horizontal_image_with_text_left@2x.png";

import image10 from "../../assets/work/normal_text_full@2x.png";
import image11 from "../../assets/work/normal_text_centre@2x.png";
import image12 from "../../assets/work/normal_text_right@2x.png";
import image13 from "../../assets/work/normal_text_left@2x.png";

import image14 from "../../assets/work/flow_vertical_image_large_left@2x.png";
import image15 from "../../assets/work/flow_vertical_image_small_left@2x.png";
import image16 from "../../assets/work/flow_vertical_image_large_right@2x.png";
import image17 from "../../assets/work/flow_vertical_image_small_right@2x.png";

import image18 from "../../assets/work/flow_horizontal_image_large_left@2x.png";
import image19 from "../../assets/work/flow_horizontal_image_small_left@2x.png";
import image20 from "../../assets/work/flow_horizontal_image_large_right@2x.png";
import image21 from "../../assets/work/flow_horizontal_image_small_right@2x.png";
import styles from "./styles.module.less";
import { BlockGroupItem } from "../../service/request/apis/work";

const staticList = [
    {
        title: "单图片",
        images: [
            { img: image01, label: "竖图 1 幅", type: 1 },
            { img: image02, label: "竖图 2 幅", type: 2 },
            { img: image03, label: "竖图 3 幅", type: 3 },
            { img: image04, label: "横图 1 幅", type: 4 },
            { img: image05, label: "横图 2 幅", type: 5 },
        ],
    },

    {
        title: "图文混合",
        images: [
            { img: image06, label: "竖图左、字右", type: 6 },
            { img: image07, label: "竖图右、字左", type: 7 },
            { img: image08, label: "横图左、字右", type: 8 },
            { img: image09, label: "横图右、字左", type: 9 },
        ],
    },

    {
        title: "单文字",
        images: [
            { img: image10, label: "贯穿", type: 10 },
            { img: image11, label: "居中", type: 11 },
            { img: image12, label: "右对齐", type: 12 },
            { img: image13, label: "左对齐", type: 13 },
        ],
    },
];

const dynamicList = [
    {
        title: "竖图",
        images: [
            { img: image14, label: "大、左侧贴边", type: 14 },
            { img: image15, label: "小、左侧贴边", type: 15 },
            { img: image16, label: "大、右侧贴边", type: 16 },
            { img: image17, label: "小、右侧贴边", type: 17 },
        ],
    },

    {
        title: "横图",
        images: [
            { img: image18, label: "大、左侧贴边", type: 18 },
            { img: image19, label: "小、左侧贴边", type: 19 },
            { img: image20, label: "大、右侧贴边", type: 20 },
            { img: image21, label: "小、右侧贴边", type: 21 },
        ],
    },
];

export const useImageByType = (type: number) => {
    return useMemo(() => {
        let result: {
            img: string;
            label: string;
            type: number;
        } | undefined;
        const list = [...staticList, ...dynamicList];
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            const findItem = item.images.find((img) => img.type === type);
            if (findItem) {
                result = findItem;
                break;
            }
        };
        return result;
    }, [type]);
};

const Item = ({
    data,
    type,
    onTypeChange,
    onDoubleClick,
}: {
    data: {
        images: { img: string; label: string; type: number }[];
        title: string;
    };
    type: number;
    onTypeChange: (type: number) => void;
    onDoubleClick: (type: number) => void;
}) => {
    const { title, images } = data;

    return (
        <div className={styles.itemGroup}>
            <h6>{title}</h6>
            <div className={styles.itemList}>
                {images.map((item, idx) => {
                    return (
                        <div
                            onClick={() => {
                                onTypeChange(item.type);
                            }}
                            onDoubleClick={() => {
                                onDoubleClick(item.type);
                            }}
                            key={idx}
                            className={cn(styles.item, {
                                [styles.itemActive]: type === item.type,
                            })}
                        >
                            <img src={item.img} alt="" />
                            <span>{item.label}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export const BlockModal = ({
    visible,
    onTypeChange,
    onVisibleChange,
}: {
    visible?: boolean;
    onTypeChange?: (data: BlockGroupItem) => void;
    onVisibleChange?: (visible: boolean) => void;
}): ReactElement => {
    const [open, setOpen] = useState<boolean | undefined>(false);
    const [type, setType] = useState<number>(1);

    const onOk = useCallback(() => {
        onVisibleChange?.(false);
        onTypeChange?.({ type });
    }, [onTypeChange, type, onVisibleChange]);

    const onDoubleClick = useCallback((type: number) => {
        onVisibleChange?.(false);
        onTypeChange?.({ type });
        setType(type);
    }, [onTypeChange, onVisibleChange]);

    const onCancel = useCallback(() => {
        onVisibleChange?.(false);
    }, [onVisibleChange]);

    useEffect(() => {
        setOpen(visible);
    }, [visible]);

    return (
        <Modal
            onCancel={onCancel}
            onOk={onOk}
            width="60%"
            style={{ maxWidth: 868 }}
            title="图文模块选择(可双击选择)"
            open={open}
            className="modal-header ant-modal-custom"
        >
            <div className={styles.container}>
                <h3>静态模块</h3>
                {staticList.map((item, idx) => {
                    return (
                        <Item
                            type={type}
                            onTypeChange={setType}
                            key={idx}
                            data={item}
                            onDoubleClick={onDoubleClick}
                        />
                    );
                })}
                <h3 style={{ marginTop: 30 }}>动态模块（自带视差滚动效果效果更灵动）</h3>
                {dynamicList.map((item, idx) => {
                    return (
                        <Item
                            type={type}
                            onTypeChange={setType}
                            key={idx}
                            data={item}
                            onDoubleClick={onDoubleClick}
                        />
                    );
                })}
            </div>
        </Modal>
    );
};
