import { ReactElement, useMemo, useState } from "react";
import zhCN from "antd/es/locale/zh_CN";
import "dayjs/locale/zh-cn";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import "./index.css";
import {
    Navigate,
    Route,
    RouterProvider,
    createHashRouter,
    createRoutesFromElements,
} from "react-router-dom";
import { Platform } from "./service/platform";
import { PrivateRoute } from "./components/PrivateRoute";
import { MainLayout } from "./pages/Router/components/Layout";
import { LoginPage } from "./pages/Login";
import { RouteItem, RouteName } from "./service/route/interface";
import { routes } from "./service/route";
import { useUserMenuPermissionByRule } from "./service/route/useUserMenuPermissionByRule";
import { DefaultPage } from "./pages/Default";

dayjs.locale("cn");
const AppComponent = (): ReactElement => {
    const menuPermissionByRule = useUserMenuPermissionByRule();
    const [indexPath, setIndexPath] = useState<RouteName>();
    const rendeRoute = useMemo(() => {
        setIndexPath(undefined);
        const loop = (nodes: RouteItem[]) => {
            const newNodes = nodes
                .map((node, idx) => {
                    if (!menuPermissionByRule(node.rule) || node.path === RouteName.DEFAULT) {
                        return null;
                    }
                    const Element = node.element;
                    setIndexPath((prev) => prev ? prev : node.path);
                    return (
                        <Route
                            key={node.path}
                            path={node.path}
                            element={
                                <PrivateRoute>
                                    {Element ? <Element /> : null}
                                </PrivateRoute>
                            }
                        >
                            {loop(node.children)}
                        </Route>
                    );
                })
                .filter(Boolean);
            return newNodes;
        };
        return loop(routes);
    }, [menuPermissionByRule]);    

    const Router = useMemo(() => {
        return createHashRouter(
            createRoutesFromElements(
                <Route>
                    {indexPath && <Route index element={<Navigate to={indexPath} />}></Route>}
                    <Route
                        path={RouteName.DEFAULT}
                        element={
                            <PrivateRoute>
                                <MainLayout />
                            </PrivateRoute>
                        }
                    >
                        {rendeRoute}
                    </Route>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="*" element={<DefaultPage />} />
                </Route>
            )
        )
    }, [rendeRoute, indexPath]);

    return (
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 5,
                },
                components: {
                    Menu: {
                        colorPrimary: "#000",
                        radiusItem: 0,
                        colorItemBg: "#000",
                        colorItemBgHover: "rgba(255,255,255,0.9)",
                        colorItemBgSelected: "rgba(255,255,255,0.9)",
                        colorItemTextHover: "#000",
                        colorItemTextSelected: "#000",
                        itemMarginInline: 0,
                    },
                    Select: {
                        colorPrimaryHover: "#777",
                        controlItemBgActive: "#999",
                        colorPrimary: "#fff",
                    },
                    Input: {
                        colorPrimaryHover: "#777",
                        controlItemBgActive: "#777",
                    },
                    DatePicker: {
                        colorPrimaryHover: "#777",
                        controlItemBgActive: "#777",
                    },
                    Button: {
                        fontSize: 12,
                        colorPrimary: "#000",
                        colorPrimaryHover: "#000",
                        colorPrimaryActive: "#000",
                        fontWeightStrong: 700,
                    },
                    Checkbox: {
                        colorPrimary: "#000",
                        colorPrimaryHover: "#000",
                        colorPrimaryActive: "#000",
                    },
                    Pagination: {
                        colorPrimary: "#000",
                        colorPrimaryHover: "#000",
                        colorPrimaryActive: "#000",
                    },
                    Switch: {
                        colorPrimary: "#000",
                        colorPrimaryHover: "#000",
                        colorPrimaryActive: "#000",
                    },
                    Radio: {
                        colorPrimary: "#000",
                        colorPrimaryHover: "#000",
                        colorPrimaryActive: "#000",
                    },
                    Spin: {
                        colorPrimary: "#000",
                        colorPrimaryHover: "#000",
                        colorPrimaryActive: "#000",
                    },
                },
            }}
            locale={zhCN}
        >
            <RouterProvider router={Router} />
        </ConfigProvider>
    );
};

export const App = () => {
    return (
        <Platform>
            <AppComponent />
        </Platform>
    );
};
