const APP_NAME = "sig_studio_admin";

export const config = {
    // Base Info
    /**
     * 应用名称
     */
    APP_NAME,

    // API server
    /**
     * Api请求地址
     */
    API_SERVER: process.env.API_SERVER ?? "",

    // Access server
    /**
     * 资源请求地址
     */
    API_ACCESS_SERVER: process.env.API_ACCESS_SERVER ?? "",

    /**
     * 前端页面地址
     */
    WEB_SITE: process.env.WEB_SITE ?? "",

    /**
     * 登录storage key
     */
    LOGIN_INFO_CACHE_STORAGE_KEY: `${APP_NAME}_LOGIN_INFO`,
};
