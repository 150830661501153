import { Alert, Button, Form, Input } from "antd";
import { ReactElement, useCallback, useState } from "react";
import styles from "./styles.module.less";
import { doLogin } from "../../service/request/apis/user";
import { useAuthState } from "../../service/platform/useAuthState";
import { useNavigate } from "react-router-dom";

export const LoginPage = (): ReactElement => {
    const [form] = Form.useForm();
    const auth = useAuthState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = useCallback(() => {
        form.submit();
        form.validateFields().then((values) => {
            setLoading(true);
            setErrorMessage("");
            doLogin(values)
                .then((res) => {
                    auth.signin(res).then(() => {
                        navigate('/');
                    });
                })
                .catch(() => {
                    setErrorMessage("登录失败，用户名或密码错误");
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, [form, auth, navigate]);

    return (
        <div className={styles.container}>
            <div className={styles.loginForm}>
                <div className={styles.loginBody}>
                    <h2 className={styles.logo}>SIG&nbsp;&nbsp;STUDIO</h2>
                    <h3 className={styles.title}>登录</h3>
                    <Form
                        onFinish={onSubmit}
                        size="large"
                        form={form}
                        layout="vertical"
                    >
                        {!!errorMessage && (
                            <Form.Item>
                                <Alert
                                    message={errorMessage}
                                    type="error"
                                    showIcon
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            name="username"
                            label="用户名"
                            rules={[
                                { message: "请输入用户名", required: true },
                            ]}
                        >
                            <Input placeholder="请输入用户名" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="密码"
                            rules={[{ message: "请输入密码", required: true }]}
                        >
                            <Input placeholder="请输入密码" type="password" />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                loading={loading}
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};
