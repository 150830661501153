import axios from "axios";
import { Code } from "../code";
import { config } from "../config";
import { dispatchEvent } from "../event";

interface Config {
    token: string;
}

const defaultConfig = {
    token: "",
};

axios.interceptors.response.use(
    (response) => {
        const { data } = response;
        if (data?.code && data?.code !== 0) {
            if (data.code === Code.TOKEN_INVALID) {
                dispatchEvent("tokenInvalid");
            }
            return Promise.reject(response.data || response);
        }
        return response?.data?.data || response.data;
    },
    (error) => {
        if (error?.response?.status === Code.TOKEN_INVALID) {
            dispatchEvent("tokenInvalid");
        }
        return Promise.reject(error.data || error);
    }
);

axios.defaults.baseURL = config.API_SERVER;

export const setRequestConfig = (conf: Config) => {
    Object.assign(defaultConfig, conf);
    if (defaultConfig.token) {
        axios.defaults.headers.common["Authorization"] = `${conf.token}`;
    }
};
