import { ReactElement, useState } from "react";
import { SystemRolePage } from "../Role";
import { Radio } from "antd";
import { UsersPage } from "../Users";

export const PermissionPage = (): ReactElement => {
    const [type, setType] = useState<"users" | "role">("users");

    return type === "users" ? (
        <UsersPage
            tableHeader={
                <Radio.Group
                    style={{ marginBottom: 20 }}
                    value={type}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(e) => setType(e.target.value)}
                >
                    <Radio.Button value="users">用户管理</Radio.Button>
                    <Radio.Button value="role">角色管理</Radio.Button>
                </Radio.Group>
            }
        />
    ) : (
        <SystemRolePage
            tableHeader={
                <Radio.Group
                    style={{ marginBottom: 20 }}
                    value={type}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(e) => setType(e.target.value)}
                >
                    <Radio.Button value="users">用户管理</Radio.Button>
                    <Radio.Button value="role">角色管理</Radio.Button>
                </Radio.Group>
            }
        />
    );
};
