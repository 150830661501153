import { Dayjs } from "dayjs";
import { post } from ".";
import {
    CommonStatusEnum,
    DraftStatusEnum,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";
import { ClientItem } from "./client";

export interface BlockGroupItem {
    type: number;
    /** 左竖图 */
    imageVerticalLeft?: string;
    /** 右竖图 */
    imageVerticalRight?: string;
    /** 中间竖图 */
    imageVerticalMiddle?: string;
    /** 左横图 */
    imageHorizontalLeft?: string;
    /** 右横图 */
    imageHorizontalRight?: string;
    /** 中间横图 */
    imageHorizontalMiddle?: string;
    /** 左文字-中文 */
    textCN?: string;
    /** 左文字-英文 */
    textEN?: string;
}

export interface WorkItem {
    guid: string;
    /** 客户 */
    clientGuid: string;
    /** 客户实体 */
    clientInfo: Partial<ClientItem>;
    /** 服务 */
    serviceGuids: string[];
    /** 中文标题 */
    titleCn?: string;
    /** 英文标题 */
    titleEn?: string;
    /** 中文简介 */
    summaryCn?: string;
    /** 英文简介 */
    summaryEn?: string;
    /** 缩略图 */
    thumbnail?: string;
    /** 顶部头图 */
    banner?: string;
    /** 详细介绍 */
    details: BlockGroupItem[];
    /** 参与人员 */
    partner?: { jobGuid: string; teamGuid: string }[];
    /** 是否定时发布(前端) */
    isTimingPublish?: boolean;
    /** 项目地点中文 */
    addressCn?: string;
    /** 项目地点英文 */
    addressEn?: string;
    /** 面积 */
    area: number;
    /** 完工时间 */
    finishTime?: number | Dayjs;
    /** 发布时间 */
    publishTime?: number;
    /** 发布时间(前端) */
    publishDatePart?: number | Dayjs;
    /** 发布时间(前端) */
    publishTimePart?: number | Dayjs;
    /** 在线状态 */
    status?: StatusEnum;
    /** 草稿状态 */
    draftStatus?: DraftStatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 是否未发布 */
    isUnPublish?: CommonStatusEnum;
    /** 置顶 */
    isTop?: CommonStatusEnum;
    /** 排序 */
    sort?: number;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface WorkListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: WorkItem[];
}

export interface WorkDetailsReply {
    data: WorkItem;
}

/**
 * 获取work列表
 */
export const fetchWorkList = (
    request: Partial<PageConfig> & Partial<WorkItem>
) => {
    return post<Partial<PageConfig> & Partial<WorkItem>, WorkListReply>(
        "admin.biz.work.info.list",
        request
    );
};

/**
 * 创建work
 */
export const createWork = (request: WorkItem) => {
    return post<WorkItem>("admin.biz.work.info.create", request);
};

/**
 * 获取work详情
 */
export const fetchWorkDetails = (request: { guid: string }) => {
    return post<{ guid: string }, WorkItem>(
        "admin.biz.work.info.detail",
        request
    );
};

/**
 * 修改work
 */
export const updateWork = (request: Partial<WorkItem>) => {
    return post<Partial<WorkItem>, WorkItem>(
        "admin.biz.work.info.update",
        request
    );
};

/**
 * 删除work
 */
export const deleteWork = (request: {
    guids: string[];
    isDelete?: CommonStatusEnum;
}) => {
    return post<{ guids: string[]; isDelete?: CommonStatusEnum }>(
        "admin.biz.work.info.delete",
        {
            isDelete: CommonStatusEnum.NO,
            ...request,
        }
    );
};
