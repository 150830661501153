import { Form, Input, Modal, message } from "antd";
import { ReactElement, useCallback } from "react";
import {
    IndustryItem,
    createIndustry,
    updateIndustry,
} from "../../../../service/request/apis/industry";
import { CheckboxSwitch } from "../../../../components/CheckboxSwitch";
import { UploadComponent } from "../../../../components/Upload";

const { Item } = Form;
export const FormModal = ({
    data,
    onClose,
    onRefresh,
}: {
    data?: IndustryItem;
    onClose?: () => void;
    onRefresh?: () => void;
}): ReactElement => {
    const [form] = Form.useForm();
    const onCancel = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const onFinish = useCallback(
        (values) => {
            const loading = message.loading("提交中...", 0);
            const isEdit = !!values.guid;
            console.log('values: ', values);
            (isEdit ? updateIndustry(values) : createIndustry(values))
                .then(() => {
                    loading();
                    message.success(`${isEdit ? "编辑" : "添加"}成功`, 1);
                    onRefresh?.();
                    onClose?.();
                })
                .catch((error) => {
                    loading();
                    message.error(`${isEdit ? "编辑" : "添加"}失败: ${error?.msg || ''}`, 1);
                });
        },
        [onClose, onRefresh]
    );

    return (
        <Modal
            onCancel={onCancel}
            onOk={() => {
                form.validateFields().then(() => {
                    form.submit();
                });
            }}
            width="60%"
            style={{ maxWidth: 868 }}
            title={data?.guid ? '编辑行业类别' : '添加行业类别'}
            open={true}
            className="modal-header ant-modal-custom"
        >
            <Form
                initialValues={data}
                onFinish={onFinish}
                form={form}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 8 }}
            >
                <Item name="guid" hidden>
                    <Input />
                </Item>
                <Item
                    name="titleCn"
                    label="中文名称"
                    rules={[{ required: true, message: "请输入中文名称" }]}
                >
                    <Input placeholder="中文名称" />
                </Item>
                <Item
                    name="titleEn"
                    label="英文名称"
                    rules={[{ required: true, message: "请输入英文名称" }]}
                >
                    <Input placeholder="英文名称" />
                </Item>
                <Item name="sort" label="排序">
                    <Input placeholder="排序" type="number" />
                </Item>
                <Item name="icon" label="图标">
                    <UploadComponent direction="minHorizontal" />
                </Item>
                <Item name="status" label="隐藏">
                    <CheckboxSwitch />
                </Item>
            </Form>
        </Modal>
    );
};
