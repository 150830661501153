import { Form, FormInstance, Input, Modal, Select, Spin, message } from "antd";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { TeamItem, fetchTeamList } from "../../../../service/request/apis/team";
import {
    RecycleStatusEnum,
    StatusEnum,
} from "../../../../service/request/interface";
import {
    AboutItem,
    fetchAboutDetails,
    updateAbout,
} from "../../../../service/request/apis/about";

// 人员选项
const useTeamOptions = () => {
    const [options, setData] = useState<{ label: string; value: string }[]>();
    const [teamList, setTeamList] = useState<TeamItem[]>();
    useEffect(() => {
        fetchTeamList({
            pageNum: 1,
            pageSize: 200,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setTeamList(res.list);
            setData(
                res.list.map((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
        });
    }, []);
    return { options, teamList };
};

const useFetchDetails = () => {
    const [details, setData] = useState<AboutItem>();
    const fetchDetails = useCallback(() => {
        fetchAboutDetails()
            .then((res) => {
                setData(res);
            })
            .catch(() => {
                message.error("获取信息失败，请联系管理员", 1);
            });
    }, []);

    useEffect(() => {
        fetchDetails();
    }, [fetchDetails]);

    return { details, fetchDetails };
};

const useFinish = (
    fetchDetails: () => void,
    onCloseContact: () => void,
    details?: AboutItem,
    teamList?: TeamItem[]
) => {
    return useCallback(
        (values) => {
            const loading = message.loading("保存中...", 0);
            const team = teamList?.find(
                (team) => team.guid === values.contact.contactPerson.guid
            );
            const newValues = {
                ...details,
                contact: {
                    ...values.contact,
                    contactPerson: team,
                },
            };

            return updateAbout(newValues)
                .then(() => {
                    loading();
                    fetchDetails();
                    onCloseContact();
                    message.success("保存成功", 1);
                })
                .catch((error) => {
                    loading();
                    message.error(`保存失败: ${error?.msg || ""}`, 1);
                });
        },
        [fetchDetails, onCloseContact, details, teamList]
    );
};

const useFormatDataSource = (form: FormInstance, data?: AboutItem) => {
    useEffect(() => {
        if (data) {
            form.setFieldsValue(data);
        }
    }, [form, data]);
};

export const ContactModal = ({
    onClose,
}: {
    onClose: () => void;
}): ReactElement => {
    const [form] = Form.useForm();
    const { teamList, options } = useTeamOptions();
    const { details, fetchDetails } = useFetchDetails();
    const onFinish = useFinish(fetchDetails, onClose, details, teamList);
    useFormatDataSource(form, details);

    return (
        <Modal
            onOk={() => {
                form.validateFields().then(() => {
                    form.submit();
                });
            }}
            style={{ maxWidth: 868 }}
            open
            onCancel={onClose}
            title="联系方式"
            className="modal-header ant-modal-custom"
            width="60%"
        >
            <Spin spinning={!details}>
                <Form
                    initialValues={details}
                    form={form}
                    onFinish={onFinish}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 8 }}
                >
                    <Form.Item
                        name={["contact", "email"]}
                        rules={[{ message: "请输入电子邮件", required: true }]}
                        label="电子邮件"
                    >
                        <Input placeholder="电子邮件" />
                    </Form.Item>
                    <Form.Item
                        name={["contact", "phone1"]}
                        rules={[{ message: "请输入电话", required: true }]}
                        label="电话 1"
                    >
                        <Input placeholder="电话 1" />
                    </Form.Item>
                    <Form.Item name={["contact", "phone2"]} label="电话 2">
                        <Input placeholder="电话 2" />
                    </Form.Item>
                    <Form.Item
                        name={["contact", "contactPerson", "guid"]}
                        rules={[{ message: "请选择招聘人员", required: true }]}
                        label="招聘人"
                    >
                        <Select options={options} placeholder="招聘人" />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};
