import { useCallback } from "react";
import { useUserInfo } from "../platform/useUserInfo";
import { RouteName } from "./interface";

export const roleWithRouteName = {
    [RouteName.DASHBOARD]: "*",
    [RouteName.WORK]: "admin.biz.work.*",
    [RouteName.CLIENT]: "admin.biz.client.*",
    [RouteName.IDEAS]: "admin.biz.idea.*",
    [RouteName.IDEAS_CREATE]: "admin.biz.category.*",
    [RouteName.INDUSTRY_TYPE]: "admin.biz.industry.*",
    [RouteName.SERVICE_TYPE]: "admin.biz.service.*",
    [RouteName.FUNCTIONS]: "admin.biz.job.*",
    [RouteName.USERS]: "admin.biz.team.*",
    [RouteName.ABOUT]: "*", // admin.system.setting.
    [RouteName.WAIT_RELEASED]: ["admin.biz.work.*", "admin.biz.idea.*"],
    [RouteName.DRAFT]: ["admin.biz.work.*", "admin.biz.idea.*"],
    [RouteName.RECRUITMENT]: "admin.biz.recruitment.*",
};

export const useUserMenuPermissionByRule = () => {
    const userInfo = useUserInfo();

    return useCallback(
        (rule?: string | string[]) => {
            if (!userInfo?.guid) {
                return false;
            }
            if (userInfo.optSuper === 1) {
                return true;
            }

            if (!rule) return false;

            if (rule === "*") return true;

            if (Array.isArray(rule)) {
                return rule.some((item) => userInfo.actionRules.includes(item));
            }

            return userInfo.actionRules.includes(rule);
        },
        [userInfo]
    );
};
