import { useContext } from "react";
import { UserInfoStateContext } from "./UserInfoStateProvider";

/**
 * 获取用户信息
 * @returns {UserItem}
 */
export const useUserInfo = () => {
    return useContext(UserInfoStateContext);
};
