import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { PageTable } from "../../components/PageTable";
import { Button, Modal, Space, message } from "antd";
import { useSortable } from "@dnd-kit/sortable";
import {
    DeleteOutlined,
    DragOutlined,
    EditOutlined,
    ScissorOutlined,
    UndoOutlined,
} from "@ant-design/icons";
import {
    TeamItem,
    deleteTeam,
    fetchTeamList,
    updateTeam,
} from "../../service/request/apis/team";
import { ColumnProps } from "antd/es/table";
import {
    CommonStatusEnum,
    RecycleStatusEnum,
    StatusEnum,
} from "../../service/request/interface";
import dayjs from "dayjs";
import { usePagination } from "../../service/pagination/usePagination";
import { FormModal } from "./components/FormModal";
import { CheckboxSwitch } from "../../components/CheckboxSwitch";

const ActionItem = ({
    data,
    onDeleteBatchClick,
    onRecoverBatchHandle,
    onEditClick,
}: {
    data: TeamItem;
    onEditClick: (data: TeamItem) => void;
    onDeleteBatchClick: (guids: string[], isDelete?: CommonStatusEnum) => void;
    onRecoverBatchHandle: (guid: string) => void;
}) => {
    const { listeners, setActivatorNodeRef } = useSortable({
        id: data.guid!,
    });

    const onDeleteHandle = useCallback(() => {
        Modal.confirm({
            title: "确定销毁该条记录吗?",
            onOk: () => {
                onDeleteBatchClick([data.guid], CommonStatusEnum.YES);
            },
        });
    }, [onDeleteBatchClick, data]);

    const onRecycleHandle = useCallback(() => {
        Modal.confirm({
            title: "确定删除该条记录吗?",
            onOk: () => {
                onDeleteBatchClick([data.guid]);
            },
        });
    }, [onDeleteBatchClick, data]);

    return (
        <Space size="small">
            <Button
                title="拖拽进行排序"
                ref={setActivatorNodeRef}
                {...listeners}
                size="small"
                disabled={
                    data.recycleStatus === RecycleStatusEnum.RecycleStatusYes
                }
                icon={
                    <DragOutlined
                        style={{ touchAction: "none", cursor: "move" }}
                    />
                }
            />
            {data.recycleStatus === RecycleStatusEnum.RecycleStatusYes && (
                <Button
                    onClick={() => {
                        onRecoverBatchHandle(data.guid);
                    }}
                    size="small"
                    title="恢复"
                >
                    <UndoOutlined />
                </Button>
            )}
            <Button
                disabled={
                    data.recycleStatus === RecycleStatusEnum.RecycleStatusYes
                }
                onClick={() => {
                    onEditClick(data);
                }}
                icon={<EditOutlined />}
                title="编辑"
                size="small"
            >
                编辑
            </Button>
            {data.recycleStatus === RecycleStatusEnum.RecycleStatusYes ? (
                <Button
                    onClick={onDeleteHandle}
                    size="small"
                    title="销毁"
                    danger
                >
                    <ScissorOutlined />
                </Button>
            ) : (
                <Button
                    onClick={onRecycleHandle}
                    danger
                    icon={<DeleteOutlined />}
                    title="删除"
                    size="small"
                />
            )}
        </Space>
    );
};

/** 是否显示 */
const StatusItem = ({
    data,
    refresh,
}: {
    data: TeamItem;
    refresh: () => void;
}) => {
    const [isSwitch, setIsSwitch] = useState<StatusEnum | undefined>(
        data.status
    );

    const onChange = useCallback(
        (e: StatusEnum) => {
            setIsSwitch(e);
            updateTeam({
                ...data,
                status: e,
            }).then(refresh);
        },
        [data, refresh]
    );

    useEffect(() => {
        setIsSwitch(data.status);
    }, [data.status]);

    return (
        <CheckboxSwitch
            onChange={(e) => {
                onChange(e);
            }}
            checked={!!isSwitch}
        />
    );
};

const useColumns = (
    onDeleteBatchClick: (guids: string[], isDelete?: CommonStatusEnum) => void,
    onRecoverBatchHandle: (guid: string) => void,
    onEditClick: (data: TeamItem) => void,
    refresh: () => void
) => {
    return useMemo<ColumnProps<TeamItem>[]>(() => {
        return [
            {
                title: "中文名称",
                dataIndex: "titleCn",
            },
            {
                title: "英文名称",
                dataIndex: "titleEn",
            },
            {
                title: "隐藏",
                render: (_: string, record) => {
                    return <StatusItem refresh={refresh} data={record} />;
                },
            },
            {
                title: "创建时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
                },
            },
            {
                title: "修改时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
                },
            },
            {
                title: "操作",
                key: "action",
                render: (_: string, record: any) => {
                    return (
                        <ActionItem
                            onEditClick={onEditClick}
                            onDeleteBatchClick={onDeleteBatchClick}
                            onRecoverBatchHandle={onRecoverBatchHandle}
                            data={record}
                        />
                    );
                },
            },
        ];
    }, [onRecoverBatchHandle, onDeleteBatchClick, refresh, onEditClick]);
};

export const TeamPage = (): ReactElement => {
    const [showAddModal, setShowAddModal] = useState<TeamItem | undefined>();
    const onAddHandle = useCallback(() => {
        setShowAddModal({} as TeamItem);
    }, []);

    const { isRequest, dataSource, pageIndex, onPageIndexChange, fetchAction, pageSize } =
        usePagination(fetchTeamList);

    const onResetDataSource = useCallback(
        (request?) => {
            fetchAction(request);
        },
        [fetchAction]
    );

    // 批量回收和销毁
    const onDeleteBatchClick = useCallback(
        (guids: string[], isDelete?: CommonStatusEnum) => {
            const loading = message.loading(
                `${!!isDelete ? "销毁中..." : "删除中..."}`,
                1
            );
            deleteTeam({
                guids,
                isDelete,
            })
                .then(() => {
                    loading();
                    message.success(
                        `${!!isDelete ? "销毁成功" : "删除成功"}`,
                        1
                    );
                    onResetDataSource();
                })
                .catch(() => {
                    loading();
                    message.success(
                        `${!!isDelete ? "销毁失败" : "删除失败"}`,
                        1
                    );
                });
        },
        [onResetDataSource]
    );

    // 恢复
    const onRecoverBatchHandle = useCallback(
        (guid: string) => {
            updateTeam({
                guid,
                recycleStatus: RecycleStatusEnum.RecycleStatusNo,
            })
                .then(() => {
                    onResetDataSource();
                });
        },
        [onResetDataSource]
    );

    const columns = useColumns(
        onDeleteBatchClick,
        onRecoverBatchHandle,
        setShowAddModal,
        onResetDataSource
    );

    useEffect(() => {
        onResetDataSource({ recycleStatus: RecycleStatusEnum.RecycleStatusNo });
    }, [onResetDataSource]);

    return (
        <div>
            <PageTitle
                title="人员"
                subtitle="人员内容展示"
                onAddHandle={onAddHandle}
            />
            <PageTable
                onRequestChange={onResetDataSource}
                onDeleteAllClick={onDeleteBatchClick}
                tableProps={{
                    loading: isRequest,
                    columns: columns as any,
                    dataSource: dataSource?.list,
                    pagination: {
                        current: pageIndex,
                        pageSize,
                        onChange: onPageIndexChange,
                        showQuickJumper: true,
                        total: dataSource?.total ?? 0,
                        showSizeChanger: true,
                    },
                }}
            />
            {!!showAddModal && (
                <FormModal
                    data={showAddModal}
                    onClose={() => {
                        setShowAddModal(undefined);
                    }}
                    onRefresh={onResetDataSource}
                />
            )}
        </div>
    );
};
