import {
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { PageTitle } from "../../components/PageTitle";
import { PageTable } from "../../components/PageTable";
import { Button, Modal, Space, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
    UserItem,
    deleteUser,
    fetchUserList,
} from "../../service/request/apis/user";
import { ColumnProps } from "antd/es/table";
import { RecycleStatusEnum } from "../../service/request/interface";
import dayjs from "dayjs";
import { usePagination } from "../../service/pagination/usePagination";
import { FormModal } from "./components/FormModal";

const ActionItem = ({
    data,
    onEditClick,
    onDeleteHandle,
}: {
    data: UserItem;
    onEditClick: (data: UserItem) => void;
    onDeleteHandle: (data: UserItem) => void;
}) => {
    const onDeleteClick = useCallback(() => {
        Modal.confirm({
            title: "确定删除该条记录吗?",
            onOk: () => {
                onDeleteHandle?.(data);
            },
        });
    }, [onDeleteHandle, data]);

    return (
        <Space size="small">
            <Button
                onClick={() => {
                    onEditClick(data);
                }}
                icon={<EditOutlined />}
                title="编辑"
                size="small"
            >
                编辑
            </Button>
            <Button
                onClick={onDeleteClick}
                danger
                icon={<DeleteOutlined />}
                title="删除"
                size="small"
            />
        </Space>
    );
};

const useColumns = (
    onEditClick: (data: UserItem) => void,
    onDeleteHandle: (data: UserItem) => void
) => {
    return useMemo<ColumnProps<UserItem>[]>(() => {
        return [
            {
                title: "角色名称",
                render(_, record) {
                    return record.nickname;
                },
            },
            {
                title: "创建时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
                },
            },
            {
                title: "修改时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime ?? 0).format("YYYY-MM-DD");
                },
            },
            {
                title: "操作",
                key: "action",
                render: (_: string, record: any) => {
                    return (
                        <ActionItem
                            onEditClick={onEditClick}
                            onDeleteHandle={onDeleteHandle}
                            data={record}
                        />
                    );
                },
            },
        ];
    }, [onDeleteHandle, onEditClick]);
};

export const UsersPage = ({
    tableHeader,
}: {
    tableHeader?: ReactNode;
}): ReactElement => {
    const [showAddModal, setShowAddModal] = useState<UserItem | undefined>();
    const onAddHandle = useCallback(() => {
        setShowAddModal({} as UserItem);
    }, []);

    const {
        isRequest,
        dataSource,
        pageIndex,
        onPageIndexChange,
        fetchAction,
        pageSize,
    } = usePagination(fetchUserList);

    const onResetDataSource = useCallback(
        (request?) => {
            fetchAction(request);
        },
        [fetchAction]
    );

    // 删除
    const onDeleteHandle = useCallback(
        (item: UserItem) => {
            const loading = message.loading("删除中...", 1);
            deleteUser({
                guid: item.guid,
            })
                .then(() => {
                    loading();
                    message.success("删除成功", 1);
                    onResetDataSource();
                })
                .catch((error) => {
                    loading();
                    message.success(`删除失败: ${error?.msg || ""}`, 1);
                });
        },
        [onResetDataSource]
    );

    const columns = useColumns(setShowAddModal, onDeleteHandle);

    useEffect(() => {
        onResetDataSource({ recycleStatus: RecycleStatusEnum.RecycleStatusNo });
    }, [onResetDataSource]);

    return (
        <div>
            <PageTitle
                title="用户"
                subtitle="用户管理展示"
                onAddHandle={onAddHandle}
            />
            <PageTable
                onRequestChange={onResetDataSource}
                tableHeader={tableHeader}
                hideStatusSelect
                searchName="usernameLike"
                tableProps={{
                    loading: isRequest,
                    columns: columns as any,
                    dataSource: dataSource?.list,
                    pagination: {
                        current: pageIndex,
                        pageSize,
                        onChange: onPageIndexChange,
                        showQuickJumper: true,
                        total: dataSource?.total ?? 0,
                        showSizeChanger: true,
                    },
                }}
            />
            {!!showAddModal && (
                <FormModal
                    data={showAddModal}
                    onClose={() => {
                        setShowAddModal(undefined);
                    }}
                    onRefresh={onResetDataSource}
                />
            )}
        </div>
    );
};
