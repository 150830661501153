import {
    Children,
    ComponentProps,
    HTMLAttributes,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styles from "./styles.module.less";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
} from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import {
    DeleteOutlined,
    SearchOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { CommonStatusEnum } from "../../service/request/interface";

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
}

const RowItem = ({ children, ...props }: RowProps, ...rest: any) => {
    const { attributes, setNodeRef, transform, transition, isDragging } =
        useSortable({
            id: props["data-row-key"],
        });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && { ...transform, scaleY: 1 }
        ),
        transition,
        ...(isDragging ? { position: "relative", zIndex: 1 } : {}),
    };

    if (!props["data-row-key"]) return <tr {...props}>{children}</tr>;

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {Children.map(children, (child) => {
                return child;
            })}
        </tr>
    );
};

type TableProps = ComponentProps<typeof Table>;
const ColumnSetting = ({
    columns,
    onConfirm,
}: {
    columns: TableProps["columns"];
    onConfirm?: (columnKeys: string[]) => void;
}) => {
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectColumns, setSelectColumns] = useState(() => {
        return columns?.map?.((item) => item.title) as string[];
    });

    const onConfirmHandle = useCallback(() => {
        onConfirm?.(selectColumns);
    }, [onConfirm, selectColumns]);

    const checkeAll = useMemo(() => {
        if (!columns?.length) return false;
        return selectColumns?.length === columns?.length;
    }, [selectColumns, columns]);

    const onCheckAllChange = useCallback(
        (e) => {
            setSelectColumns(
                e.target.checked
                    ? (columns?.map?.((item) => item.title) as string[])
                    : []
            );
            setIndeterminate(false);
        },
        [columns]
    );

    const onCheckChange = useCallback(
        (list) => {
            if (!columns) return;
            setSelectColumns(list);
            setIndeterminate(!!list.length && list.length < columns.length);
        },
        [columns]
    );

    const onCheckReset = useCallback(
        (list) => {
            if (!columns) return;
            setSelectColumns(columns?.map?.((item) => item.title) as string[]);
            setIndeterminate(false);
        },
        [columns]
    );

    return (
        <Popconfirm
            icon={null}
            placement="bottom"
            title={
                <div>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkeAll}
                    >
                        列展示
                    </Checkbox>
                    <Button onClick={onCheckReset} type="link">
                        重置
                    </Button>
                </div>
            }
            description={
                <Checkbox.Group
                    className={styles.checkboxList}
                    value={selectColumns}
                    onChange={onCheckChange}
                >
                    {columns?.map((column, idx) => {
                        if (!column.title) return null;
                        return (
                            <div key={idx} style={{ paddingBottom: 3 }}>
                                <Checkbox value={column.title}>
                                    {column.title}
                                </Checkbox>
                            </div>
                        );
                    }, [])}
                </Checkbox.Group>
            }
            showCancel={false}
            onConfirm={onConfirmHandle}
        >
            <Button icon={<SettingOutlined />}></Button>
        </Popconfirm>
    );
};

const StatusOptions = [
    { label: "默认", value: 1 },
    { label: "已回收", value: 2 },
];

export const PageTable = function (props: {
    tableProps: TableProps;
    fields?: ReactNode[];
    disabledDrag?: boolean;
    tableHeader?: ReactNode;
    hideStatusSelect?: boolean;
    searchName?: string;
    hideSearchButton?: boolean;
    extraItems?: ReactNode;
    onRequestChange?: (request: any) => void;
    onSortChange?: (
        active: { guid: string; sort: number },
        over: { guid: string; sort: number }
    ) => void;
    onDeleteAllClick?: (guids: string[], isDelete?: CommonStatusEnum) => void;
}): ReactElement {
    const {
        tableProps,
        fields,
        onRequestChange,
        disabledDrag,
        extraItems,
        tableHeader,
        onSortChange,
        onDeleteAllClick,
        hideStatusSelect,
        searchName = "titleLike",
        hideSearchButton,
    } = props;
    const [form] = Form.useForm();
    const [baseSearchForm] = Form.useForm();
    const onFinish = useCallback(() => {}, []);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

    const rowSelection: TableRowSelection<any> = useMemo(() => {
        return {
            onChange: (keys, selectedRows) => {
                setSelectedRowKeys(keys);
            },
            selectedRowKeys,
        };
    }, [selectedRowKeys]);

    const [columnKeys, setColumnKeys] = useState(() => {
        return tableProps?.columns?.map((item) => item.title) as string[];
    });

    const onColumnSelect = useCallback((keys) => {
        setColumnKeys(keys);
    }, []);

    const columns = useMemo(() => {
        return tableProps?.columns?.filter((item) => {
            return !item.title || columnKeys.includes(item.title as string);
        });
    }, [columnKeys, tableProps?.columns]);

    const [dataSource, setDataSource] = useState<any>(tableProps?.dataSource);
    useEffect(() => {
        setDataSource(tableProps?.dataSource);
    }, [tableProps?.dataSource]);

    const onDragEnd = useCallback(
        ({ active, over }: DragEndEvent) => {
            if (active.id !== over?.id) {
                setDataSource((previous: any) => {
                    if (!previous) return [];
                    const activeIndex = previous.findIndex(
                        (i: any) => i.guid === active.id
                    );
                    const overIndex = previous.findIndex(
                        (i: any) => i.guid === over?.id
                    );
                    const nextSources = arrayMove(
                        previous,
                        activeIndex,
                        overIndex
                    );
                    onSortChange?.(previous[activeIndex], previous[overIndex]);
                    return nextSources;
                });
            }
        },
        [onSortChange]
    );

    const items = useMemo(
        () => (dataSource ? dataSource.map((i: any) => i.guid) : []),
        [dataSource]
    );

    const onBaseSubmit = useCallback(() => {
        const values = { ...baseSearchForm.getFieldsValue() };
        values.recycleStatus = values.status === 2 ? 1 : 0;
        values.status = undefined;
        onRequestChange?.(values);
    }, [onRequestChange, baseSearchForm]);

    return (
        <div className={styles.container}>
            {!!fields?.length && (
                <Form
                    form={form}
                    onFinish={onFinish}
                    className={styles.formWrapper}
                >
                    <Row gutter={24}>
                        {fields?.map?.((field, idx) => {
                            return (
                                <Col span={6} key={idx}>
                                    {field}
                                </Col>
                            );
                        })}
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Button htmlType="submit">搜索</Button>
                            <Button
                                style={{ margin: "0 8px" }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
            {tableHeader}
            <Space className={styles.functions}>
                <ColumnSetting
                    onConfirm={onColumnSelect}
                    columns={tableProps?.columns}
                />
                <Form form={baseSearchForm} layout="inline">
                    <Form.Item shouldUpdate>
                        {({ getFieldValue }) => {
                            const s = getFieldValue("status");
                            return s === 2 ? (
                                <Button
                                    danger
                                    disabled={!selectedRowKeys.length}
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: "确认销毁这些数据吗？",
                                            onOk: () => {
                                                setSelectedRowKeys([]);
                                                onDeleteAllClick?.(
                                                    selectedRowKeys,
                                                    CommonStatusEnum.YES
                                                );
                                            },
                                        });
                                    }}
                                >
                                    销毁
                                </Button>
                            ) : (
                                <Button
                                    danger
                                    disabled={!selectedRowKeys.length}
                                    icon={<DeleteOutlined />}
                                    onClick={(e) => {
                                        Modal.confirm({
                                            title: "确认删除这些数据吗？",
                                            onOk: () => {
                                                setSelectedRowKeys([]);
                                                onDeleteAllClick?.(
                                                    selectedRowKeys
                                                );
                                            },
                                        });
                                    }}
                                >
                                    删除
                                </Button>
                            );
                        }}
                    </Form.Item>
                    {!hideStatusSelect && (
                        <Form.Item name="status">
                            <Select
                                style={{ width: 100 }}
                                options={StatusOptions}
                                placeholder="选择状态"
                                onChange={onBaseSubmit}
                            />
                        </Form.Item>
                    )}
                    {!hideSearchButton && (
                        <Form.Item name={searchName}>
                            <Input.Search
                                style={{ width: 280 }}
                                prefix={<SearchOutlined />}
                                placeholder="搜索"
                                enterButton="搜索"
                                onSearch={onBaseSubmit}
                            />
                        </Form.Item>
                    )}
                    {extraItems}
                </Form>
            </Space>
            <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                    disabled={disabledDrag}
                    items={items}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        rowKey={(item: any) => item.guid}
                        rowSelection={{ ...rowSelection }}
                        {...tableProps}
                        components={{
                            body: {
                                row: RowItem,
                            },
                        }}
                        columns={columns}
                        dataSource={dataSource}
                    />
                </SortableContext>
            </DndContext>
        </div>
    );
};
