import { post } from ".";
import {
    CommonStatusEnum,
    DeleteRequest,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";

export interface RecruitmentItem {
    guid: string;
    /** job名称-中文 */
    titleCn?: string;
    /** jop名称-英文 */
    titleEn?: string;
    /** 中文简介 */
    summaryCn?: string;
    /** 英文简介 */
    summaryEn?: string;
    /** 中文要求 */
    contentCn?: string;
    /** 英文要求 */
    contentEn?: string;
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface RecruitmentListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: RecruitmentItem[];
}

/**
 * 获取招聘列表
 */
export const fetchRecruitmentList = (
    request: PageConfig & Partial<RecruitmentItem>
) => {
    return post<PageConfig & Partial<RecruitmentItem>, RecruitmentListReply>(
        "admin.biz.recruitment.info.list",
        request
    );
};

/**
 * 创建招聘
 */
export const createRecruitment = (request: RecruitmentItem) => {
    return post<RecruitmentItem>("admin.biz.recruitment.info.create", request);
};

/**
 * 获取招聘详情
 */
export const fetchRecruitmentDetails = (request: { guid: string }) => {
    return post<{ guid: string }, RecruitmentItem>(
        "admin.biz.recruitment.info.detail",
        request
    );
};

/**
 * 修改招聘
 */
export const updateRecruitment = (request: Partial<RecruitmentItem>) => {
    return post<Partial<RecruitmentItem>, RecruitmentItem>(
        "admin.biz.recruitment.info.update",
        request
    );
};

/**
 * 删除招聘
 */
export const deleteRecruitment = (request: DeleteRequest) => {
    return post<DeleteRequest>("admin.biz.recruitment.info.delete", {
        isDelete: CommonStatusEnum.NO,
        ...request,
    });
};
