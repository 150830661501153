import { Form, Input, Modal, Select, message } from "antd";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
    ClientItem,
    createClient,
    updateClient,
} from "../../../../service/request/apis/client";
import { CheckboxSwitch } from "../../../../components/CheckboxSwitch";
import { fetchIndustryList } from "../../../../service/request/apis/industry";
import {
    RecycleStatusEnum,
    StatusEnum,
} from "../../../../service/request/interface";
import { UploadComponent } from "../../../../components/Upload";

const { Item } = Form;

const useIndustryOptions = () => {
    const [data, setData] = useState<{ label: string; value: string }[]>();
    useEffect(() => {
        fetchIndustryList({
            pageNum: 1,
            pageSize: 100,
            status: StatusEnum.StatusOnline,
            recycleStatus: RecycleStatusEnum.RecycleStatusNo,
        }).then((res) => {
            setData(
                res.list?.map?.((item) => ({
                    label: item.titleCn!,
                    value: item.guid!,
                }))
            );
        });
    }, []);
    return data;
};

export const FormModal = ({
    data,
    onClose,
    onRefresh,
}: {
    data?: ClientItem;
    onClose?: () => void;
    onRefresh?: () => void;
}): ReactElement => {
    const [form] = Form.useForm();
    const options = useIndustryOptions();

    const onCancel = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const onFinish = useCallback(
        (values) => {
            const loading = message.loading("提交中...", 0);
            const isEdit = !!values.guid;
            (isEdit ? updateClient(values) : createClient(values))
                .then(() => {
                    loading();
                    message.success(`${isEdit ? "编辑" : "添加"}成功`, 1);
                    onRefresh?.();
                    onClose?.();
                })
                .catch((error) => {
                    loading();
                    message.error(
                        `${isEdit ? "编辑" : "添加"}失败: ${error?.msg || ""}`,
                        1
                    );
                });
        },
        [onClose, onRefresh]
    );

    return (
        <Modal
            onCancel={onCancel}
            onOk={() => {
                form.validateFields().then(() => {
                    form.submit();
                });
            }}
            width="60%"
            style={{ maxWidth: 868 }}
            title={data?.guid ? "编辑客户" : "添加客户"}
            open={true}
            className="modal-header ant-modal-custom"
        >
            <Form
                initialValues={data}
                onFinish={onFinish}
                form={form}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 8 }}
            >
                <Item name="guid" hidden>
                    <Input />
                </Item>
                <Item
                    name="titleCn"
                    label="中文名称"
                    rules={[{ required: true, message: "请输入中文名称" }]}
                >
                    <Input placeholder="中文名称" />
                </Item>
                <Item
                    name="titleEn"
                    label="英文名称"
                    rules={[{ required: true, message: "请输入英文名称" }]}
                >
                    <Input placeholder="英文名称" />
                </Item>
                <Item
                    name="industryGuid"
                    label="行业类型"
                    rules={[{ required: true, message: "请选择行业类型" }]}
                >
                    <Select options={options} placeholder="行业类型" />
                </Item>
                <Item
                    name="thumbnail"
                    label="图标"
                    rules={[
                        {
                            required: true,
                            message: "请上传客户图标",
                        },
                    ]}
                >
                    <UploadComponent folder="client" direction="horizontal" />
                </Item>
                <Item name="sort" label="排序">
                    <Input placeholder="排序" type="number" />
                </Item>
                <Item name="status" label="隐藏" valuePropName="checked">
                    <CheckboxSwitch />
                </Item>
            </Form>
        </Modal>
    );
};
