import { Button, Form, message, Modal, Space } from "antd";
import { ColumnProps } from "antd/lib/table";
import dayjs from "dayjs";
import {
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { usePagination } from "../../service/pagination/usePagination";
import {
    deleteSystemRole,
    fetchSystemRoleList,
    RoleItem,
} from "../../service/request/apis/role";
import { RoleModal } from "./components/RoleModal";
import { PageTable } from "../../components/PageTable";
import { PageTitle } from "../../components/PageTitle";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const useColumns = (
    onRefresh: () => void,
    onEditItem: (item: RoleItem) => void
) => {
    return useMemo<ColumnProps<RoleItem>[]>(() => {
        return [
            {
                title: "角色名称",
                dataIndex: "roleName",
            },
            {
                title: "创建时间",
                render: (_: string, record) => {
                    return dayjs(record.createTime).format(
                        "YYYY-MM-DD HH:mm:ss"
                    );
                },
            },
            {
                title: "最近操作时间",
                render: (_: string, record) => {
                    return dayjs(record.updateTime).format(
                        "YYYY-MM-DD HH:mm:ss"
                    );
                },
            },
            {
                title: "操作",
                render: (_: string, record) => {
                    return (
                        <Action
                            onEditItem={onEditItem}
                            onRefresh={onRefresh}
                            data={record}
                        />
                    );
                },
            },
        ];
    }, [onRefresh, onEditItem]);
};

const Action = ({
    data,
    onRefresh,
    onEditItem,
}: {
    data: RoleItem;
    onRefresh: () => void;
    onEditItem: (item: RoleItem) => void;
}) => {
    const onDelete = useCallback(() => {
        Modal.confirm({
            title: "确定删除吗？",
            onOk: () => {
                const hide = message.loading("正在删除...", 0);
                deleteSystemRole({ guid: data.guid })
                    .then(() => {
                        message.success("删除成功", 1);
                        onRefresh();
                    })
                    .catch((error) => {
                        message.error(`删除失败: ${error?.msg || ""}`, 1);
                    })
                    .finally(hide);
            },
        });
    }, [data, onRefresh]);

    const onEditHandle = useCallback(() => {
        onEditItem(data);
    }, [onEditItem, data]);

    return (
        <Space>
            <Button icon={<EditOutlined />} size="small" onClick={onEditHandle}>
                编辑
            </Button>
            <Button
                size="small"
                icon={<DeleteOutlined />}
                onClick={onDelete}
                danger
                title="删除"
            />
        </Space>
    );
};

export const SystemRolePage = ({
    titleProps,
    tableHeader,
}: {
    titleProps?: { title: string; subtitle: string };
    tableHeader?: ReactNode;
}): ReactElement => {
    const [form] = Form.useForm();
    const [editModal, setEditModal] = useState<RoleItem>();

    const {
        fetchAction,
        dataSource,
        pageIndex,
        pageSize,
        onPageIndexChange,
        isRequest,
    } = usePagination(fetchSystemRoleList);

    const onSearch = useCallback(() => {
        fetchAction({ ...form.getFieldsValue(), pageNum: 1 });
    }, [form, fetchAction]);

    const onCloseModal = useCallback(() => {
        setEditModal(undefined);
    }, []);

    const onCreate = useCallback(() => {
        setEditModal({} as RoleItem);
    }, []);

    const columns = useColumns(onSearch, setEditModal);
    useEffect(() => {
        onSearch();
    }, [onSearch]);

    console.log("dataSource: ", dataSource);

    return (
        <>
            <PageTitle
                title="角色管理"
                subtitle="角色管理内容展示"
                {...titleProps}
                onAddHandle={onCreate}
            />
            <PageTable
                hideStatusSelect
                tableHeader={tableHeader}
                onRequestChange={onSearch}
                hideSearchButton
                tableProps={{
                    loading: isRequest,
                    columns: columns as any,
                    dataSource: dataSource?.list,
                    pagination: {
                        current: pageIndex,
                        pageSize,
                        onChange: onPageIndexChange,
                        showQuickJumper: true,
                        total: dataSource?.total ?? 0,
                    },
                }}
            />
            {!!editModal && (
                <RoleModal
                    onRefresh={onSearch}
                    data={editModal}
                    onClose={onCloseModal}
                />
            )}
        </>
    );
};
