import { post } from ".";
import {
    CommonStatusEnum,
    DeleteRequest,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";

export interface ServiceItem {
    guid: string;
    /** 服务名称-中文 */
    titleCn?: string;
    /** 服务名称-英文 */
    titleEn?: string;
    /** 是否插入到导航栏 */
    isInsertMenu?: CommonStatusEnum;
    /** 图标 */
    icon?: string;
    /** 排序 */
    sort?: number;
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface ServiceListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: ServiceItem[];
}

export interface ServiceDetailsReply {
    data: ServiceItem;
}

/**
 * 获取服务列表
 */
export const fetchServiceList = (
    request: PageConfig & Partial<ServiceItem>
) => {
    return post<PageConfig & Partial<ServiceItem>, ServiceListReply>(
        "admin.biz.service.info.list",
        request
    );
};

/**
 * 创建服务
 */
export const createService = (request: ServiceItem) => {
    return post<ServiceItem>("admin.biz.service.info.create", request);
};

/**
 * 获取服务详情
 */
export const fetchServiceDetails = (request: { guid: string }) => {
    return post<{ guid: string }, ServiceItem>(
        "admin.biz.service.info.detail",
        request
    );
};

/**
 * 修改service
 */
export const updateService = (request: Partial<ServiceItem>) => {
    return post<Partial<ServiceItem>, ServiceItem>(
        "admin.biz.service.info.update",
        request
    );
};

/**
 * 删除服务
 */
export const deleteService = (request: DeleteRequest) => {
    return post<DeleteRequest>("admin.biz.service.info.delete", {
        isDelete: CommonStatusEnum.NO,
        ...request,
    });
};
