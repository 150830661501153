import { createContext, FunctionComponent, useEffect, useState } from "react";
import { fetchUserProfile, UserItem } from "../request/apis/user";
import { useIsLoggedIn } from "./useIsLoggedIn";

const defaultState = {} as UserItem | undefined;

export const UserInfoStateContext = createContext(defaultState);

export const UserInfoStateProvider: FunctionComponent = ({ children }) => {
    const [userInfo, setUserInfo] = useState<UserItem>();
    const isLoggedIn = useIsLoggedIn();

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserProfile().then((res) => {
                setUserInfo(res);
            });
        } else {
            setUserInfo(undefined);
        }
    }, [isLoggedIn]);

    return (
        <UserInfoStateContext.Provider value={userInfo}>
            {children}
        </UserInfoStateContext.Provider>
    );
};
