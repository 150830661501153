import { ComponentType } from "react";

export enum RouteName {
    /**
     * 登录页
     */
    LOGIN = "/login",
    /**
     * 工作台
     */
    DASHBOARD = "/dashboard",
    /** default */
    DEFAULT = "/",
    /**
     * WORK
     */
    WORK = "work",
    /**
     * work form edit
     */
    WORK_EDIT = "/work/:workid",
    /**
     * work form create
     */
    WORK_CREATE = "/work/create",
    /**
     * 关于我们
     */
    ABOUT = "about",
    /**
     * IDEAS
     */
    IDEAS = "ideas",
    /**
     * work form edit
     */
    IDEAS_EDIT = "/ideas/:ideasid",
    /**
     * work form create
     */
    IDEAS_CREATE = "/ideas/create",
    /**
     * CLIENT
     */
    CLIENT = "client",
    /**
     * SERVICE
     */
    SERVICE = "service",
    /**
     * industry type
     */
    INDUSTRY_TYPE = "industry_type",
    /**
     * Service type
     */
    SERVICE_TYPE = "service_type",
    /**
     * IDEAS type
     */
    IDEAS_TYPE = "ideas_type",
    /**
     * 待发布
     */
    WAIT_RELEASED = "to_be_released",
    /**
     * 草稿
     */
    DRAFT = "draft",
    /**
     * 人员
     */
    USERS = "users",
    /**
     * 职能
     */
    FUNCTIONS = "functions",
    /**
     * 招聘
     */
    RECRUITMENT = "recruitment",
    /**
     * 招聘 form edit
     */
    RECRUITMENT_EDIT = "/recruitment/:ideasid",
    /**
     * 招聘 form create
     */
    RECRUITMENT_CREATE = "/recruitment/create",
    /**
     * 权限
     */
    PERMISSIONS = "permissions",
    // /**
    //  * 会员列表页
    //  */
    // MEMBERSHIP = "/membership",
    // /**
    //  * 数据中心
    //  */
    // DATACENTER = "/datacenter",
    // /**
    //  * 财务中心
    //  */
    // FINANCE = "/finance",
    // /**
    //  * 客服中心
    //  */
    // CUSTOMERSERVICE = "/customerservice",
    // /**
    //  * 系统设置-个人信息
    //  */
    // SYSTEM = "/system",
    // /**
    //  * 系统设置-配置中心
    //  */
    // SYSTEM_CONFIG = "/system/config",
    // /**
    //  * 系统设置-角色权限
    //  */
    // SYSTEM_ROLE = "/system/role",
    // /**
    //  * 系统设置-人员管理
    //  */
    // SYSTEM_USERS = "/system/users",
    // /**
    //  * 系统设置-人员管理-编辑用户
    //  */
    // SYSTEM_USERS_EDIT = "/system/users/:guid",
}

export interface RouteItem {
    name: string;
    path: RouteName;
    children: RouteItem[];
    hideMenu?: boolean;
    element?: ComponentType<any>;
    hideInMenu?: boolean;
    breadcrumbName: string;
    rule?: string | string[];
}
