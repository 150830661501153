import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ComponentProps, ReactElement, useCallback } from 'react';

type CheckboxProps = ComponentProps<typeof Checkbox>;
interface Props extends Omit<CheckboxProps, 'onChange'> {
    onChange?: (value: number) => void;
}
export const CheckboxSwitch = (props: Props): ReactElement => {
    const { children, onChange, ...rest } = props;
    const onChangeHandle = useCallback((e: CheckboxChangeEvent) => {
        onChange?.(e.target.checked ? 1 : 0);
    }, [onChange]);

    return <Checkbox {...rest} onChange={onChangeHandle} >{children}</Checkbox>
};
