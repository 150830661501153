/** 实体上下线状态 */
export enum StatusEnum {
    /** 在线 */
    StatusOnline = 0,
    /** 下线 */
    StatusOffline = 1,
}

/** 通用开关状态 */
export enum CommonStatusEnum {
    /** 否 */
    NO = 0,
    /** 是 */
    YES = 1,
}

/** 草稿箱状态 */
export enum DraftStatusEnum {
    /** 不是草稿 */
    DraftStatusNo = 0,
    /** 是草稿 */
    DraftStatusYes = 1,
}

/** 回收站状态 */
export enum RecycleStatusEnum {
    /** 未回收 */
    RecycleStatusNo = 0,
    /** 已回收 */
    RecycleStatusYes = 1,
}

export interface ListRequest {
    pageNum: number;
    pageSize: number;
}

export interface PageConfig {
    pageNum: number;
    pageSize: number;
}

export interface DeleteRequest {
    guids: string[];
    isDelete?: CommonStatusEnum;
}
