import {
    createContext,
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { config } from "../config";
import { addListener, dispatchEvent } from "../event";
import { setRequestConfig } from "../request";
import { local } from "../storage";
import { AuthInfo } from "./interface";

const authInfo = local.getItem(config.LOGIN_INFO_CACHE_STORAGE_KEY);
if (authInfo) {
    setRequestConfig({ token: authInfo.token });
}

export interface AuthState {
    isLoggedIn: boolean;
    signin: (info: AuthInfo) => Promise<void>;
    signout: () => Promise<void>;
    authInfo?: AuthInfo;
}

const defaultState: AuthState = {
    isLoggedIn: !!authInfo.token,
    authInfo,
    signin: () => Promise.resolve(),
    signout: () => Promise.resolve(),
};

export const AuthStateContext = createContext(defaultState);

export const AuthStateProvider: FunctionComponent = ({ children }) => {
    const [authState, setAuthState] = useState(defaultState);
    const signin = useCallback((info: AuthInfo) => {
        setAuthState((prev) => ({
            ...prev,
            authInfo: info,
            isLoggedIn: !!info.token,
        }));
        local.setItem(config.LOGIN_INFO_CACHE_STORAGE_KEY, info);
        setRequestConfig({ token: info.token });
        return Promise.resolve();
    }, []);

    const signout = useCallback(() => {
        dispatchEvent('tokenInvalid');
        return Promise.resolve();
    }, []);

    useEffect(() => {
        const subscription = addListener("tokenInvalid", () => {
            local.removeItem(config.LOGIN_INFO_CACHE_STORAGE_KEY);
            console.log('tokenInvalidtokenInvalid');
            
            setAuthState((prev) => ({
                ...prev,
                isLoggedIn: false,
                authInfo: undefined
            }));
            setRequestConfig({ token: '' });
        });

        return () => {
            subscription.remove();
        }
    }, []);
    
    const value = useMemo(
        () => ({
            ...authState,
            signin,
            signout,
        }),
        [authState, signin, signout]
    );

    return (
        <AuthStateContext.Provider value={value}>
            {children}
        </AuthStateContext.Provider>
    );
};
