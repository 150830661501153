import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { useCallback, useEffect, useRef, useState } from "react";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import { IDomEditor, IToolbarConfig } from "@wangeditor/editor";
import styles from "./styles.module.less";

const toolBarConfig: Partial<IToolbarConfig> = {
    // toolbarKeys: ['bold', 'italic', 'sup', 'sub', 'insertLink'],
    toolbarKeys: ["insertLink"],
};

const hoverBarConfig = {
    headerSelect: { menuKeys: ["header1"] },
    text: {
        menuKeys: [
            "headerSelect",
            "insertLink",
            // 'bulletedList',
            "|",
            'bold',
            'through',
            'color',
            'bgColor',
            "clearStyle",
        ],
    },
};

export const BraftEditorBase = ({
    value,
    onChange,
    height = 300,
}: {
    value?: string;
    onChange?: (content: string) => void;
    height?: number;
}) => {
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    const initRef = useRef(false);
    const [editor, setEditor] = useState<IDomEditor | null>(null);

    const onChangeHandle = useCallback((e) => {
        onChangeRef.current?.(e.getHtml());
    }, []);

    useEffect(() => {
        if (!initRef.current && value !== undefined && value !== null) {
            initRef.current = true;
            onChangeRef?.current?.(value);
        }
    }, [value]);

    return (
        <div className={styles.container}>
            <Toolbar
                editor={editor}
                defaultConfig={toolBarConfig}
                mode="default"
                className={styles.toolBar}
            />
            <Editor
                defaultConfig={{
                    hoverbarKeys: hoverBarConfig,
                }}
                value={value}
                onCreated={setEditor}
                onChange={onChangeHandle}
                mode="default"
                style={{ height }}
            />
        </div>
    );
};
