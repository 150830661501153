const storageBy = (storage: Storage) => ({
    getItem(key: string) {
        const content = storage.getItem(key) || "";
        try {
            return JSON.parse(content);
        } catch (e) {
            return content;
        }
    },
    setItem<T extends unknown>(key: string, content: T) {
        storage.setItem(key, JSON.stringify(content));
    },
    removeItem(key: string) {
        storage.removeItem(key);
    },
});
export const local = storageBy(window.localStorage);
export const session = storageBy(window.sessionStorage);
