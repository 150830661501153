import { Form, Input, message, Modal, Select } from "antd";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { fetchSystemRoleList } from "../../../../service/request/apis/role";
import {
    createUser,
    updateUserInfo,
    UserItem,
} from "../../../../service/request/apis/user";

export const RoleItemList = ({
    value,
    onChange,
}: {
    value?: string;
    onChange?: (value: string) => void;
}): ReactElement => {
    const [options, setOptions] = useState<{ label: string; value: string }[]>(
        []
    );
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    useEffect(() => {
        fetchSystemRoleList({ pageNum: 1, pageSize: 100 }).then((res) => {
            setOptions(
                res.list.map((item) => {
                    return {
                        label: item.roleName,
                        value: item.guid,
                    };
                })
            );
        });
    }, []);

    return (
        <Select
            allowClear
            onChange={onChange}
            options={options}
            value={value}
            placeholder="请选择角色"
        />
    );
};

export const FormModal = ({
    data,
    onClose,
    onRefresh,
}: {
    data: UserItem;
    onClose: () => void;
    onRefresh: () => void;
}): ReactElement => {
    const [form] = Form.useForm();

    const isEdit = !!data?.guid;
    const onSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            const hide = message.loading("正在提交...", 0);
            (isEdit ? updateUserInfo : createUser)({
                ...data,
                ...values,
            })
                .then(() => {
                    hide();
                    onClose();
                    onRefresh();
                    message.success("提交成功", 1);
                })
                .catch((error) => {
                    hide();
                    message.error(`提交失败: ${error?.msg || ""}`, 1);
                })
                .finally(hide);
        });
    }, [form, onClose, onRefresh, data, isEdit]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                ...data,
                userPwd: "",
            });
        }
    }, [data, form]);

    return (
        <Modal
            open={true}
            width="60%"
            className="modal-header ant-modal-custom"
            onCancel={onClose}
            onOk={() => {
                form.submit();
            }}
            title={isEdit ? "编辑用户" : "创建用户"}
        >
            <div className="content-body" style={{ maxWidth: 600 }}>
                <Form
                    onFinish={onSubmit}
                    form={form}
                    layout="horizontal"
                    labelCol={{ span: 5 }}
                >
                    <Form.Item hidden name="guid">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[{ message: "请输入用户名", required: true }]}
                    >
                        <Input placeholder="输入用户名" />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        extra={isEdit ? "如果不修改密码，请留空" : ""}
                    >
                        <Input placeholder="输入用户密码" />
                    </Form.Item>
                    <Form.Item
                        label="用户昵称"
                        name="nickname"
                        rules={[{ message: "请输入用户昵称", required: true }]}
                    >
                        <Input placeholder="输入用户昵称" />
                    </Form.Item>
                    <Form.Item
                        label="角色"
                        name="roleGuid"
                        rules={[{ message: "请选择用户角色", required: true }]}
                    >
                        <RoleItemList />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
