import { ListenerHolder, ListenerSubscription } from "./listener";

type DefaultEventType = "tokenInvalid";
type EventListener = () => void;

const listenerHolder = new ListenerHolder<DefaultEventType, EventListener>();

export function dispatchEvent(type: DefaultEventType): void {
    listenerHolder.dispatch(type);
}

export function addListener(
    type: DefaultEventType,
    listener: EventListener
): ListenerSubscription {
    return listenerHolder.addListener(type, listener);
}
