import { post } from ".";
import { RecycleStatusEnum, StatusEnum } from "../interface";
import { IdeasItem } from "./ideas";
import { JobItem } from "./job";
import { WorkItem } from "./work";

export interface OrganizationItem {
    /** 职位名称 */
    job: Partial<JobItem>;
    /** 数量 */
    number: number;
}

export interface AboutNewsItem {
    /** 新闻ID */
    news?: Partial<IdeasItem>;
}

export interface AboutWorkItem {
    work?: Partial<WorkItem>;
    /** 获奖链接 */
    awardUrl?: string;
    /** 获奖图标 */
    icon?: string;
}

export interface AboutIdeaItem {
    idea?: Partial<IdeasItem>;
}

export interface PublicationItem {
    publication?: Partial<IdeasItem>;
}

export interface AboutItem {
    guid: string;
    /** 公司简介-中文 */
    companyInfoCn?: string;
    /** 公司简介-英文 */
    companyInfoEn?: string;
    /** 联系电话 */
    connectPhone?: string;
    /** 联系邮箱 */
    connectEmail?: string;
    /** 联系地址-中文 */
    connectAddressCn?: string;
    /** 联系地址-英文 */
    connectAddressEn?: string;
    /** 公众号 */
    mp?: string;
    /** 微博 */
    weibo?: string;
    /** 小红书 */
    xiaohongshu?: string;
    /** 抖音 */
    douyin?: string;
    /** be */
    be?: string;
    /** pin */
    pin?: string;
    /** 组织架构 */
    organization?: OrganizationItem[];
    /** 新闻 */
    newsList?: AboutNewsItem[];
    /** 项目 */
    works?: AboutWorkItem[];
    /** 出版物 */
    publications?: PublicationItem[];
    /** 招聘邮箱 */
    recruitEmail?: string;
    /** 招聘联系电话 */
    recruitPhone?: string;
    /** 招聘人 */
    recruitTeamGuid?: string;
    /** 招聘职位 */
    recruit?: string[];
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface AboutDetailsReply {
    settingValue: {
        sigAboutValue: AboutItem;
    };
}

const settingKey = "sig_about_config";

/**
 * 获取关于详情
 */
export const fetchAboutDetails = () => {
    return post<{ settingKey: string }, AboutDetailsReply>(
        "admin.system.setting.detail",
        {
            settingKey,
        }
    ).then((res) => {
        try {
            return res.settingValue?.sigAboutValue
                ? JSON.parse(
                      res.settingValue.sigAboutValue as unknown as string
                  )
                : {};
        } catch (error) {
            return {};
        }
    });
};

/**
 * 修改关于
 */
export const updateAbout = (request: Partial<AboutItem>) => {
    return post<
        {
            settingKey: string;
            settingValue: { sigAboutValue: string };
        },
        AboutItem
    >("admin.system.setting.edit", {
        settingKey,
        settingValue: {
            sigAboutValue: JSON.stringify(request),
        },
    });
};
