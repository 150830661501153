import axios from "axios";
import { config } from "../../config";

export const post = <T = any, Response = any>(
    action: string,
    request?: T
): Promise<Response> => {
    return axios.post(config.API_SERVER, {
        action,
        params: request,
    });
};
