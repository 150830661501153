import { RouteItem, RouteName } from "./interface";
import { DashboardPage } from "../../pages/Dashboard";
import { WorkPage } from "../../pages/Work";
import { WorkFormEditPage } from "../../pages/Work/components/FormEdit";
import { ClientPage } from "../../pages/Client";
import { IdeasPage } from "../../pages/Ideas";
import { IndustryPage } from "../../pages/Industry";
import { ServicePage } from "../../pages/Service";
import { IdeasCategoryPage } from "../../pages/IdeaCategory";
import { TeamPage } from "../../pages/Team";
import { JobPage } from "../../pages/Job";
import { AboutPage } from "../../pages/About";
import { DraftPage } from "../../pages/Draft";
import { WaitPublishPage } from "../../pages/WaitPublish";
import { IdeasFormEditPage } from "../../pages/Ideas/components/FormEdit";
import { PermissionPage } from "../../pages/Permissions";
import { roleWithRouteName } from "./useUserMenuPermissionByRule";
import { RecruitmentPage } from "../../pages/Recruitment";
import { RecruitmentFormEditPage } from "../../pages/Recruitment/components/FormEdit";
import { DefaultPage } from "../../pages/Default";

export const routes: RouteItem[] = [
    {
        name: "默认",
        path: RouteName.DEFAULT,
        children: [],
        hideMenu: true,
        element: DefaultPage,
        breadcrumbName: "默认",
        rule: "*",
    },
    {
        name: "控制台",
        path: RouteName.DASHBOARD,
        children: [],
        element: DashboardPage,
        breadcrumbName: "控制台",
        rule: roleWithRouteName[RouteName.DASHBOARD],
    },
    {
        name: "WORK",
        path: RouteName.WORK,
        children: [],
        element: WorkPage,
        breadcrumbName: "WORK",
        rule: roleWithRouteName[RouteName.WORK],
    },
    {
        name: "WORK EDIT",
        path: RouteName.WORK_EDIT,
        children: [],
        hideMenu: true,
        element: WorkFormEditPage,
        breadcrumbName: "WORK EDIT",
        rule: roleWithRouteName[RouteName.WORK],
    },
    {
        name: "WORK CREATE",
        path: RouteName.WORK_CREATE,
        children: [],
        hideMenu: true,
        element: WorkFormEditPage,
        breadcrumbName: "WORK CREATE",
        rule: roleWithRouteName[RouteName.WORK],
    },
    {
        name: "IDEAS",
        path: RouteName.IDEAS,
        children: [],
        element: IdeasPage,
        breadcrumbName: "IDEAS",
        rule: roleWithRouteName[RouteName.IDEAS],
    },
    {
        name: "IDEAS EDIT",
        path: RouteName.IDEAS_EDIT,
        children: [],
        hideMenu: true,
        element: IdeasFormEditPage,
        breadcrumbName: "IDEAS EDIT",
        rule: roleWithRouteName[RouteName.IDEAS],
    },
    {
        name: "IDEAS CREATE",
        path: RouteName.IDEAS_CREATE,
        children: [],
        hideMenu: true,
        element: IdeasFormEditPage,
        breadcrumbName: "IDEAS CREATE",
        rule: roleWithRouteName[RouteName.IDEAS],
    },
    {
        name: "ABOUT",
        path: RouteName.ABOUT,
        children: [],
        element: AboutPage,
        breadcrumbName: "ABOUT",
        rule: roleWithRouteName[RouteName.ABOUT],
    },
    {
        name: "客户",
        path: RouteName.CLIENT,
        children: [],
        element: ClientPage,
        breadcrumbName: "CLIENT",
        rule: roleWithRouteName[RouteName.CLIENT],
    },
    {
        name: "行业类型",
        path: RouteName.INDUSTRY_TYPE,
        children: [],
        element: IndustryPage,
        breadcrumbName: "行业类型",
        rule: roleWithRouteName[RouteName.INDUSTRY_TYPE],
    },
    {
        name: "服务类型",
        path: RouteName.SERVICE_TYPE,
        children: [],
        element: ServicePage,
        breadcrumbName: "服务类型",
        rule: roleWithRouteName[RouteName.SERVICE_TYPE],
    },
    {
        name: "IDEAS 类型",
        path: RouteName.IDEAS_TYPE,
        children: [],
        element: IdeasCategoryPage,
        breadcrumbName: "IDEAS 类型",
        rule: roleWithRouteName[RouteName.IDEAS_CREATE],
    },
    {
        name: "待发布",
        path: RouteName.WAIT_RELEASED,
        children: [],
        element: WaitPublishPage,
        breadcrumbName: "待发布",
        rule: roleWithRouteName[RouteName.WAIT_RELEASED],
    },
    {
        name: "草稿",
        path: RouteName.DRAFT,
        children: [],
        element: DraftPage,
        breadcrumbName: "草稿",
        rule: roleWithRouteName[RouteName.DRAFT],
    },
    {
        name: "人员",
        path: RouteName.USERS,
        children: [],
        element: TeamPage,
        breadcrumbName: "人员",
        rule: roleWithRouteName[RouteName.USERS],
    },
    {
        name: "职能",
        path: RouteName.FUNCTIONS,
        children: [],
        element: JobPage,
        breadcrumbName: "职能",
        rule: roleWithRouteName[RouteName.FUNCTIONS],
    },
    {
        name: "招聘",
        path: RouteName.RECRUITMENT,
        children: [],
        element: RecruitmentPage,
        breadcrumbName: "招聘",
        rule: roleWithRouteName[RouteName.RECRUITMENT],
    },
    {
        name: "招聘编辑",
        path: RouteName.RECRUITMENT_EDIT,
        children: [],
        hideMenu: true,
        element: RecruitmentFormEditPage,
        breadcrumbName: "招聘编辑",
        rule: roleWithRouteName[RouteName.RECRUITMENT],
    },
    {
        name: "招聘创建",
        path: RouteName.RECRUITMENT_CREATE,
        children: [],
        hideMenu: true,
        element: RecruitmentFormEditPage,
        breadcrumbName: "招聘创建",
        rule: roleWithRouteName[RouteName.RECRUITMENT],
    },
    {
        name: "权限",
        path: RouteName.PERMISSIONS,
        children: [],
        element: PermissionPage,
        breadcrumbName: "权限",
    },
];

const showRoutes = routes.filter((route) => !route.hideMenu);
export const routeGroups = [
    showRoutes.slice(0, 1),
    showRoutes.slice(1, 4),
    showRoutes.slice(8, 10),
    showRoutes.slice(4, 8),
    showRoutes.slice(10),
];
