import { post } from ".";
import {
    CommonStatusEnum,
    DeleteRequest,
    PageConfig,
    RecycleStatusEnum,
    StatusEnum,
} from "../interface";

export interface TeamItem {
    guid: string;
    /** 名字-中文 */
    titleCn?: string;
    /** 名字-英文 */
    titleEn?: string;
    /** 在线状态 */
    status?: StatusEnum;
    /** 回收状态 */
    recycleStatus?: RecycleStatusEnum;
    /** 创建时间 */
    createTime: number;
    /** 更新时间 */
    updateTime: number;
}

export interface TeamListReply {
    page: number;
    size: number;
    count: number;
    total: number;
    list: TeamItem[];
}

export interface TeamDetailsReply {
    data: TeamItem;
}

/**
 * 获取服务列表
 */
export const fetchTeamList = (request: PageConfig & Partial<TeamItem>) => {
    return post<PageConfig & Partial<TeamItem>, TeamListReply>(
        "admin.biz.team.info.list",
        request
    );
};

/**
 * 创建服务
 */
export const createTeam = (request: TeamItem) => {
    return post<TeamItem>("admin.biz.team.info.create", request);
};

/**
 * 获取服务详情
 */
export const fetchTeamDetails = (request: { guid: string }) => {
    return post<{ guid: string }, TeamItem>(
        "admin.biz.team.info.detail",
        request
    );
};

/**
 * 修改team
 */
export const updateTeam = (request: Partial<TeamItem>) => {
    return post<Partial<TeamItem>, TeamItem>(
        "admin.biz.team.info.update",
        request
    );
};

/**
 * 删除服务
 */
export const deleteTeam = (request: DeleteRequest) => {
    return post<DeleteRequest>("admin.biz.team.info.delete", {
        isDelete: CommonStatusEnum.NO,
        ...request,
    });
};
